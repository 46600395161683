import { Component, OnInit } from '@angular/core';
import { Fraccion1Service } from 'src/services/Articulo8/Fraccion1.service';
import { GeneralService } from 'src/services/general.service';
import { SweetAlertService } from 'src/services/sweet-alert.service';

@Component({
  selector: 'app-in-n1',
  templateUrl: './in-n1.component.html',
  styleUrls: ['./in-n1.component.scss']
})
export class InN1Component implements OnInit {
  docs: any;
  docs18: any;
  docs19: any;
  docs20: any;
  docs21: any;
  docs22: any;
  docs24: any;
  docs23: any;
  boton: any;
  area: any;
  displayStyleEditarNombre = "none";

  borrar: string[] = [];
  valor: any;
  todo18: any;
  todo19: any;
  todo20: any;
  todo21: any;
  todo22: any;
  todo24: any;
  todo23: any;

  eliminarForm: any = {
    ESTATUS: 0,
    NOMBRE: undefined,
    ENLACE: undefined,

  };
  constructor(private service: Fraccion1Service, private general: GeneralService, private alerts: SweetAlertService) { }

  ngOnInit(): void {

    localStorage.setItem('fraccion', '1');
    localStorage.setItem('inciso', '15');

    if (localStorage.getItem('token')) {
      this.boton = 1;
    }

  }
  checkCheckBoxvalue18(event:any, index:any, id: any){
    console.log(event.target.checked)
    this.todo18 = null;
    this.valor = event.target.checked;
    if(this.valor === true){
      console.log('verdadero');
      console.log(index);
      this.borrar[index]=this.docs18[index].ID;
      console.log(this.borrar);

    }else if(this.valor === false){
      console.log('falso');
      console.log(index);
      let indice = this.borrar.indexOf(id);
      this.borrar.splice(indice, 1);
      console.log(this.borrar);

    }
  }

  seleccionarTodo18(){
    this.todo18 = true;
    let valores = this.docs18.length;
    for( let i = 0; i < valores; i++){
      console.log(i);
      this.borrar[i]=this.docs18[i].ID;
      console.log(this.borrar);

    }

  }

  quitarSeleccion18(){
    let valores = this.borrar.length;
    for( let i = 0; i < valores; i++){
      console.log(i);
      this.borrar.pop();
      console.log(this.borrar);
      this.todo18 = false;
    }
  }

  checkCheckBoxvalue19(event:any, index:any, id: any){
    console.log(event.target.checked)
    this.todo19 = null;
    this.valor = event.target.checked;
    if(this.valor === true){
      console.log('verdadero');
      console.log(index);
      this.borrar[index]=this.docs19[index].ID;
      console.log(this.borrar);

    }else if(this.valor === false){
      console.log('falso');
      console.log(index);
      let indice = this.borrar.indexOf(id);
      this.borrar.splice(indice, 1);
      console.log(this.borrar);

    }
  }

  seleccionarTodo19(){
    this.todo19 = true;
    let valores = this.docs19.length;
    for( let i = 0; i < valores; i++){
      console.log(i);
      this.borrar[i]=this.docs19[i].ID;
      console.log(this.borrar);

    }

  }

  quitarSeleccion19(){
    let valores = this.borrar.length;
    for( let i = 0; i < valores; i++){
      console.log(i);
      this.borrar.pop();
      console.log(this.borrar);
      this.todo19 = false;
    }
  }

  checkCheckBoxvalue20(event:any, index:any, id: any){
    console.log(event.target.checked)
    this.todo20 = null;
    this.valor = event.target.checked;
    if(this.valor === true){
      console.log('verdadero');
      console.log(index);
      this.borrar[index]=this.docs20[index].ID;
      console.log(this.borrar);

    }else if(this.valor === false){
      console.log('falso');
      console.log(index);
      let indice = this.borrar.indexOf(id);
      this.borrar.splice(indice, 1);
      console.log(this.borrar);

    }
  }

  seleccionarTodo20(){
    this.todo20 = true;
    let valores = this.docs20.length;
    for( let i = 0; i < valores; i++){
      console.log(i);
      this.borrar[i]=this.docs20[i].ID;
      console.log(this.borrar);

    }

  }

  quitarSeleccion20(){
    let valores = this.borrar.length;
    for( let i = 0; i < valores; i++){
      console.log(i);
      this.borrar.pop();
      console.log(this.borrar);
      this.todo20 = false;
    }
  }
  checkCheckBoxvalue21(event:any, index:any, id: any){
    console.log(event.target.checked)
    this.todo21 = null;
    this.valor = event.target.checked;
    if(this.valor === true){
      console.log('verdadero');
      console.log(index);
      this.borrar[index]=this.docs21[index].ID;
      console.log(this.borrar);

    }else if(this.valor === false){
      console.log('falso');
      console.log(index);
      let indice = this.borrar.indexOf(id);
      this.borrar.splice(indice, 1);
      console.log(this.borrar);

    }
  }

  seleccionarTodo21(){
    this.todo21 = true;
    let valores = this.docs21.length;
    for( let i = 0; i < valores; i++){
      console.log(i);
      this.borrar[i]=this.docs21[i].ID;
      console.log(this.borrar);

    }

  }

  quitarSeleccion21(){
    let valores = this.borrar.length;
    for( let i = 0; i < valores; i++){
      console.log(i);
      this.borrar.pop();
      console.log(this.borrar);
      this.todo21 = false;
    }
  }
  checkCheckBoxvalue22(event:any, index:any, id: any){
    console.log(event.target.checked)
    this.todo22 = null;
    this.valor = event.target.checked;
    if(this.valor === true){
      console.log('verdadero');
      console.log(index);
      this.borrar[index]=this.docs22[index].ID;
      console.log(this.borrar);

    }else if(this.valor === false){
      console.log('falso');
      console.log(index);
      let indice = this.borrar.indexOf(id);
      this.borrar.splice(indice, 1);
      console.log(this.borrar);

    }
  }

  seleccionarTodo22(){
    this.todo22 = true;
    let valores = this.docs22.length;
    for( let i = 0; i < valores; i++){
      console.log(i);
      this.borrar[i]=this.docs22[i].ID;
      console.log(this.borrar);

    }

  }
  quitarSeleccion22(){
    let valores = this.borrar.length;
    for( let i = 0; i < valores; i++){
      console.log(i);
      this.borrar.pop();
      console.log(this.borrar);
      this.todo22 = false;
    }
  }
  checkCheckBoxvalue23(event:any, index:any, id: any){
    console.log(event.target.checked)
    this.todo23 = null;
    this.valor = event.target.checked;
    if(this.valor === true){
      console.log('verdadero');
      console.log(index);
      this.borrar[index]=this.docs23[index].ID;
      console.log(this.borrar);

    }else if(this.valor === false){
      console.log('falso');
      console.log(index);
      let indice = this.borrar.indexOf(id);
      this.borrar.splice(indice, 1);
      console.log(this.borrar);

    }
  }

  seleccionarTodo23(){
    this.todo23 = true;
    let valores = this.docs23.length;
    for( let i = 0; i < valores; i++){
      console.log(i);
      this.borrar[i]=this.docs23[i].ID;
      console.log(this.borrar);

    }

  }
  quitarSeleccion23(){
    let valores = this.borrar.length;
    for( let i = 0; i < valores; i++){
      console.log(i);
      this.borrar.pop();
      console.log(this.borrar);
      this.todo23 = false;
    }
  }

  checkCheckBoxvalue24(event:any, index:any, id: any){
    console.log(event.target.checked)
    this.todo24 = null;
    this.valor = event.target.checked;
    if(this.valor === true){
      console.log('verdadero');
      console.log(index);
      this.borrar[index]=this.docs24[index].ID;
      console.log(this.borrar);

    }else if(this.valor === false){
      console.log('falso');
      console.log(index);
      let indice = this.borrar.indexOf(id);
      this.borrar.splice(indice, 1);
      console.log(this.borrar);

    }
  }

  seleccionarTodo24(){
    this.todo24 = true;
    let valores = this.docs24.length;
    for( let i = 0; i < valores; i++){
      console.log(i);
      this.borrar[i]=this.docs24[i].ID;
      console.log(this.borrar);

    }

  }
  quitarSeleccion24(){
    let valores = this.borrar.length;
    for( let i = 0; i < valores; i++){
      console.log(i);
      this.borrar.pop();
      console.log(this.borrar);
      this.todo24 = false;
    }
  }

  eliminarSeleccionados(){

    this.alerts.alertaConfirmacionEliminar('Eliminar documento', '¿Desea eliminar este documento?')
    .then((res: any) => {

      if (res.isConfirmed) {
        for(let numero of this.borrar){
         console.log(numero);
         this.eliminarForm.ID = numero;
         this.eliminarForm.ESTATUS = 1;
         this.general.eliminarEnlace(this.eliminarForm).subscribe(
           (data: any) => {

           })
         this.alerts.alertaRealizado().then((res: any) => {
          location.reload()
         })

        }

      }

    })
  }

  a82019ENE() {
    this.service.obtenerA8MES(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2019, 1).subscribe(res => {
      this.docs19 = res.body
      console.log(this.docs19);
      console.log(this.area);
    })
  }

  a82019FEB() {
    this.service.obtenerA8MES(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2019, 2).subscribe(res => {
      this.docs19 = res.body
      console.log(this.docs19);
      console.log(this.area);
    })
  }


  a82019MAR() {
    this.service.obtenerA8MES(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2019, 3).subscribe(res => {
      this.docs19 = res.body
      console.log(this.docs19);
      console.log(this.area);
    })
  }


  a82019ABR() {
    this.service.obtenerA8MES(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2019, 4).subscribe(res => {
      this.docs19 = res.body
      console.log(this.docs19);
      console.log(this.area);
    })
  }

  a82019MAY() {
    this.service.obtenerA8MES(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2019, 5).subscribe(res => {
      this.docs19 = res.body
      console.log(this.docs19);
      console.log(this.area);
    })
  }

  a82019JUN() {
    this.service.obtenerA8MES(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2019, 6).subscribe(res => {
      this.docs19 = res.body
      console.log(this.docs19);
      console.log(this.area);
    })
  }


  a82019JUL() {
    this.service.obtenerA8MES(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2019, 7).subscribe(res => {
      this.docs19 = res.body
      console.log(this.docs19);
      console.log(this.area);
    })
  }


  a82019AGO() {
    this.service.obtenerA8MES(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2019, 8).subscribe(res => {
      this.docs19 = res.body
      console.log(this.docs19);
      console.log(this.area);
    })
  }


  a82019SEP() {
    this.service.obtenerA8MES(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2019, 9).subscribe(res => {
      this.docs19 = res.body
      console.log(this.docs19);
      console.log(this.area);
    })
  }


  a82019OCT() {
    this.service.obtenerA8MES(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2019, 10).subscribe(res => {
      this.docs19 = res.body
      console.log(this.docs19);
      console.log(this.area);
    })
  }


  a82019NOV() {
    this.service.obtenerA8MES(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2019, 11).subscribe(res => {
      this.docs19 = res.body
      console.log(this.docs19);
      console.log(this.area);
    })
  }



  a82019DIC() {
    this.service.obtenerA8MES(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2019, 12).subscribe(res => {
      this.docs19 = res.body
      console.log(this.docs19);
      console.log(this.area);
    })
  }



  a82018ENE() {
    this.service.obtenerA8MES(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2018, 1).subscribe(res => {
      this.docs18 = res.body
      console.log(res.body);

    })
  }

  a82018FEB() {
    this.service.obtenerA8MES(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2018, 2).subscribe(res => {
      this.docs18 = res.body
      console.log(this.docs18);
      console.log('18');
      console.log(this.area);
    })
  }


  a82018MAR() {
    this.service.obtenerA8MES(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2018, 3).subscribe(res => {
      this.docs18 = res.body
      console.log(this.docs18);
      console.log('18');
      console.log(this.area);
    })
  }


  a82018ABR() {
    this.service.obtenerA8MES(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2018, 4).subscribe(res => {
      this.docs18 = res.body
      console.log(this.docs18);
      console.log('18');
      console.log(this.area);
    })
  }

  a82018MAY() {
    this.service.obtenerA8MES(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2018, 5).subscribe(res => {
      this.docs18 = res.body
      console.log(this.docs18);
      console.log('18');
      console.log(this.area);
    })
  }

  a82018JUN() {
    this.service.obtenerA8MES(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2018, 6).subscribe(res => {
      this.docs18 = res.body
      console.log(this.docs18);
      console.log('18');
      console.log(this.area);
    })
  }


  a82018JUL() {
    this.service.obtenerA8MES(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2018, 7).subscribe(res => {
      this.docs18 = res.body
      console.log(this.docs18);
      console.log('18');
      console.log(this.area);
    })
  }


  a82018AGO() {
    this.service.obtenerA8MES(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2018, 8).subscribe(res => {
      this.docs18 = res.body
      console.log(this.docs18);
      console.log('18');
      console.log(this.area);
    })
  }


  a82018SEP() {
    this.service.obtenerA8MES(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2018, 9).subscribe(res => {
      this.docs18 = res.body
      console.log(this.docs18);
      console.log('18');
      console.log(this.area);
    })
  }


  a82018OCT() {
    this.service.obtenerA8MES(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2018, 10).subscribe(res => {
      this.docs18 = res.body
      console.log(this.docs18);
      console.log('18');
      console.log(this.area);
    })
  }


  a82018NOV() {
    this.service.obtenerA8MES(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2018, 11).subscribe(res => {
      this.docs18 = res.body
      console.log(this.docs18);
      console.log('18');
      console.log(this.area);
    })
  }



  a82018DIC() {
    this.service.obtenerA8MES(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2018, 12).subscribe(res => {
      this.docs18 = res.body
      console.log(this.docs18);
      console.log('18');
      console.log(this.area);
    })
  }



  a82020ENE() {
    this.service.obtenerA8MES(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2020, 1).subscribe(res => {
      this.docs20 = res.body
      console.log(this.docs20);
      console.log(this.area);
    })
  }

  a82020FEB() {
    this.service.obtenerA8MES(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2020, 2).subscribe(res => {
      this.docs20 = res.body
      console.log(this.docs20);
      console.log(this.area);
    })
  }


  a82020MAR() {
    this.service.obtenerA8MES(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2020, 3).subscribe(res => {
      this.docs20 = res.body
      console.log(this.docs20);
      console.log(this.area);
    })
  }


  a82020ABR() {
    this.service.obtenerA8MES(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2020, 4).subscribe(res => {
      this.docs20 = res.body
      console.log(this.docs20);
      console.log(this.area);
    })
  }

  a82020MAY() {
    this.service.obtenerA8MES(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2020, 5).subscribe(res => {
      this.docs20 = res.body
      console.log(this.docs20);
      console.log(this.area);
    })
  }

  a82020JUN() {
    this.service.obtenerA8MES(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2020, 6).subscribe(res => {
      this.docs20 = res.body
      console.log(this.docs20);
      console.log(this.area);
    })
  }


  a82020JUL() {
    this.service.obtenerA8MES(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2020, 7).subscribe(res => {
      this.docs20 = res.body
      console.log(this.docs20);
      console.log(this.area);
    })
  }


  a82020AGO() {
    this.service.obtenerA8MES(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2020, 8).subscribe(res => {
      this.docs20 = res.body
      console.log(this.docs20);
      console.log(this.area);
    })
  }


  a82020SEP() {
    this.service.obtenerA8MES(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2020, 9).subscribe(res => {
      this.docs20 = res.body
      console.log(this.docs20);
      console.log(this.area);
    })
  }


  a82020OCT() {
    this.service.obtenerA8MES(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2020, 10).subscribe(res => {
      this.docs20 = res.body
      console.log(this.docs20);
      console.log(this.area);
    })
  }


  a82020NOV() {
    this.service.obtenerA8MES(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2020, 11).subscribe(res => {
      this.docs20 = res.body
      console.log(this.docs20);
      console.log(this.area);
    })
  }



  a82020DIC() {
    this.service.obtenerA8MES(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2020, 12).subscribe(res => {
      this.docs20 = res.body
      console.log(this.docs20);
      console.log(this.area);
    })
  }



  a82022ENE() {
    this.service.obtenerA8MES(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2022, 1).subscribe(res => {
      this.docs22 = res.body
      console.log(this.docs22);
      console.log(this.area);
    })
  }

  a82022FEB() {
    this.service.obtenerA8MES(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2022, 2).subscribe(res => {
      this.docs22 = res.body
      console.log(this.docs22);
      console.log(this.area);
    })
  }


  a82022MAR() {
    this.service.obtenerA8MES(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2022, 3).subscribe(res => {
      this.docs22 = res.body
      console.log(this.docs22);
      console.log(this.area);
    })
  }


  a82022ABR() {
    this.service.obtenerA8MES(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2022, 4).subscribe(res => {
      this.docs22 = res.body
      console.log(this.docs22);
      console.log(this.area);
    })
  }

  a82022MAY() {
    this.service.obtenerA8MES(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2022, 5).subscribe(res => {
      this.docs22 = res.body
      console.log(this.docs22);
      console.log(this.area);
    })
  }

  a82022JUN() {
    this.service.obtenerA8MES(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2022, 6).subscribe(res => {
      this.docs22 = res.body
      console.log(this.docs22);
      console.log('18');
      console.log(this.area);
    })
  }


  a82022JUL() {
    this.service.obtenerA8MES(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2022, 7).subscribe(res => {
      this.docs22 = res.body
      console.log(this.docs22);
      console.log('18');
      console.log(this.area);
    })
  }


  a82022AGO() {
    this.service.obtenerA8MES(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2022, 8).subscribe(res => {
      this.docs22 = res.body
      console.log(this.docs22);
      console.log(this.area);
    })
  }


  a82022SEP() {
    this.service.obtenerA8MES(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2022, 9).subscribe(res => {
      this.docs22 = res.body
      console.log(this.docs22);
      console.log(this.area);
    })
  }


  a82022OCT() {
    this.service.obtenerA8MES(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2022, 10).subscribe(res => {
      this.docs22 = res.body
      console.log(this.docs22);
      console.log(this.area);
    })
  }


  a82022NOV() {
    this.service.obtenerA8MES(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2022, 11).subscribe(res => {
      this.docs22 = res.body
      console.log(this.docs22);
      console.log(this.area);
    })
  }



  a82022DIC() {
    this.service.obtenerA8MES(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2022, 12).subscribe(res => {
      this.docs22 = res.body
      console.log(this.docs22);
      console.log(this.area);
    })
  }

  a82024ENE() {
    this.service.obtenerA8MES(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2024, 1).subscribe(res => {
      this.docs24 = res.body
      console.log(this.docs24);
    })
  }

  a82024FEB() {
    this.service.obtenerA8MES(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2024, 2).subscribe(res => {
      this.docs24 = res.body
      console.log(this.docs24);
    })
  }


  a82024MAR() {
    this.service.obtenerA8MES(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2024, 3).subscribe(res => {
      this.docs24 = res.body
      console.log(this.docs24);
    })
  }


  a82024ABR() {
    this.service.obtenerA8MES(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2024, 4).subscribe(res => {
      this.docs24 = res.body
      console.log(this.docs24);
    })
  }

  a82024MAY() {
    this.service.obtenerA8MES(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2024, 5).subscribe(res => {
      this.docs24 = res.body
      console.log(this.docs24);
    })
  }

  a82024JUN() {
    this.service.obtenerA8MES(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2024, 6).subscribe(res => {
      this.docs24 = res.body
      console.log(this.docs24);
    })
  }


  a82024JUL() {
    this.service.obtenerA8MES(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2024, 7).subscribe(res => {
      this.docs24 = res.body
      console.log(this.docs24);
    })
  }


  a82024AGO() {
    this.service.obtenerA8MES(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2024, 8).subscribe(res => {
      this.docs24 = res.body
      console.log(this.docs24);
    })
  }


  a82024SEP() {
    this.service.obtenerA8MES(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2024, 9).subscribe(res => {
      this.docs24 = res.body
      console.log(this.docs24);
    })
  }


  a82024OCT() {
    this.service.obtenerA8MES(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2024, 10).subscribe(res => {
      this.docs24 = res.body
      console.log(this.docs24);
    })
  }


  a82024NOV() {
    this.service.obtenerA8MES(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2024, 11).subscribe(res => {
      this.docs24 = res.body
      console.log(this.docs24);
    })
  }



  a82024DIC() {
    this.service.obtenerA8MES(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2024, 12).subscribe(res => {
      this.docs24 = res.body
      console.log(this.docs24);
    })
  }


  a82023ENE() {
    this.service.obtenerA8MES(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2023, 1).subscribe(res => {
      this.docs23 = res.body
      console.log(this.docs23);
    })
  }

  a82023FEB() {
    this.service.obtenerA8MES(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2023, 2).subscribe(res => {
      this.docs23 = res.body
      console.log(this.docs23);
    })
  }


  a82023MAR() {
    this.service.obtenerA8MES(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2023, 3).subscribe(res => {
      this.docs23 = res.body
      console.log(this.docs23);
    })
  }


  a82023ABR() {
    this.service.obtenerA8MES(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2023, 4).subscribe(res => {
      this.docs23 = res.body
      console.log(this.docs23);
    })
  }

  a82023MAY() {
    this.service.obtenerA8MES(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2023, 5).subscribe(res => {
      this.docs23 = res.body
      console.log(this.docs23);
    })
  }

  a82023JUN() {
    this.service.obtenerA8MES(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2023, 6).subscribe(res => {
      this.docs23 = res.body
      console.log(this.docs23);
    })
  }


  a82023JUL() {
    this.service.obtenerA8MES(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2023, 7).subscribe(res => {
      this.docs23 = res.body
      console.log(this.docs23);
    })
  }


  a82023AGO() {
    this.service.obtenerA8MES(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2023, 8).subscribe(res => {
      this.docs23 = res.body
      console.log(this.docs23);
    })
  }


  a82023SEP() {
    this.service.obtenerA8MES(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2023, 9).subscribe(res => {
      this.docs23 = res.body
      console.log(this.docs23);
    })
  }


  a82023OCT() {
    this.service.obtenerA8MES(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2023, 10).subscribe(res => {
      this.docs23 = res.body
      console.log(this.docs23);
    })
  }


  a82023NOV() {
    this.service.obtenerA8MES(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2023, 11).subscribe(res => {
      this.docs23 = res.body
      console.log(this.docs23);
    })
  }



  a82023DIC() {
    this.service.obtenerA8MES(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2023, 12).subscribe(res => {
      this.docs23 = res.body
      console.log(this.docs23);
    })
  }



  a82021ENE() {
    this.service.obtenerA8MES(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2021, 1).subscribe(res => {
      this.docs21 = res.body
      console.log(this.docs21);
      console.log(this.area);
    })
  }

  a82021FEB() {
    this.service.obtenerA8MES(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2021, 2).subscribe(res => {
      this.docs21 = res.body
      console.log(this.docs21);
      console.log(this.area);
    })
  }


  a82021MAR() {
    this.service.obtenerA8MES(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2021, 3).subscribe(res => {
      this.docs21 = res.body
      console.log(this.docs21);
      console.log(this.area);
    })
  }


  a82021ABR() {
    this.service.obtenerA8MES(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2021, 4).subscribe(res => {
      this.docs21 = res.body
      console.log(this.docs21);
      console.log(this.area);
    })
  }

  a82021MAY() {
    this.service.obtenerA8MES(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2021, 5).subscribe(res => {
      this.docs21 = res.body
      console.log(this.docs21);
      console.log(this.area);
    })
  }

  a82021JUN() {
    this.service.obtenerA8MES(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2021, 6).subscribe(res => {
      this.docs21 = res.body
      console.log(this.docs21);
      console.log(this.area);
    })
  }


  a82021JUL() {
    this.service.obtenerA8MES(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2021, 7).subscribe(res => {
      this.docs21 = res.body
      console.log(this.docs21);
      console.log(this.area);
    })
  }


  a82021AGO() {
    this.service.obtenerA8MES(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2021, 8).subscribe(res => {
      this.docs21 = res.body
      console.log(this.docs21);
      console.log(this.area);
    })
  }


  a82021SEP() {
    this.service.obtenerA8MES(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2021, 9).subscribe(res => {
      this.docs21 = res.body
      console.log(this.docs21);
      console.log(this.area);
    })
  }


  a82021OCT() {
    this.service.obtenerA8MES(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2021, 10).subscribe(res => {
      this.docs21 = res.body
      console.log(this.docs21);
      console.log(this.area);
    })
  }


  a82021NOV() {
    this.service.obtenerA8MES(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2021, 11).subscribe(res => {
      this.docs21 = res.body
      console.log(this.docs21);
      console.log(this.area);
    })
  }



  a82021DIC() {
    this.service.obtenerA8MES(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2021, 12).subscribe(res => {
      this.docs21 = res.body
      console.log(this.docs21);
      console.log(this.area);
    })
  }

  eliminar(ID: any) {

    this.alerts.alertaConfirmacionEliminar('Eliminar documento', '¿Desea eliminar este documento?')
      .then((res: any) => {

        if (res.isConfirmed) {
          this.eliminarForm.ID = ID;
          this.eliminarForm.ESTATUS = 1;
          this.general.eliminarEnlace(this.eliminarForm).subscribe(
            (data: any) => {


              console.log(ID);

            })
          this.alerts.alertaRealizado().then((res: any) => {
            location.reload()
          })
        }

      })


  }
}
