<!DOCTYPE html>
<html lang="en">

<head>
  <meta charset="utf-8">
  <meta content="width=device-width, initial-scale=1.0" name="viewport">

  <meta content="" name="description">
  <meta content="" name="keywords">

  <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.1.1/animate.min.css" />

  <!-- Favicons -->
  <link href="/assets/img/favicon.png" rel="icon">
  <link href="/assets/img/apple-touch-icon.png" rel="apple-touch-icon">

  <!-- Google Fonts -->
  <link
    href="https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i|Roboto:300,300i,400,400i,500,500i,600,600i,700,700i|Poppins:300,300i,400,400i,500,500i,600,600i,700,700i"
    rel="stylesheet">

  <!-- Vendor CSS Files -->
  <link href="/assets/vendor/animate.css/animate.min.css" rel="stylesheet">
  <link href="/assets/vendor/aos/aos.css" rel="stylesheet">
  <link href="/assets/vendor/bootstrap/css/bootstrap.min.css" rel="stylesheet">
  <link href="/assets/vendor/bootstrap-icons/bootstrap-icons.css" rel="stylesheet">
  <link href="/assets/vendor/boxicons/css/boxicons.min.css" rel="stylesheet">
  <link href="/assets/vendor/glightbox/css/glightbox.min.css" rel="stylesheet">
  <link href="/assets/vendor/remixicon/remixicon.css" rel="stylesheet">
  <link href="/assets/vendor/swiper/swiper-bundle.min.css" rel="stylesheet">

  <!-- Template Main CSS File -->
  <link href="/assets/css/style.css" rel="stylesheet">

  <!-- =======================================================
  * Template Name: Company - v4.7.0
  * Template URL: https://bootstrapmade.com/company-free-html-bootstrap-template/
  * Author: BootstrapMade.com
  * License: https://bootstrapmade.com/license/
  ======================================================== -->
</head>

<body>



  <!--Main layout-->
  <main style="margin-top: 40px;">
    <div class="container pt-4"></div>
  </main>
  <!--Main layout-->
  <main id="main">

    <section style="margin-top: 40px;">
      <app-menu-compartido></app-menu-compartido>
      <div class="section-title" data-aos="fade-up">
        <h4 style="text-align: left; margin-left: 140px;">Los programas sociales que aplica el sujeto obligado, de
          cuando menos los últimos tres años</h4>
      </div>
    </section>

    <!-- ======= Breadcrumbs ======= -->
    <section id="breadcrumbs" class="breadcrumbs">
      <div class="container">

        <div class="d-flex justify-content-between align-items-center">
          <ol>
            <li>Inicio</li>
            <li><a href="admin/articulo-8">Articulo 8</a></li>
            <li>Fracción VI</li>
            <li>Inciso D</li>
          </ol>
        </div>

      </div>
    </section><!-- End Breadcrumbs -->


    <section id="blog" class="blog">
      <!-- ======= Inicio Seccion Carpetas por año ======= -->
      <div class="fondo2">
        <div id="scroll">
          <div class="panel-group" id="accordion" style="height:600px;">







            <div class="panel panel-default">
              <div class="panel-heading">
                <h4 class="panel-title">
                  <button (click)="carp1()" data-bs-toggle="collapse" data-bs-parent="#accordion" href="#collapse1123"
                    style="background: none; border: none;">
                    <i class="bi bi-folder-fill">Centro de convicencia y alimentación</i></button>
                </h4>
              </div>
              <div id="collapse1123" class="panel-collapse collapse in" data-bs-parent="#accordion">
                <div class="panel-group" id="accordion18" style="margin-left:20px;">







                  <div class="panel panel-default">
                    <div class="panel-heading">
                      <h4 class="panel-title">
                        <button (click)="an82021()" data-bs-toggle="collapse" data-bs-parent="#accordion18"
                          href="#collapse418" style="background: none; border: none;">
                          <i class="bi bi-folder-fill"> 2021</i></button>
                      </h4>
                    </div>
                    <div id="collapse418" class="panel-collapse collapse in" data-bs-parent="#accordion18">
                      <div class="panel-body">
                        <div style="margin: auto; width: 80%; height: 70px; display: flex; justify-content: right;"
                          *ngIf="boton === 1">
                          <button style="height: 38px;" (click)="seleccionarTodo18()" type="button"
                            class="btn btn-outline-dark">Seleccionar Todo</button>
                          <button style="height: 38px;  margin-left: 10px;" (click)="quitarSeleccion18()" type="button"
                            class="btn btn-outline-dark">Desahacer Selección</button>
                          <button style="height: 38px; margin-left: 10px;" (click)="eliminarSeleccionados()"
                            type="button" class="btn btn-outline-dark">Eliminar Seleccionados</button>
                        </div>
                        <div class="fondo">

                          <div id="scroll">
                            <table class="table table-striped">
                              <thead>
                                <tr>
                                  <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre
                                    del
                                    documento</th>
                                  <th scope="col"></th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr *ngFor="let dato of docs18, let i = index">
                                  <td><input type="checkbox" (change)="checkCheckBoxvalue18($event, i, dato?.ID)"
                                      *ngIf="boton === 1" [checked]="todo18">
                                    <img src="assets/img/docs.png" alt="" height="30" width="30">
                                    <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;"> {{dato?.NOMBRE}}</a>
                                  </td>

                                  <td>
                                    <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                      class="btn btn-outline-dark">Eliminar</button>
                                  </td>

                                </tr>
                              </tbody>
                            </table>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="panel panel-default">
                    <div class="panel-heading">
                      <h4 class="panel-title">
                        <button (click)="an82022()" data-bs-toggle="collapse" data-bs-parent="#accordion18"
                          href="#collapse518" style="background: none; border: none;">
                          <i class="bi bi-folder-fill"> 2022</i></button>
                      </h4>
                    </div>
                    <div id="collapse518" class="panel-collapse collapse in" data-bs-parent="#accordion18">
                      <div class="panel-body">
                        <div style="margin: auto; width: 80%; height: 70px; display: flex; justify-content: right;"
                          *ngIf="boton === 1">
                          <button style="height: 38px;" (click)="seleccionarTodo18()" type="button"
                            class="btn btn-outline-dark">Seleccionar Todo</button>
                          <button style="height: 38px;  margin-left: 10px;" (click)="quitarSeleccion18()" type="button"
                            class="btn btn-outline-dark">Desahacer Selección</button>
                          <button style="height: 38px; margin-left: 10px;" (click)="eliminarSeleccionados()"
                            type="button" class="btn btn-outline-dark">Eliminar Seleccionados</button>
                        </div>
                        <div class="fondo">

                          <div id="scroll">
                            <table class="table table-striped">
                              <thead>
                                <tr>
                                  <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre
                                    del
                                    documento</th>
                                  <th scope="col"></th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr *ngFor="let dato of docs18, let i = index">
                                  <td><input type="checkbox" (change)="checkCheckBoxvalue18($event, i, dato?.ID)"
                                      *ngIf="boton === 1" [checked]="todo18">
                                    <img src="assets/img/docs.png" alt="" height="30" width="30">
                                    <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;"> {{dato?.NOMBRE}}</a>
                                  </td>

                                  <td>
                                    <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                      class="btn btn-outline-dark">Eliminar</button>
                                  </td>

                                </tr>
                              </tbody>
                            </table>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="panel panel-default">
                    <div class="panel-heading">
                      <h4 class="panel-title">
                        <button (click)="an82023()" data-bs-toggle="collapse" data-bs-parent="#accordion18"
                          href="#collapse2118" style="background: none; border: none;">
                          <i class="bi bi-folder-fill"> 2023</i></button>
                      </h4>
                    </div>
                    <div id="collapse2118" class="panel-collapse collapse in" data-bs-parent="#accordion18">
                      <div class="panel-body">
                        <div style="margin: auto; width: 80%; height: 70px; display: flex; justify-content: right;"
                          *ngIf="boton === 1">
                          <button style="height: 38px;" (click)="seleccionarTodo18()" type="button"
                            class="btn btn-outline-dark">Seleccionar Todo</button>
                          <button style="height: 38px;  margin-left: 10px;" (click)="quitarSeleccion18()" type="button"
                            class="btn btn-outline-dark">Desahacer Selección</button>
                          <button style="height: 38px; margin-left: 10px;" (click)="eliminarSeleccionados()"
                            type="button" class="btn btn-outline-dark">Eliminar Seleccionados</button>
                        </div>
                        <div class="fondo">

                          <div id="scroll">
                            <table class="table table-striped">
                              <thead>
                                <tr>
                                  <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre
                                    del
                                    documento</th>
                                  <th scope="col"></th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr *ngFor="let dato of docs18, let i = index">
                                  <td><input type="checkbox" (change)="checkCheckBoxvalue18($event, i, dato?.ID)"
                                      *ngIf="boton === 1" [checked]="todo18">
                                    <img src="assets/img/docs.png" alt="" height="30" width="30">
                                    <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;"> {{dato?.NOMBRE}}</a>
                                  </td>

                                  <td>
                                    <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                      class="btn btn-outline-dark">Eliminar</button>
                                  </td>

                                </tr>
                              </tbody>
                            </table>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>


                  <div class="panel panel-default">
                    <div class="panel-heading">
                      <h4 class="panel-title">
                        <button (click)="an82024()" data-bs-toggle="collapse" data-bs-parent="#accordion18"
                          href="#collapse2118s" style="background: none; border: none;">
                          <i class="bi bi-folder-fill"> 2024</i></button>
                      </h4>
                    </div>
                    <div id="collapse2118s" class="panel-collapse collapse in" data-bs-parent="#accordion18">
                      <div class="panel-body">
                        <div style="margin: auto; width: 80%; height: 70px; display: flex; justify-content: right;"
                          *ngIf="boton === 1">
                          <button style="height: 38px;" (click)="seleccionarTodo24()" type="button"
                            class="btn btn-outline-dark">Seleccionar Todo</button>
                          <button style="height: 38px;  margin-left: 10px;" (click)="quitarSeleccion24()" type="button"
                            class="btn btn-outline-dark">Desahacer Selección</button>
                          <button style="height: 38px; margin-left: 10px;" (click)="eliminarSeleccionados()"
                            type="button" class="btn btn-outline-dark">Eliminar Seleccionados</button>
                        </div>
                        <div class="fondo">

                          <div id="scroll">
                            <table class="table table-striped">
                              <thead>
                                <tr>
                                  <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre
                                    del
                                    documento</th>
                                  <th scope="col"></th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr *ngFor="let dato of docs24, let i = index">
                                  <td><input type="checkbox" (change)="checkCheckBoxvalue24($event, i, dato?.ID)"
                                      *ngIf="boton === 1" [checked]="todo18">
                                    <img src="assets/img/docs.png" alt="" height="30" width="30">
                                    <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;"> {{dato?.NOMBRE}}</a>
                                  </td>

                                  <td>
                                    <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                      class="btn btn-outline-dark">Eliminar</button>
                                  </td>

                                </tr>
                              </tbody>
                            </table>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>




                </div>
              </div>
            </div>

            <div class="panel panel-default">
              <div class="panel-heading">
                <h4 class="panel-title">
                  <button (click)="carp2()" data-bs-toggle="collapse" data-bs-parent="#accordion" href="#collapse1223"
                    style="background: none; border: none;">
                    <i class="bi bi-folder-fill">Centro de dia</i></button>
                </h4>
              </div>
              <div id="collapse1223" class="panel-collapse collapse in" data-bs-parent="#accordion">
                <div class="panel-group" id="accordion19" style="margin-left:20px;">

                  <div class="panel panel-default">
                    <div class="panel-heading">
                      <h4 class="panel-title">
                        <button (click)="an82021()" data-bs-toggle="collapse" data-bs-parent="#accordion19"
                          href="#collapse41822" style="background: none; border: none;">
                          <i class="bi bi-folder-fill"> 2021</i></button>
                      </h4>
                    </div>
                    <div id="collapse41822" class="panel-collapse collapse in" data-bs-parent="#accordion19">
                      <div class="panel-body">
                        <div style="margin: auto; width: 80%; height: 70px; display: flex; justify-content: right;"
                          *ngIf="boton === 1">
                          <button style="height: 38px;" (click)="seleccionarTodo18()" type="button"
                            class="btn btn-outline-dark">Seleccionar Todo</button>
                          <button style="height: 38px;  margin-left: 10px;" (click)="quitarSeleccion18()" type="button"
                            class="btn btn-outline-dark">Desahacer Selección</button>
                          <button style="height: 38px; margin-left: 10px;" (click)="eliminarSeleccionados()"
                            type="button" class="btn btn-outline-dark">Eliminar Seleccionados</button>
                        </div>
                        <div class="fondo">

                          <div id="scroll">
                            <table class="table table-striped">
                              <thead>
                                <tr>
                                  <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre
                                    del
                                    documento</th>
                                  <th scope="col"></th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr *ngFor="let dato of docs18, let i = index">
                                  <td><input type="checkbox" (change)="checkCheckBoxvalue18($event, i, dato?.ID)"
                                      *ngIf="boton === 1" [checked]="todo18">
                                    <img src="assets/img/docs.png" alt="" height="30" width="30">
                                    <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;"> {{dato?.NOMBRE}}</a>
                                  </td>

                                  <td>
                                    <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                      class="btn btn-outline-dark">Eliminar</button>
                                  </td>

                                </tr>
                              </tbody>
                            </table>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="panel panel-default">
                    <div class="panel-heading">
                      <h4 class="panel-title">
                        <button (click)="an82022()" data-bs-toggle="collapse" data-bs-parent="#accordion19"
                          href="#collapse51822" style="background: none; border: none;">
                          <i class="bi bi-folder-fill"> 2022</i></button>
                      </h4>
                    </div>
                    <div id="collapse51822" class="panel-collapse collapse in" data-bs-parent="#accordion19">
                      <div class="panel-body">
                        <div style="margin: auto; width: 80%; height: 70px; display: flex; justify-content: right;"
                          *ngIf="boton === 1">
                          <button style="height: 38px;" (click)="seleccionarTodo18()" type="button"
                            class="btn btn-outline-dark">Seleccionar Todo</button>
                          <button style="height: 38px;  margin-left: 10px;" (click)="quitarSeleccion18()" type="button"
                            class="btn btn-outline-dark">Desahacer Selección</button>
                          <button style="height: 38px; margin-left: 10px;" (click)="eliminarSeleccionados()"
                            type="button" class="btn btn-outline-dark">Eliminar Seleccionados</button>
                        </div>
                        <div class="fondo">

                          <div id="scroll">
                            <table class="table table-striped">
                              <thead>
                                <tr>
                                  <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre
                                    del
                                    documento</th>
                                  <th scope="col"></th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr *ngFor="let dato of docs18, let i = index">
                                  <td><input type="checkbox" (change)="checkCheckBoxvalue18($event, i, dato?.ID)"
                                      *ngIf="boton === 1" [checked]="todo18">
                                    <img src="assets/img/docs.png" alt="" height="30" width="30">
                                    <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;"> {{dato?.NOMBRE}}</a>
                                  </td>

                                  <td>
                                    <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                      class="btn btn-outline-dark">Eliminar</button>
                                  </td>

                                </tr>
                              </tbody>
                            </table>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="panel panel-default">
                    <div class="panel-heading">
                      <h4 class="panel-title">
                        <button (click)="an82023()" data-bs-toggle="collapse" data-bs-parent="#accordion19"
                          href="#collapse211822" style="background: none; border: none;">
                          <i class="bi bi-folder-fill"> 2023</i></button>
                      </h4>
                    </div>
                    <div id="collapse211822" class="panel-collapse collapse in" data-bs-parent="#accordion19">
                      <div class="panel-body">
                        <div style="margin: auto; width: 80%; height: 70px; display: flex; justify-content: right;"
                          *ngIf="boton === 1">
                          <button style="height: 38px;" (click)="seleccionarTodo18()" type="button"
                            class="btn btn-outline-dark">Seleccionar Todo</button>
                          <button style="height: 38px;  margin-left: 10px;" (click)="quitarSeleccion18()" type="button"
                            class="btn btn-outline-dark">Desahacer Selección</button>
                          <button style="height: 38px; margin-left: 10px;" (click)="eliminarSeleccionados()"
                            type="button" class="btn btn-outline-dark">Eliminar Seleccionados</button>
                        </div>
                        <div class="fondo">

                          <div id="scroll">
                            <table class="table table-striped">
                              <thead>
                                <tr>
                                  <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre
                                    del
                                    documento</th>
                                  <th scope="col"></th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr *ngFor="let dato of docs18, let i = index">
                                  <td><input type="checkbox" (change)="checkCheckBoxvalue18($event, i, dato?.ID)"
                                      *ngIf="boton === 1" [checked]="todo18">
                                    <img src="assets/img/docs.png" alt="" height="30" width="30">
                                    <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;"> {{dato?.NOMBRE}}</a>
                                  </td>

                                  <td>
                                    <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                      class="btn btn-outline-dark">Eliminar</button>
                                  </td>

                                </tr>
                              </tbody>
                            </table>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="panel panel-default">
                    <div class="panel-heading">
                      <h4 class="panel-title">
                        <button (click)="an82024()" data-bs-toggle="collapse" data-bs-parent="#accordion19"
                          href="#collapse211822s" style="background: none; border: none;">
                          <i class="bi bi-folder-fill"> 2024</i></button>
                      </h4>
                    </div>
                    <div id="collapse211822s" class="panel-collapse collapse in" data-bs-parent="#accordion19">
                      <div class="panel-body">
                        <div style="margin: auto; width: 80%; height: 70px; display: flex; justify-content: right;"
                          *ngIf="boton === 1">
                          <button style="height: 38px;" (click)="seleccionarTodo24()" type="button"
                            class="btn btn-outline-dark">Seleccionar Todo</button>
                          <button style="height: 38px;  margin-left: 10px;" (click)="quitarSeleccion24()" type="button"
                            class="btn btn-outline-dark">Desahacer Selección</button>
                          <button style="height: 38px; margin-left: 10px;" (click)="eliminarSeleccionados()"
                            type="button" class="btn btn-outline-dark">Eliminar Seleccionados</button>
                        </div>
                        <div class="fondo">

                          <div id="scroll">
                            <table class="table table-striped">
                              <thead>
                                <tr>
                                  <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre
                                    del
                                    documento</th>
                                  <th scope="col"></th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr *ngFor="let dato of docs24, let i = index">
                                  <td><input type="checkbox" (change)="checkCheckBoxvalue24($event, i, dato?.ID)"
                                      *ngIf="boton === 1" [checked]="todo24">
                                    <img src="assets/img/docs.png" alt="" height="30" width="30">
                                    <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;"> {{dato?.NOMBRE}}</a>
                                  </td>

                                  <td>
                                    <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                      class="btn btn-outline-dark">Eliminar</button>
                                  </td>

                                </tr>
                              </tbody>
                            </table>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>


                </div>
              </div>
            </div>

            <div class="panel panel-default">
              <div class="panel-heading">
                <h4 class="panel-title">
                  <button (click)="carp3()" data-bs-toggle="collapse" data-bs-parent="#accordion" href="#collapse1223s"
                    style="background: none; border: none;">
                    <i class="bi bi-folder-fill">Coordinación Alimentaria</i></button>
                </h4>
              </div>
              <div id="collapse1223s" class="panel-collapse collapse in" data-bs-parent="#accordion">
                <div class="panel-group" id="accordion19" style="margin-left:20px;">

                  <div class="panel panel-default">
                    <div class="panel-heading">
                      <h4 class="panel-title">
                        <button (click)="an82021()" data-bs-toggle="collapse" data-bs-parent="#accordion19"
                          href="#collapse41822s" style="background: none; border: none;">
                          <i class="bi bi-folder-fill"> 2021</i></button>
                      </h4>
                    </div>
                    <div id="collapse41822s" class="panel-collapse collapse in" data-bs-parent="#accordion19">
                      <div class="panel-body">
                        <div style="margin: auto; width: 80%; height: 70px; display: flex; justify-content: right;"
                          *ngIf="boton === 1">
                          <button style="height: 38px;" (click)="seleccionarTodo18()" type="button"
                            class="btn btn-outline-dark">Seleccionar Todo</button>
                          <button style="height: 38px;  margin-left: 10px;" (click)="quitarSeleccion18()" type="button"
                            class="btn btn-outline-dark">Desahacer Selección</button>
                          <button style="height: 38px; margin-left: 10px;" (click)="eliminarSeleccionados()"
                            type="button" class="btn btn-outline-dark">Eliminar Seleccionados</button>
                        </div>
                        <div class="fondo">

                          <div id="scroll">
                            <table class="table table-striped">
                              <thead>
                                <tr>
                                  <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre
                                    del
                                    documento</th>
                                  <th scope="col"></th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr *ngFor="let dato of docs18, let i = index">
                                  <td><input type="checkbox" (change)="checkCheckBoxvalue18($event, i, dato?.ID)"
                                      *ngIf="boton === 1" [checked]="todo18">
                                    <img src="assets/img/docs.png" alt="" height="30" width="30">
                                    <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;"> {{dato?.NOMBRE}}</a>
                                  </td>

                                  <td>
                                    <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                      class="btn btn-outline-dark">Eliminar</button>
                                  </td>

                                </tr>
                              </tbody>
                            </table>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="panel panel-default">
                    <div class="panel-heading">
                      <h4 class="panel-title">
                        <button (click)="an82022()" data-bs-toggle="collapse" data-bs-parent="#accordion19"
                          href="#collapse51822s" style="background: none; border: none;">
                          <i class="bi bi-folder-fill"> 2022</i></button>
                      </h4>
                    </div>
                    <div id="collapse51822s" class="panel-collapse collapse in" data-bs-parent="#accordion19">
                      <div class="panel-body">
                        <div style="margin: auto; width: 80%; height: 70px; display: flex; justify-content: right;"
                          *ngIf="boton === 1">
                          <button style="height: 38px;" (click)="seleccionarTodo18()" type="button"
                            class="btn btn-outline-dark">Seleccionar Todo</button>
                          <button style="height: 38px;  margin-left: 10px;" (click)="quitarSeleccion18()" type="button"
                            class="btn btn-outline-dark">Desahacer Selección</button>
                          <button style="height: 38px; margin-left: 10px;" (click)="eliminarSeleccionados()"
                            type="button" class="btn btn-outline-dark">Eliminar Seleccionados</button>
                        </div>
                        <div class="fondo">

                          <div id="scroll">
                            <table class="table table-striped">
                              <thead>
                                <tr>
                                  <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre
                                    del
                                    documento</th>
                                  <th scope="col"></th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr *ngFor="let dato of docs18, let i = index">
                                  <td><input type="checkbox" (change)="checkCheckBoxvalue18($event, i, dato?.ID)"
                                      *ngIf="boton === 1" [checked]="todo18">
                                    <img src="assets/img/docs.png" alt="" height="30" width="30">
                                    <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;"> {{dato?.NOMBRE}}</a>
                                  </td>

                                  <td>
                                    <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                      class="btn btn-outline-dark">Eliminar</button>
                                  </td>

                                </tr>
                              </tbody>
                            </table>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="panel panel-default">
                    <div class="panel-heading">
                      <h4 class="panel-title">
                        <button (click)="an82023()" data-bs-toggle="collapse" data-bs-parent="#accordion19"
                          href="#collapse211822s" style="background: none; border: none;">
                          <i class="bi bi-folder-fill"> 2023</i></button>
                      </h4>
                    </div>
                    <div id="collapse211822s" class="panel-collapse collapse in" data-bs-parent="#accordion19">
                      <div class="panel-body">
                        <div style="margin: auto; width: 80%; height: 70px; display: flex; justify-content: right;"
                          *ngIf="boton === 1">
                          <button style="height: 38px;" (click)="seleccionarTodo18()" type="button"
                            class="btn btn-outline-dark">Seleccionar Todo</button>
                          <button style="height: 38px;  margin-left: 10px;" (click)="quitarSeleccion18()" type="button"
                            class="btn btn-outline-dark">Desahacer Selección</button>
                          <button style="height: 38px; margin-left: 10px;" (click)="eliminarSeleccionados()"
                            type="button" class="btn btn-outline-dark">Eliminar Seleccionados</button>
                        </div>
                        <div class="fondo">

                          <div id="scroll">
                            <table class="table table-striped">
                              <thead>
                                <tr>
                                  <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre
                                    del
                                    documento</th>
                                  <th scope="col"></th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr *ngFor="let dato of docs18, let i = index">
                                  <td><input type="checkbox" (change)="checkCheckBoxvalue18($event, i, dato?.ID)"
                                      *ngIf="boton === 1" [checked]="todo18">
                                    <img src="assets/img/docs.png" alt="" height="30" width="30">
                                    <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;"> {{dato?.NOMBRE}}</a>
                                  </td>

                                  <td>
                                    <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                      class="btn btn-outline-dark">Eliminar</button>
                                  </td>

                                </tr>
                              </tbody>
                            </table>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="panel panel-default">
                    <div class="panel-heading">
                      <h4 class="panel-title">
                        <button (click)="an82024()" data-bs-toggle="collapse" data-bs-parent="#accordion19"
                          href="#collapse211822ss" style="background: none; border: none;">
                          <i class="bi bi-folder-fill"> 2024</i></button>
                      </h4>
                    </div>
                    <div id="collapse211822ss" class="panel-collapse collapse in" data-bs-parent="#accordion19">
                      <div class="panel-body">
                        <div style="margin: auto; width: 80%; height: 70px; display: flex; justify-content: right;"
                          *ngIf="boton === 1">
                          <button style="height: 38px;" (click)="seleccionarTodo24()" type="button"
                            class="btn btn-outline-dark">Seleccionar Todo</button>
                          <button style="height: 38px;  margin-left: 10px;" (click)="quitarSeleccion24()" type="button"
                            class="btn btn-outline-dark">Desahacer Selección</button>
                          <button style="height: 38px; margin-left: 10px;" (click)="eliminarSeleccionados()"
                            type="button" class="btn btn-outline-dark">Eliminar Seleccionados</button>
                        </div>
                        <div class="fondo">

                          <div id="scroll">
                            <table class="table table-striped">
                              <thead>
                                <tr>
                                  <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre
                                    del
                                    documento</th>
                                  <th scope="col"></th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr *ngFor="let dato of docs24, let i = index">
                                  <td><input type="checkbox" (change)="checkCheckBoxvalue24($event, i, dato?.ID)"
                                      *ngIf="boton === 1" [checked]="todo24">
                                    <img src="assets/img/docs.png" alt="" height="30" width="30">
                                    <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;"> {{dato?.NOMBRE}}</a>
                                  </td>

                                  <td>
                                    <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                      class="btn btn-outline-dark">Eliminar</button>
                                  </td>

                                </tr>
                              </tbody>
                            </table>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>


                </div>
              </div>
            </div>

            <div class="panel panel-default">
              <div class="panel-heading">
                <h4 class="panel-title">
                  <button (click)="carp4()" data-bs-toggle="collapse" data-bs-parent="#accordion" href="#collapse1223sa"
                    style="background: none; border: none;">
                    <i class="bi bi-folder-fill">Dirección</i></button>
                </h4>
              </div>
              <div id="collapse1223sa" class="panel-collapse collapse in" data-bs-parent="#accordion">
                <div class="panel-group" id="accordion19" style="margin-left:20px;">

                  <div class="panel panel-default">
                    <div class="panel-heading">
                      <h4 class="panel-title">
                        <button (click)="an82021()" data-bs-toggle="collapse" data-bs-parent="#accordion19"
                          href="#collapse41822sa" style="background: none; border: none;">
                          <i class="bi bi-folder-fill"> 2021</i></button>
                      </h4>
                    </div>
                    <div id="collapse41822ss" class="panel-collapse collapse in" data-bs-parent="#accordion19">
                      <div class="panel-body">
                        <div style="margin: auto; width: 80%; height: 70px; display: flex; justify-content: right;"
                          *ngIf="boton === 1">
                          <button style="height: 38px;" (click)="seleccionarTodo18()" type="button"
                            class="btn btn-outline-dark">Seleccionar Todo</button>
                          <button style="height: 38px;  margin-left: 10px;" (click)="quitarSeleccion18()" type="button"
                            class="btn btn-outline-dark">Desahacer Selección</button>
                          <button style="height: 38px; margin-left: 10px;" (click)="eliminarSeleccionados()"
                            type="button" class="btn btn-outline-dark">Eliminar Seleccionados</button>
                        </div>
                        <div class="fondo">

                          <div id="scroll">
                            <table class="table table-striped">
                              <thead>
                                <tr>
                                  <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre
                                    del
                                    documento</th>
                                  <th scope="col"></th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr *ngFor="let dato of docs18, let i = index">
                                  <td><input type="checkbox" (change)="checkCheckBoxvalue18($event, i, dato?.ID)"
                                      *ngIf="boton === 1" [checked]="todo18">
                                    <img src="assets/img/docs.png" alt="" height="30" width="30">
                                    <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;"> {{dato?.NOMBRE}}</a>
                                  </td>

                                  <td>
                                    <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                      class="btn btn-outline-dark">Eliminar</button>
                                  </td>

                                </tr>
                              </tbody>
                            </table>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="panel panel-default">
                    <div class="panel-heading">
                      <h4 class="panel-title">
                        <button (click)="an82022()" data-bs-toggle="collapse" data-bs-parent="#accordion19"
                          href="#collapse51822sa" style="background: none; border: none;">
                          <i class="bi bi-folder-fill"> 2022</i></button>
                      </h4>
                    </div>
                    <div id="collapse51822sa" class="panel-collapse collapse in" data-bs-parent="#accordion19">
                      <div class="panel-body">
                        <div style="margin: auto; width: 80%; height: 70px; display: flex; justify-content: right;"
                          *ngIf="boton === 1">
                          <button style="height: 38px;" (click)="seleccionarTodo18()" type="button"
                            class="btn btn-outline-dark">Seleccionar Todo</button>
                          <button style="height: 38px;  margin-left: 10px;" (click)="quitarSeleccion18()" type="button"
                            class="btn btn-outline-dark">Desahacer Selección</button>
                          <button style="height: 38px; margin-left: 10px;" (click)="eliminarSeleccionados()"
                            type="button" class="btn btn-outline-dark">Eliminar Seleccionados</button>
                        </div>
                        <div class="fondo">

                          <div id="scroll">
                            <table class="table table-striped">
                              <thead>
                                <tr>
                                  <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre
                                    del
                                    documento</th>
                                  <th scope="col"></th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr *ngFor="let dato of docs18, let i = index">
                                  <td><input type="checkbox" (change)="checkCheckBoxvalue18($event, i, dato?.ID)"
                                      *ngIf="boton === 1" [checked]="todo18">
                                    <img src="assets/img/docs.png" alt="" height="30" width="30">
                                    <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;"> {{dato?.NOMBRE}}</a>
                                  </td>

                                  <td>
                                    <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                      class="btn btn-outline-dark">Eliminar</button>
                                  </td>

                                </tr>
                              </tbody>
                            </table>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="panel panel-default">
                    <div class="panel-heading">
                      <h4 class="panel-title">
                        <button (click)="an82023()" data-bs-toggle="collapse" data-bs-parent="#accordion19"
                          href="#collapse211822sa" style="background: none; border: none;">
                          <i class="bi bi-folder-fill"> 2023</i></button>
                      </h4>
                    </div>
                    <div id="collapse211822sa" class="panel-collapse collapse in" data-bs-parent="#accordion19">
                      <div class="panel-body">
                        <div style="margin: auto; width: 80%; height: 70px; display: flex; justify-content: right;"
                          *ngIf="boton === 1">
                          <button style="height: 38px;" (click)="seleccionarTodo18()" type="button"
                            class="btn btn-outline-dark">Seleccionar Todo</button>
                          <button style="height: 38px;  margin-left: 10px;" (click)="quitarSeleccion18()" type="button"
                            class="btn btn-outline-dark">Desahacer Selección</button>
                          <button style="height: 38px; margin-left: 10px;" (click)="eliminarSeleccionados()"
                            type="button" class="btn btn-outline-dark">Eliminar Seleccionados</button>
                        </div>
                        <div class="fondo">

                          <div id="scroll">
                            <table class="table table-striped">
                              <thead>
                                <tr>
                                  <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre
                                    del
                                    documento</th>
                                  <th scope="col"></th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr *ngFor="let dato of docs18, let i = index">
                                  <td><input type="checkbox" (change)="checkCheckBoxvalue18($event, i, dato?.ID)"
                                      *ngIf="boton === 1" [checked]="todo18">
                                    <img src="assets/img/docs.png" alt="" height="30" width="30">
                                    <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;"> {{dato?.NOMBRE}}</a>
                                  </td>

                                  <td>
                                    <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                      class="btn btn-outline-dark">Eliminar</button>
                                  </td>

                                </tr>
                              </tbody>
                            </table>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="panel panel-default">
                    <div class="panel-heading">
                      <h4 class="panel-title">
                        <button (click)="an82024()" data-bs-toggle="collapse" data-bs-parent="#accordion19"
                          href="#collapse211822ssa" style="background: none; border: none;">
                          <i class="bi bi-folder-fill"> 2024</i></button>
                      </h4>
                    </div>
                    <div id="collapse211822ssa" class="panel-collapse collapse in" data-bs-parent="#accordion19">
                      <div class="panel-body">
                        <div style="margin: auto; width: 80%; height: 70px; display: flex; justify-content: right;"
                          *ngIf="boton === 1">
                          <button style="height: 38px;" (click)="seleccionarTodo24()" type="button"
                            class="btn btn-outline-dark">Seleccionar Todo</button>
                          <button style="height: 38px;  margin-left: 10px;" (click)="quitarSeleccion24()" type="button"
                            class="btn btn-outline-dark">Desahacer Selección</button>
                          <button style="height: 38px; margin-left: 10px;" (click)="eliminarSeleccionados()"
                            type="button" class="btn btn-outline-dark">Eliminar Seleccionados</button>
                        </div>
                        <div class="fondo">

                          <div id="scroll">
                            <table class="table table-striped">
                              <thead>
                                <tr>
                                  <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre
                                    del
                                    documento</th>
                                  <th scope="col"></th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr *ngFor="let dato of docs24, let i = index">
                                  <td><input type="checkbox" (change)="checkCheckBoxvalue24($event, i, dato?.ID)"
                                      *ngIf="boton === 1" [checked]="todo24">
                                    <img src="assets/img/docs.png" alt="" height="30" width="30">
                                    <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;"> {{dato?.NOMBRE}}</a>
                                  </td>

                                  <td>
                                    <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                      class="btn btn-outline-dark">Eliminar</button>
                                  </td>

                                </tr>
                              </tbody>
                            </table>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>


                </div>
              </div>
            </div>


            <div class="panel panel-default">
              <div class="panel-heading">
                <h4 class="panel-title">
                  <button (click)="carp5()" data-bs-toggle="collapse" data-bs-parent="#accordion" href="#collapse1223saes"
                    style="background: none; border: none;">
                    <i class="bi bi-folder-fill">Trabajo Social</i></button>
                </h4>
              </div>
              <div id="collapse1223saes" class="panel-collapse collapse in" data-bs-parent="#accordion">
                <div class="panel-group" id="accordion19" style="margin-left:20px;">

                  <div class="panel panel-default">
                    <div class="panel-heading">
                      <h4 class="panel-title">
                        <button (click)="an82021()" data-bs-toggle="collapse" data-bs-parent="#accordion19"
                          href="#collapse41822saes" style="background: none; border: none;">
                          <i class="bi bi-folder-fill"> 2021</i></button>
                      </h4>
                    </div>
                    <div id="collapse41822saes" class="panel-collapse collapse in" data-bs-parent="#accordion19">
                      <div class="panel-body">
                        <div style="margin: auto; width: 80%; height: 70px; display: flex; justify-content: right;"
                          *ngIf="boton === 1">
                          <button style="height: 38px;" (click)="seleccionarTodo18()" type="button"
                            class="btn btn-outline-dark">Seleccionar Todo</button>
                          <button style="height: 38px;  margin-left: 10px;" (click)="quitarSeleccion18()" type="button"
                            class="btn btn-outline-dark">Desahacer Selección</button>
                          <button style="height: 38px; margin-left: 10px;" (click)="eliminarSeleccionados()"
                            type="button" class="btn btn-outline-dark">Eliminar Seleccionados</button>
                        </div>
                        <div class="fondo">

                          <div id="scroll">
                            <table class="table table-striped">
                              <thead>
                                <tr>
                                  <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre
                                    del
                                    documento</th>
                                  <th scope="col"></th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr *ngFor="let dato of docs18, let i = index">
                                  <td><input type="checkbox" (change)="checkCheckBoxvalue18($event, i, dato?.ID)"
                                      *ngIf="boton === 1" [checked]="todo18">
                                    <img src="assets/img/docs.png" alt="" height="30" width="30">
                                    <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;"> {{dato?.NOMBRE}}</a>
                                  </td>

                                  <td>
                                    <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                      class="btn btn-outline-dark">Eliminar</button>
                                  </td>

                                </tr>
                              </tbody>
                            </table>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="panel panel-default">
                    <div class="panel-heading">
                      <h4 class="panel-title">
                        <button (click)="an82022()" data-bs-toggle="collapse" data-bs-parent="#accordion19"
                          href="#collapse51822ssas" style="background: none; border: none;">
                          <i class="bi bi-folder-fill"> 2022</i></button>
                      </h4>
                    </div>
                    <div id="collapse51822ssas" class="panel-collapse collapse in" data-bs-parent="#accordion19">
                      <div class="panel-body">
                        <div style="margin: auto; width: 80%; height: 70px; display: flex; justify-content: right;"
                          *ngIf="boton === 1">
                          <button style="height: 38px;" (click)="seleccionarTodo18()" type="button"
                            class="btn btn-outline-dark">Seleccionar Todo</button>
                          <button style="height: 38px;  margin-left: 10px;" (click)="quitarSeleccion18()" type="button"
                            class="btn btn-outline-dark">Desahacer Selección</button>
                          <button style="height: 38px; margin-left: 10px;" (click)="eliminarSeleccionados()"
                            type="button" class="btn btn-outline-dark">Eliminar Seleccionados</button>
                        </div>
                        <div class="fondo">

                          <div id="scroll">
                            <table class="table table-striped">
                              <thead>
                                <tr>
                                  <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre
                                    del
                                    documento</th>
                                  <th scope="col"></th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr *ngFor="let dato of docs18, let i = index">
                                  <td><input type="checkbox" (change)="checkCheckBoxvalue18($event, i, dato?.ID)"
                                      *ngIf="boton === 1" [checked]="todo18">
                                    <img src="assets/img/docs.png" alt="" height="30" width="30">
                                    <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;"> {{dato?.NOMBRE}}</a>
                                  </td>

                                  <td>
                                    <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                      class="btn btn-outline-dark">Eliminar</button>
                                  </td>

                                </tr>
                              </tbody>
                            </table>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="panel panel-default">
                    <div class="panel-heading">
                      <h4 class="panel-title">
                        <button (click)="an82023()" data-bs-toggle="collapse" data-bs-parent="#accordion19"
                          href="#collapse211822saes" style="background: none; border: none;">
                          <i class="bi bi-folder-fill"> 2023</i></button>
                      </h4>
                    </div>
                    <div id="collapse211822saes" class="panel-collapse collapse in" data-bs-parent="#accordion19">
                      <div class="panel-body">
                        <div style="margin: auto; width: 80%; height: 70px; display: flex; justify-content: right;"
                          *ngIf="boton === 1">
                          <button style="height: 38px;" (click)="seleccionarTodo18()" type="button"
                            class="btn btn-outline-dark">Seleccionar Todo</button>
                          <button style="height: 38px;  margin-left: 10px;" (click)="quitarSeleccion18()" type="button"
                            class="btn btn-outline-dark">Desahacer Selección</button>
                          <button style="height: 38px; margin-left: 10px;" (click)="eliminarSeleccionados()"
                            type="button" class="btn btn-outline-dark">Eliminar Seleccionados</button>
                        </div>
                        <div class="fondo">

                          <div id="scroll">
                            <table class="table table-striped">
                              <thead>
                                <tr>
                                  <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre
                                    del
                                    documento</th>
                                  <th scope="col"></th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr *ngFor="let dato of docs18, let i = index">
                                  <td><input type="checkbox" (change)="checkCheckBoxvalue18($event, i, dato?.ID)"
                                      *ngIf="boton === 1" [checked]="todo18">
                                    <img src="assets/img/docs.png" alt="" height="30" width="30">
                                    <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;"> {{dato?.NOMBRE}}</a>
                                  </td>

                                  <td>
                                    <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                      class="btn btn-outline-dark">Eliminar</button>
                                  </td>

                                </tr>
                              </tbody>
                            </table>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="panel panel-default">
                    <div class="panel-heading">
                      <h4 class="panel-title">
                        <button (click)="an82024()" data-bs-toggle="collapse" data-bs-parent="#accordion19"
                          href="#collapse211822ssas" style="background: none; border: none;">
                          <i class="bi bi-folder-fill"> 2024</i></button>
                      </h4>
                    </div>
                    <div id="collapse211822ssas" class="panel-collapse collapse in" data-bs-parent="#accordion19">
                      <div class="panel-body">
                        <div style="margin: auto; width: 80%; height: 70px; display: flex; justify-content: right;"
                          *ngIf="boton === 1">
                          <button style="height: 38px;" (click)="seleccionarTodo24()" type="button"
                            class="btn btn-outline-dark">Seleccionar Todo</button>
                          <button style="height: 38px;  margin-left: 10px;" (click)="quitarSeleccion24()" type="button"
                            class="btn btn-outline-dark">Desahacer Selección</button>
                          <button style="height: 38px; margin-left: 10px;" (click)="eliminarSeleccionados()"
                            type="button" class="btn btn-outline-dark">Eliminar Seleccionados</button>
                        </div>
                        <div class="fondo">

                          <div id="scroll">
                            <table class="table table-striped">
                              <thead>
                                <tr>
                                  <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre
                                    del
                                    documento</th>
                                  <th scope="col"></th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr *ngFor="let dato of docs24, let i = index">
                                  <td><input type="checkbox" (change)="checkCheckBoxvalue24($event, i, dato?.ID)"
                                      *ngIf="boton === 1" [checked]="todo24">
                                    <img src="assets/img/docs.png" alt="" height="30" width="30">
                                    <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;"> {{dato?.NOMBRE}}</a>
                                  </td>

                                  <td>
                                    <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                      class="btn btn-outline-dark">Eliminar</button>
                                  </td>

                                </tr>
                              </tbody>
                            </table>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>


                </div>
              </div>
            </div>

            <div class="panel panel-default">
              <div class="panel-heading">
                <h4 class="panel-title">
                  <button (click)="carp6()" data-bs-toggle="collapse" data-bs-parent="#accordion" href="#collapse1223saes1"
                    style="background: none; border: none;">
                    <i class="bi bi-folder-fill">Delegación</i></button>
                </h4>
              </div>
              <div id="collapse1223saes1" class="panel-collapse collapse in" data-bs-parent="#accordion">
                <div class="panel-group" id="accordion19" style="margin-left:20px;">

                  <div class="panel panel-default">
                    <div class="panel-heading">
                      <h4 class="panel-title">
                        <button (click)="an82021()" data-bs-toggle="collapse" data-bs-parent="#accordion19"
                          href="#collapse41822saes1" style="background: none; border: none;">
                          <i class="bi bi-folder-fill"> 2021</i></button>
                      </h4>
                    </div>
                    <div id="collapse41822saes1" class="panel-collapse collapse in" data-bs-parent="#accordion19">
                      <div class="panel-body">
                        <div style="margin: auto; width: 80%; height: 70px; display: flex; justify-content: right;"
                          *ngIf="boton === 1">
                          <button style="height: 38px;" (click)="seleccionarTodo18()" type="button"
                            class="btn btn-outline-dark">Seleccionar Todo</button>
                          <button style="height: 38px;  margin-left: 10px;" (click)="quitarSeleccion18()" type="button"
                            class="btn btn-outline-dark">Desahacer Selección</button>
                          <button style="height: 38px; margin-left: 10px;" (click)="eliminarSeleccionados()"
                            type="button" class="btn btn-outline-dark">Eliminar Seleccionados</button>
                        </div>
                        <div class="fondo">

                          <div id="scroll">
                            <table class="table table-striped">
                              <thead>
                                <tr>
                                  <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre
                                    del
                                    documento</th>
                                  <th scope="col"></th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr *ngFor="let dato of docs18, let i = index">
                                  <td><input type="checkbox" (change)="checkCheckBoxvalue18($event, i, dato?.ID)"
                                      *ngIf="boton === 1" [checked]="todo18">
                                    <img src="assets/img/docs.png" alt="" height="30" width="30">
                                    <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;"> {{dato?.NOMBRE}}</a>
                                  </td>

                                  <td>
                                    <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                      class="btn btn-outline-dark">Eliminar</button>
                                  </td>

                                </tr>
                              </tbody>
                            </table>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="panel panel-default">
                    <div class="panel-heading">
                      <h4 class="panel-title">
                        <button (click)="an82022()" data-bs-toggle="collapse" data-bs-parent="#accordion19"
                          href="#collapse51822ssas1" style="background: none; border: none;">
                          <i class="bi bi-folder-fill"> 2022</i></button>
                      </h4>
                    </div>
                    <div id="collapse51822ssas1" class="panel-collapse collapse in" data-bs-parent="#accordion19">
                      <div class="panel-body">
                        <div style="margin: auto; width: 80%; height: 70px; display: flex; justify-content: right;"
                          *ngIf="boton === 1">
                          <button style="height: 38px;" (click)="seleccionarTodo18()" type="button"
                            class="btn btn-outline-dark">Seleccionar Todo</button>
                          <button style="height: 38px;  margin-left: 10px;" (click)="quitarSeleccion18()" type="button"
                            class="btn btn-outline-dark">Desahacer Selección</button>
                          <button style="height: 38px; margin-left: 10px;" (click)="eliminarSeleccionados()"
                            type="button" class="btn btn-outline-dark">Eliminar Seleccionados</button>
                        </div>
                        <div class="fondo">

                          <div id="scroll">
                            <table class="table table-striped">
                              <thead>
                                <tr>
                                  <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre
                                    del
                                    documento</th>
                                  <th scope="col"></th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr *ngFor="let dato of docs18, let i = index">
                                  <td><input type="checkbox" (change)="checkCheckBoxvalue18($event, i, dato?.ID)"
                                      *ngIf="boton === 1" [checked]="todo18">
                                    <img src="assets/img/docs.png" alt="" height="30" width="30">
                                    <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;"> {{dato?.NOMBRE}}</a>
                                  </td>

                                  <td>
                                    <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                      class="btn btn-outline-dark">Eliminar</button>
                                  </td>

                                </tr>
                              </tbody>
                            </table>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="panel panel-default">
                    <div class="panel-heading">
                      <h4 class="panel-title">
                        <button (click)="an82023()" data-bs-toggle="collapse" data-bs-parent="#accordion19"
                          href="#collapse211822saes1" style="background: none; border: none;">
                          <i class="bi bi-folder-fill"> 2023</i></button>
                      </h4>
                    </div>
                    <div id="collapse211822saes1" class="panel-collapse collapse in" data-bs-parent="#accordion19">
                      <div class="panel-body">
                        <div style="margin: auto; width: 80%; height: 70px; display: flex; justify-content: right;"
                          *ngIf="boton === 1">
                          <button style="height: 38px;" (click)="seleccionarTodo18()" type="button"
                            class="btn btn-outline-dark">Seleccionar Todo</button>
                          <button style="height: 38px;  margin-left: 10px;" (click)="quitarSeleccion18()" type="button"
                            class="btn btn-outline-dark">Desahacer Selección</button>
                          <button style="height: 38px; margin-left: 10px;" (click)="eliminarSeleccionados()"
                            type="button" class="btn btn-outline-dark">Eliminar Seleccionados</button>
                        </div>
                        <div class="fondo">

                          <div id="scroll">
                            <table class="table table-striped">
                              <thead>
                                <tr>
                                  <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre
                                    del
                                    documento</th>
                                  <th scope="col"></th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr *ngFor="let dato of docs18, let i = index">
                                  <td><input type="checkbox" (change)="checkCheckBoxvalue18($event, i, dato?.ID)"
                                      *ngIf="boton === 1" [checked]="todo18">
                                    <img src="assets/img/docs.png" alt="" height="30" width="30">
                                    <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;"> {{dato?.NOMBRE}}</a>
                                  </td>

                                  <td>
                                    <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                      class="btn btn-outline-dark">Eliminar</button>
                                  </td>

                                </tr>
                              </tbody>
                            </table>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="panel panel-default">
                    <div class="panel-heading">
                      <h4 class="panel-title">
                        <button (click)="an82024()" data-bs-toggle="collapse" data-bs-parent="#accordion19"
                          href="#collapse211822ssas1" style="background: none; border: none;">
                          <i class="bi bi-folder-fill"> 2024</i></button>
                      </h4>
                    </div>
                    <div id="collapse211822ssas1" class="panel-collapse collapse in" data-bs-parent="#accordion19">
                      <div class="panel-body">
                        <div style="margin: auto; width: 80%; height: 70px; display: flex; justify-content: right;"
                          *ngIf="boton === 1">
                          <button style="height: 38px;" (click)="seleccionarTodo24()" type="button"
                            class="btn btn-outline-dark">Seleccionar Todo</button>
                          <button style="height: 38px;  margin-left: 10px;" (click)="quitarSeleccion24()" type="button"
                            class="btn btn-outline-dark">Desahacer Selección</button>
                          <button style="height: 38px; margin-left: 10px;" (click)="eliminarSeleccionados()"
                            type="button" class="btn btn-outline-dark">Eliminar Seleccionados</button>
                        </div>
                        <div class="fondo">

                          <div id="scroll">
                            <table class="table table-striped">
                              <thead>
                                <tr>
                                  <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre
                                    del
                                    documento</th>
                                  <th scope="col"></th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr *ngFor="let dato of docs24, let i = index">
                                  <td><input type="checkbox" (change)="checkCheckBoxvalue24($event, i, dato?.ID)"
                                      *ngIf="boton === 1" [checked]="todo24">
                                    <img src="assets/img/docs.png" alt="" height="30" width="30">
                                    <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;"> {{dato?.NOMBRE}}</a>
                                  </td>

                                  <td>
                                    <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                      class="btn btn-outline-dark">Eliminar</button>
                                  </td>

                                </tr>
                              </tbody>
                            </table>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>


                </div>
              </div>
            </div>




          </div>
        </div>
      </div>



      <!--Ventana Modal Editar Nombre-->

      <!--Termina Ventana Modal Accion Editar Nombre-->


    </section>
  </main><!-- End #main -->


  <a href="#" class="back-to-top d-flex align-items-center justify-content-center"><i
      class="bi bi-arrow-up-short"></i></a>

  <!-- Vendor JS Files -->
  <script src="/assets/vendor/aos/aos.js"></script>
  <script src="/assets/vendor/bootstrap/js/bootstrap.bundle.min.js"></script>
  <script src="/assets/vendor/glightbox/js/glightbox.min.js"></script>
  <script src="/assets/vendor/isotope-layout/isotope.pkgd.min.js"></script>
  <script src="/assets/vendor/swiper/swiper-bundle.min.js"></script>
  <script src="/assets/vendor/waypoints/noframework.waypoints.js"></script>
  <script src="/assets/vendor/php-email-form/validate.js"></script>

  <!-- Template Main JS File -->
  <script src="/assets/js/main.js"></script>

</body>

</html>
