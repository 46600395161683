import { DirectorioComponent } from './../directorio/directorio.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardComponent } from './dashboard.component';
import { CommonModule } from '@angular/common';
import { BlogSingleComponent } from '../articulo8/blog-single.component';
import { IndexComponent } from '../index/index.component';
import { InAComponent } from '../articulo8/Fraccion1/in-a/in-a.component';
import { InBComponent } from '../articulo8/Fraccion1/in-b/in-b.component';
import { InCComponent } from '../articulo8/Fraccion1/in-c/in-c.component';
import { InDComponent } from '../articulo8/Fraccion1/in-d/in-d.component';
import { InEComponent } from '../articulo8/Fraccion1/in-e/in-e.component';
import { InFComponent } from '../articulo8/Fraccion1/in-f/in-f.component';
import { InGComponent } from '../articulo8/Fraccion1/in-g/in-g.component';
import { InHComponent } from '../articulo8/Fraccion1/in-h/in-h.component';
import { InIComponent } from '../articulo8/Fraccion1/in-i/in-i.component';
import { InJComponent } from '../articulo8/Fraccion1/in-j/in-j.component';
import { InKComponent } from '../articulo8/Fraccion1/in-k/in-k.component';
import { InLComponent } from '../articulo8/Fraccion1/in-l/in-l.component';
import { InMComponent } from '../articulo8/Fraccion1/in-m/in-m.component';
import { InNComponent } from '../articulo8/Fraccion1/in-n/in-n.component';
import { InN1Component } from '../articulo8/Fraccion1/in-n1/in-n1.component';
import { In2AComponent } from '../articulo8/Fraccion2/in2-a/in2-a.component';
import { In2BComponent } from '../articulo8/Fraccion2/in2-b/in2-b.component';
import { In2CComponent } from '../articulo8/Fraccion2/in2-c/in2-c.component';
import { In2DComponent } from '../articulo8/Fraccion2/in2-d/in2-d.component';
import { In2EComponent } from '../articulo8/Fraccion2/in2-e/in2-e.component';
import { In3AComponent } from '../articulo8/Fraccion3/in3-a/in3-a.component';
import { In3BComponent } from '../articulo8/Fraccion3/in3-b/in3-b.component';
import { In3CComponent } from '../articulo8/Fraccion3/in3-c/in3-c.component';
import { In3DComponent } from '../articulo8/Fraccion3/in3-d/in3-d.component';
import { In3EComponent } from '../articulo8/Fraccion3/in3-e/in3-e.component';
import { In3FComponent } from '../articulo8/Fraccion3/in3-f/in3-f.component';
import { In3GComponent } from '../articulo8/Fraccion3/in3-g/in3-g.component';
import { In4AComponent } from '../articulo8/Fraccion4/in4-a/in4-a.component';
import { In4BComponent } from '../articulo8/Fraccion4/in4-b/in4-b.component';
import { In4CComponent } from '../articulo8/Fraccion4/in4-c/in4-c.component';
import { In4DComponent } from '../articulo8/Fraccion4/in4-d/in4-d.component';
import { In4EComponent } from '../articulo8/Fraccion4/in4-e/in4-e.component';
import { In4FComponent } from '../articulo8/Fraccion4/in4-f/in4-f.component';
import { In4GComponent } from '../articulo8/Fraccion4/in4-g/in4-g.component';
import { In4HComponent } from '../articulo8/Fraccion4/in4-h/in4-h.component';
import { In4IComponent } from '../articulo8/Fraccion4/in4-i/in4-i.component';
import { In5AComponent } from '../articulo8/Fraccion5/in5-a/in5-a.component';
import { In5BComponent } from '../articulo8/Fraccion5/in5-b/in5-b.component';
import { In5CComponent } from '../articulo8/Fraccion5/in5-c/in5-c.component';
import { In5DComponent } from '../articulo8/Fraccion5/in5-d/in5-d.component';
import { In5EComponent } from '../articulo8/Fraccion5/in5-e/in5-e.component';
import { In5FComponent } from '../articulo8/Fraccion5/in5-f/in5-f.component';
import { In5GComponent } from '../articulo8/Fraccion5/in5-g/in5-g.component';
import { In5HComponent } from '../articulo8/Fraccion5/in5-h/in5-h.component';
import { In5IComponent } from '../articulo8/Fraccion5/in5-i/in5-i.component';
import { In5JComponent } from '../articulo8/Fraccion5/in5-j/in5-j.component';
import { In5KComponent } from '../articulo8/Fraccion5/in5-k/in5-k.component';
import { In5LComponent } from '../articulo8/Fraccion5/in5-l/in5-l.component';
import { In5MComponent } from '../articulo8/Fraccion5/in5-m/in5-m.component';
import { In5NComponent } from '../articulo8/Fraccion5/in5-n/in5-n.component';
import { In5N1Component } from '../articulo8/Fraccion5/in5-n1/in5-n1.component';
import { In5OComponent } from '../articulo8/Fraccion5/in5-o/in5-o.component';
import { In5PComponent } from '../articulo8/Fraccion5/in5-p/in5-p.component';
import { In5QComponent } from '../articulo8/Fraccion5/in5-q/in5-q.component';
import { In5RComponent } from '../articulo8/Fraccion5/in5-r/in5-r.component';
import { In5SComponent } from '../articulo8/Fraccion5/in5-s/in5-s.component';
import { In5TComponent } from '../articulo8/Fraccion5/in5-t/in5-t.component';
import { In5UComponent } from '../articulo8/Fraccion5/in5-u/in5-u.component';
import { In5VComponent } from '../articulo8/Fraccion5/in5-v/in5-v.component';
import { In5WComponent } from '../articulo8/Fraccion5/in5-w/in5-w.component';
import { In5XComponent } from '../articulo8/Fraccion5/in5-x/in5-x.component';
import { In5YComponent } from '../articulo8/Fraccion5/in5-y/in5-y.component';
import { In5ZComponent } from '../articulo8/Fraccion5/in5-z/in5-z.component';
import { In6AComponent } from '../articulo8/Fraccion6/in6-a/in6-a.component';
import { In6BComponent } from '../articulo8/Fraccion6/in6-b/in6-b.component';
import { In6CComponent } from '../articulo8/Fraccion6/in6-c/in6-c.component';
import { In6DComponent } from '../articulo8/Fraccion6/in6-d/in6-d.component';
import { In6EComponent } from '../articulo8/Fraccion6/in6-e/in6-e.component';
import { In6FComponent } from '../articulo8/Fraccion6/in6-f/in6-f.component';
import { In6GComponent } from '../articulo8/Fraccion6/in6-g/in6-g.component';
import { In6HComponent } from '../articulo8/Fraccion6/in6-h/in6-h.component';
import { In6IComponent } from '../articulo8/Fraccion6/in6-i/in6-i.component';
import { In6JComponent } from '../articulo8/Fraccion6/in6-j/in6-j.component';
import { In6KComponent } from '../articulo8/Fraccion6/in6-k/in6-k.component';
import { In6LComponent } from '../articulo8/Fraccion6/in6-l/in6-l.component';
import { In6MComponent } from '../articulo8/Fraccion6/in6-m/in6-m.component';
import { In6NComponent } from '../articulo8/Fraccion6/in6-n/in6-n.component';
import { Fra7Component } from '../articulo8/Fraccion7/fra7/fra7.component';
import { Fra8Component } from '../articulo8/Fraccion8/fra8/fra8.component';
import { Fra9Component } from '../articulo8/Fraccion9/fra9/fra9.component';
import { Fra10Component } from '../articulo8/Fraccion10/fra10/fra10.component';
import { Fra11Component } from '../articulo8/Fraccion11/fra11/fra11.component';
import { Fra12Component } from '../articulo8/Fraccion12/fra12/fra12.component';
import { Fra13Component } from '../articulo8/Fraccion13/fra13/fra13.component';
import { Fra14Component } from '../articulo8/Fraccion14/fra14/fra14.component';
import { LoginComponent } from '../LOGIN/login/login.component';

import { DocumentoComponent } from '../documento/documento.component';
import { GobiernoComponent } from '../gobierno/gobierno.component';
import { IMujerComponent } from '../i-mujer/i-mujer.component';
import { InfoFundamentalComponent } from '../info-fundamental/info-fundamental.component';
import { SesionesComponent } from '../sesiones/sesiones.component';
import { TramitesYServiciosComponent } from '../tramites-y-servicios/tramites-y-servicios.component';
import { EnlacesComponent } from '../enlaces/enlaces.component';
import { SubirNoticiaComponent } from '../subir-noticia/subir-noticia.component';
import { VerNoticiaComponent } from '../ver-noticia/ver-noticia.component';
import { SubirImgInicioComponent } from '../subir-img-inicio/subir-img-inicio.component';
import { PrivacyComponent } from '../privacy/privacy.component';






const routes: Routes = [
  /*{
    path: '',
    component: DashboardComponent,
  },*/
  {
    path:'inicio',
    component: IndexComponent
  },
  {
    path:'articulo-8',
    component: BlogSingleComponent
  },
  {
    path:'articulo-8/fraccion-1/in-a',
    component: InAComponent
  },
  {
    path:'articulo-8/fraccion-1/in-b',
    component: InBComponent
  },
  {
    path:'articulo-8/fraccion-1/in-c',
    component: InCComponent
  },
  {
    path:'articulo-8/fraccion-1/in-d',
    component: InDComponent
  },
  {
    path:'articulo-8/fraccion-1/in-e',
    component: InEComponent
  },
  {
    path:'articulo-8/fraccion-1/in-f',
    component: InFComponent
  },
  {
    path:'articulo-8/fraccion-1/in-g',
    component: InGComponent
  },
  {
    path:'articulo-8/fraccion-1/in-h',
    component: InHComponent
  },
  {
    path:'articulo-8/fraccion-1/in-i',
    component: InIComponent
  },
  {
    path:'articulo-8/fraccion-1/in-j',
    component: InJComponent
  },
  {
    path:'articulo-8/fraccion-1/in-k',
    component: InKComponent
  },
  {
    path:'articulo-8/fraccion-1/in-l',
    component: InLComponent
  },
  {
    path:'articulo-8/fraccion-1/in-m',
    component: InMComponent
  },
  {
    path:'articulo-8/fraccion-1/in-n',
    component: InNComponent
  },
  {
    path:'articulo-8/fraccion-1/in-n1',
    component: InN1Component
  },
  {
    path:'articulo-8/fraccion-2/in-a',
    component: In2AComponent
  },
  {
    path:'articulo-8/fraccion-2/in-b',
    component: In2BComponent
  },
  {
    path:'articulo-8/fraccion-2/in-c',
    component: In2CComponent
  },
  {
    path:'articulo-8/fraccion-2/in-d',
    component: In2DComponent
  },
  {
    path:'articulo-8/fraccion-2/in-e',
    component: In2EComponent
  },
  {
    path:'articulo-8/fraccion-3/in-a',
    component: In3AComponent
  },
  {
    path:'articulo-8/fraccion-3/in-b',
    component: In3BComponent
  },
  {
    path:'articulo-8/fraccion-3/in-c',
    component: In3CComponent
  },
  {
    path:'articulo-8/fraccion-3/in-d',
    component: In3DComponent
  },
  {
    path:'articulo-8/fraccion-3/in-e',
    component: In3EComponent
  },
  {
    path:'articulo-8/fraccion-3/in-f',
    component: In3FComponent
  },
  {
    path:'articulo-8/fraccion-3/in-g',
    component: In3GComponent
  },
  {
    path:'articulo-8/fraccion-4/in-a',
    component: In4AComponent
  },
  {
    path:'articulo-8/fraccion-4/in-b',
    component: In4BComponent
  },
  {
    path:'articulo-8/fraccion-4/in-c',
    component: In4CComponent
  },
  {
    path:'articulo-8/fraccion-4/in-d',
    component: In4DComponent
  },
  {
    path:'articulo-8/fraccion-4/in-e',
    component: In4EComponent
  },
  {
    path:'articulo-8/fraccion-4/in-f',
    component: In4FComponent
  },
  {
    path:'articulo-8/fraccion-4/in-g',
    component: In4GComponent
  },
  {
    path:'articulo-8/fraccion-4/in-h',
    component: In4HComponent
  },
  {
    path:'articulo-8/fraccion-4/in-i',
    component: In4IComponent
  },
  {
    path:'articulo-8/fraccion-5/in-a',
    component: In5AComponent
  },
  {
    path:'articulo-8/fraccion-5/in-b',
    component: In5BComponent
  },
  {
    path:'articulo-8/fraccion-5/in-c',
    component: In5CComponent
  },
  {
    path:'articulo-8/fraccion-5/in-d',
    component: In5DComponent
  },
  {
    path:'articulo-8/fraccion-5/in-e',
    component: In5EComponent
  },
  {
    path:'articulo-8/fraccion-5/in-f',
    component: In5FComponent
  },
  {
    path:'articulo-8/fraccion-5/in-g',
    component: In5GComponent
  },
  {
    path:'articulo-8/fraccion-5/in-h',
    component: In5HComponent
  },
  {
    path:'articulo-8/fraccion-5/in-i',
    component: In5IComponent
  },
  {
    path:'articulo-8/fraccion-5/in-j',
    component: In5JComponent
  },
  {
    path:'articulo-8/fraccion-5/in-k',
    component: In5KComponent
  },
  {
    path:'articulo-8/fraccion-5/in-l',
    component: In5LComponent
  },
  {
    path:'articulo-8/fraccion-5/in-m',
    component: In5MComponent
  },
  {
    path:'articulo-8/fraccion-5/in-n',
    component: In5NComponent
  },
  {
    path:'articulo-8/fraccion-5/in-n1',
    component: In5N1Component
  },
  {
    path:'articulo-8/fraccion-5/in-o',
    component: In5OComponent
  },
  {
    path:'articulo-8/fraccion-5/in-p',
    component: In5PComponent
  },
  {
    path:'articulo-8/fraccion-5/in-q',
    component: In5QComponent
  },
  {
    path:'articulo-8/fraccion-5/in-r',
    component: In5RComponent
  },
  {
    path:'articulo-8/fraccion-5/in-s',
    component: In5SComponent
  },
  {
    path:'articulo-8/fraccion-5/in-t',
    component: In5TComponent
  },
  {
    path:'articulo-8/fraccion-5/in-u',
    component: In5UComponent
  },
  {
    path:'articulo-8/fraccion-5/in-v',
    component: In5VComponent
  },
  {
    path:'articulo-8/fraccion-5/in-w',
    component: In5WComponent
  },
  {
    path:'articulo-8/fraccion-5/in-x',
    component: In5XComponent
  },
  {
    path:'articulo-8/fraccion-5/in-y',
    component: In5YComponent
  },
  {
    path:'articulo-8/fraccion-5/in-z',
    component: In5ZComponent
  },
  {
    path:'articulo-8/fraccion-6/in-a',
    component: In6AComponent
  },
  {
    path:'articulo-8/fraccion-6/in-b',
    component: In6BComponent
  },
  {
    path:'articulo-8/fraccion-6/in-c',
    component: In6CComponent
  },
  {
    path:'articulo-8/fraccion-6/in-d',
    component: In6DComponent
  },
  {
    path:'articulo-8/fraccion-6/in-e',
    component: In6EComponent
  },
  {
    path:'articulo-8/fraccion-6/in-f',
    component: In6FComponent
  },
  {
    path:'articulo-8/fraccion-6/in-g',
    component: In6GComponent
  },
  {
    path:'articulo-8/fraccion-6/in-h',
    component: In6HComponent
  },
  {
    path:'articulo-8/fraccion-6/in-i',
    component: In6IComponent
  },
  {
    path:'articulo-8/fraccion-6/in-j',
    component: In6JComponent
  },
  {
    path:'articulo-8/fraccion-6/in-k',
    component: In6KComponent
  },
  {
    path:'articulo-8/fraccion-6/in-l',
    component: In6LComponent
  },
  {
    path:'articulo-8/fraccion-6/in-m',
    component: In6MComponent
  },
  {
    path:'articulo-8/fraccion-6/in-n',
    component: In6NComponent
  },
  {
    path:'articulo-8/fraccion-7',
    component: Fra7Component
  },
  {
    path:'articulo-8/fraccion-8',
    component: Fra8Component
  },
  {
    path:'articulo-8/fraccion-9',
    component: Fra9Component
  },
  {
    path:'articulo-8/fraccion-10',
    component: Fra10Component
  },
  {
    path:'articulo-8/fraccion-11',
    component: Fra11Component
  },
  {
    path:'articulo-8/fraccion-12',
    component: Fra12Component
  },
  {
    path:'articulo-8/fraccion-13',
    component: Fra13Component
  },
  {
    path:'articulo-8/fraccion-14',
    component: Fra14Component
  },
  {
    path:'articulo-8/fraccion-1/in-a',
    component: InAComponent
  },
  {

    path:'login',
    component: LoginComponent
  },
  {
    path:'documento',
    component: DocumentoComponent
  },
  {
    path:'gobierno',
    component: GobiernoComponent
  },
  {
    path:'info-fundamental',
    component: InfoFundamentalComponent
  },
  {
    path:'sesiones',
    component: SesionesComponent
  },
  {
    path:'tramites-y-servicios',
    component: TramitesYServiciosComponent
  },
  {
    path:'nominas',
    component: TramitesYServiciosComponent
  },
  {
    path:'directorio',
    component: DirectorioComponent
  },
  {
    path:'subir-enlace',
    component: EnlacesComponent
  },
  {
    path:'subir-noticia',
    component: SubirNoticiaComponent
  },
  {
    path:'ver-noticia/:id',
    component: VerNoticiaComponent
  },
  {
    path:'editar-banner',
    component: SubirImgInicioComponent
  },
  {
    path:'avisos-de-privacidad',
    component: PrivacyComponent
  }
];

@NgModule({
  imports: [CommonModule,RouterModule, RouterModule.forChild(routes)],
  exports: [RouterModule],
})

export class DashboardRoutingModule {}
