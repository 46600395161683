<!DOCTYPE html>
<html lang="en">

<head>
  <meta charset="utf-8">
  <meta content="width=device-width, initial-scale=1.0" name="viewport">

  <title>Autlan - Gobierno</title>
  <meta content="" name="description">
  <meta content="" name="keywords">

  <!-- Favicons -->
  <link href="/assets/img/favicon.png" rel="icon">
  <link href="/assets/img/apple-touch-icon.png" rel="apple-touch-icon">

  <!-- Google Fonts -->
  <link
    href="https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i|Roboto:300,300i,400,400i,500,500i,600,600i,700,700i|Poppins:300,300i,400,400i,500,500i,600,600i,700,700i"
    rel="stylesheet">

  <!-- Vendor CSS Files -->
  <link href="/assets/vendor/animate.css/animate.min.css" rel="stylesheet">
  <link href="/assets/vendor/aos/aos.css" rel="stylesheet">
  <link href="/assets/vendor/bootstrap/css/bootstrap.min.css" rel="stylesheet">
  <link href="/assets/vendor/bootstrap-icons/bootstrap-icons.css" rel="stylesheet">
  <link href="/assets/vendor/boxicons/css/boxicons.min.css" rel="stylesheet">
  <link href="/assets/vendor/glightbox/css/glightbox.min.css" rel="stylesheet">
  <link href="/assets/vendor/remixicon/remixicon.css" rel="stylesheet">
  <link href="/assets/vendor/swiper/swiper-bundle.min.css" rel="stylesheet">

  <!-- Template Main CSS File -->
  <link href="/assets/css/style.css" rel="stylesheet">

  <!-- =======================================================
  * Template Name: Company - v4.7.0
  * Template URL: https://bootstrapmade.com/company-free-html-bootstrap-template/
  * Author: BootstrapMade.com
  * License: https://bootstrapmade.com/license/
  ======================================================== -->
</head>

<body>







  <section id="hero" style="height: 600px; margin-top: 150px; width: 80%; margin-left: 10%; margin-right: 10%; border-radius: 6vh;">


    <div id="heroCarousel" data-bs-interval="2000" class="carousel slide carousel-fade" data-bs-ride="carousel">

      <div class="carousel-inner" role="listbox">
        <!-- Slide 1 -->
        <div class="carousel-item " style="background-image: url({{this.bann[0].URL}}); " >
          <div class="carousel-container" *ngIf="boton === 1">
            <div class="text-center"><button style="border-radius: 1vh; width: 180px; "
              (click)="editar()" >Editar Banner</button></div>
        </div>
        </div>

        <!-- Slide 2 -->
        <div class="carousel-item " style="background-image: url({{this.bann[2].URL}}); ">
          <div class="carousel-container" *ngIf="boton === 1">
            <div class="text-center"><button style="border-radius: 1vh; width: 180px; "
              (click)="editar()" >Editar Banner</button></div>
        </div>
        </div>

        <!-- Slide 3 -->
        <div class="carousel-item active" style="background-image: url({{this.bann[1].URL}}); ">
          <div class="carousel-container" *ngIf="boton === 1">
            <div class="text-center"><button style="border-radius: 1vh; width: 180px;"
            (click)="editar()" >Editar Banner</button></div>
        </div>
        </div>

      </div>

      <!-- <a class="carousel-control-prev" href="#heroCarousel" role="button" data-bs-slide="prev">
        <span class="carousel-control-prev-icon bi bi-chevron-left" aria-hidden="true"></span>
      </a>

      <a class="carousel-control-next" href="#heroCarouse2" role="button" data-bs-slide="next">
        <span class="carousel-control-next-icon bi bi-chevron-right" aria-hidden="true"></span>
      </a>

      <ol class="carousel-indicators" id="hero-carousel-indicators"></ol> -->

    </div>








  </section><!-- End Hero -->

  <main id="main">

    <section id="testimonials" class="testimonials section-bg">
        <div style="margin-top: -2vh;" class="astrodivider" data-aos="fade-up">
    <div class="astrodividermask" data-aos="fade-up"></div><span
      style=" background-image: url(https://i.imgur.com/Ec4hd0u.png); justify-items: start;"></span>
  </div>
      <div class="container">

        <div class="row">
          <div class="section-title" data-aos="fade-up">
            <div class="row" style="background-color: rgb(255, 255, 255); border-radius: 2vh;">

              <div class="col-lg-12" data-aos="fade-up">
                <iframe
                  src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2FSistemaDIFAutlan&tabs=timeline&width=2000&height=500&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId"
                  width="560" height="315" style="border:none;overflow:hidden" scrolling="no" frameborder="0"
                  allowfullscreen="true"
                  allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
              </div>

            </div>


          </div>





        </div>

      </div>
    </section><!-- End Testimonials Section -->





<section id="team" class="team section-bg">

  <div style="margin-top: -2vh;" class="astrodivider" data-aos="fade-up">
    <div class="astrodividermask" data-aos="fade-up"></div><span
      style=" background-image: url(https://i.imgur.com/Ec4hd0u.png); justify-items: start;"></span>
  </div>
<div class="row" style="text-align: center;margin-left: -40vh;">
<div class="col-2"></div>

<div class="col-10">
<div class="scroll" style="width: 100%;">

  <img src="https://i.imgur.com/NvVykvV.jpg" alt="">
</div>


</div>

<div class="col-2"></div>
</div>
</section>








    <!-- ======= Our Team Section ======= -->
    <section id="team" class="team section-bg">
      <div style="margin-top: -2vh;" class="astrodivider" data-aos="fade-up">
        <div class="astrodividermask" data-aos="fade-up"></div><span
          style=" background-image: url(https://i.imgur.com/Ec4hd0u.png); justify-items: start;"></span>
      </div>
      <div class="container">

        <div class="section-title" data-aos="fade-up">
          <h2>Últimas <strong>Noticias</strong></h2>
          <!-- <p>Magnam dolores commodi suscipit. Necessitatibus eius consequatur ex aliquid fuga eum quidem. Sit sint conse
            ctetur velit. Quisquam quos quisquam cupiditate. Et nemo qui impedit sus
            cipit alias ea. Quia fugiat sit in iste officiis commodi quidem hic quas.</p> -->
        </div>

        <div class="row">
          <div class="col-lg-3 col-md-6 d-flex align-items-stretch" *ngFor="let noticia of data">
            <div class="member" data-aos="fade-up" data-aos-delay="100" style="border-radius: 3vh;">
              <div class="member-img">
                <img [src]="noticia.ENLACE" height="300" width="300" class="img-fluid" alt="">

              </div>
              <div class="member-info">
                <a href="admin/ver-noticia/{{noticia.ID}}"
                  style="font-size: 2vh; text-align: justify;">{{noticia.ENCABEZADO}}</a>
                <span> {{noticia.FECHA}}</span>
              </div>
            </div>
          </div>

        </div>

      </div>
    </section><!-- End Our Team Section -->









  </main><!-- End #main -->

  <a href="#" class="back-to-top d-flex align-items-center justify-content-center"><i
      class="bi bi-arrow-up-short"></i></a>

  <!-- Vendor JS Files -->
  <script src="/assets/vendor/aos/aos.js"></script>
  <script src="/assets/vendor/bootstrap/js/bootstrap.bundle.min.js"></script>
  <script src="/assets/vendor/glightbox/js/glightbox.min.js"></script>
  <script src="/assets/vendor/isotope-layout/isotope.pkgd.min.js"></script>
  <script src="/assets/vendor/swiper/swiper-bundle.min.js"></script>
  <script src="/assets/vendor/waypoints/noframework.waypoints.js"></script>
  <script src="/assets/vendor/php-email-form/validate.js"></script>

  <!-- Template Main JS File -->
  <script src="/assets/js/main.js"></script>

</body>

</html>
