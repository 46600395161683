<!DOCTYPE html>
<html lang="en">

<head>
  <meta charset="utf-8">
  <meta content="width=device-width, initial-scale=1.0" name="viewport">

  <meta content="" name="description">
  <meta content="" name="keywords">

  <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.1.1/animate.min.css" />

  <!-- Favicons -->
  <link href="/assets/img/favicon.png" rel="icon">
  <link href="/assets/img/apple-touch-icon.png" rel="apple-touch-icon">

  <!-- Google Fonts -->
  <link
    href="https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i|Roboto:300,300i,400,400i,500,500i,600,600i,700,700i|Poppins:300,300i,400,400i,500,500i,600,600i,700,700i"
    rel="stylesheet">

  <!-- Vendor CSS Files -->
  <link href="/assets/vendor/animate.css/animate.min.css" rel="stylesheet">
  <link href="/assets/vendor/aos/aos.css" rel="stylesheet">
  <link href="/assets/vendor/bootstrap/css/bootstrap.min.css" rel="stylesheet">
  <link href="/assets/vendor/bootstrap-icons/bootstrap-icons.css" rel="stylesheet">
  <link href="/assets/vendor/boxicons/css/boxicons.min.css" rel="stylesheet">
  <link href="/assets/vendor/glightbox/css/glightbox.min.css" rel="stylesheet">
  <link href="/assets/vendor/remixicon/remixicon.css" rel="stylesheet">
  <link href="/assets/vendor/swiper/swiper-bundle.min.css" rel="stylesheet">

  <!-- Template Main CSS File -->
  <link href="/assets/css/style.css" rel="stylesheet">

  <!-- =======================================================
  * Template Name: Company - v4.7.0
  * Template URL: https://bootstrapmade.com/company-free-html-bootstrap-template/
  * Author: BootstrapMade.com
  * License: https://bootstrapmade.com/license/
  ======================================================== -->
</head>

<body>



  <!--Main layout-->
  <main style="margin-top: 40px;">
    <div class="container pt-4"></div>
  </main>
  <!--Main layout-->
  <main id="main">

    <section style="margin-top: 40px;">
      <app-menu-compartido></app-menu-compartido>
      <div class="section-title" data-aos="fade-up">
        <h4 style="text-align: left; margin-left: 140px;">La agenda diaria de actividades del sujeto obligado, de cuando
          menos el último mes</h4>
      </div>
    </section>

    <!-- ======= Breadcrumbs ======= -->
    <section id="breadcrumbs" class="breadcrumbs">
      <div class="container">

        <div class="d-flex justify-content-between align-items-center">
          <ol>
            <li>Inicio</li>
            <li><a href="admin/articulo-8">Articulo 8</a></li>
            <li>Fracción VI</li>
            <li>Inciso H</li>
          </ol>
        </div>

      </div>
    </section><!-- End Breadcrumbs -->


    <section id="blog" class="blog">
      <!-- ======= Inicio Seccion Carpetas por año ======= -->
      <div class="fondo2">
        <div id="scroll">

          <div class="panel-group" id="accordion1">
            <!-- ======= Inicio  areas======= -->


            <div class="panel panel-default">
              <div class="panel-heading">
                <h4 class="panel-title">
                  <button (click)="area1()" data-bs-toggle="collapse" data-bs-parent="#accordion1" href="#a1"
                    style="background: none; border: none;">
                    <i class="bi bi-folder-fill">Centro de atención especializada en terapia familiar</i></button>
                </h4>
              </div>
              <div id="a1" class="panel-collapse collapse in" data-bs-parent="#accordion1">
                <div class="panel-group" id="accordion100" style=" margin-left: 20px;">

                  <div class="panel panel-default">
                    <div class="panel-heading">
                      <h4 class="panel-title">
                        <button (click)="a82018()" data-bs-toggle="collapse" data-bs-parent="#accordion100"
                          href="#collapse100" style="background: none; border: none;">
                          <i class="bi bi-folder-fill">2018</i></button>
                      </h4>
                    </div>
                    <div id="collapse100" class="panel-collapse collapse in" data-bs-parent="#accordion100">
                      <div class="panel-body">
                        <div style="margin: auto; width: 80%; height: 70px; display: flex; justify-content: right;"
                          *ngIf="boton === 1">
                          <button style="height: 38px;" (click)="seleccionarTodo18()" type="button"
                            class="btn btn-outline-dark">Seleccionar Todo</button>
                          <button style="height: 38px;  margin-left: 10px;" (click)="quitarSeleccion18()" type="button"
                            class="btn btn-outline-dark">Desahacer Selección</button>
                          <button style="height: 38px; margin-left: 10px;" (click)="eliminarSeleccionados()"
                            type="button" class="btn btn-outline-dark">Eliminar Seleccionados</button>
                        </div>
                        <div class="fondo">

                          <div id="scroll">
                            <table class="table table-striped">
                              <thead>
                                <tr>
                                  <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre
                                    del
                                    documento</th>
                                  <th scope="col"></th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr *ngFor="let dato of docs18, let i = index">
                                  <td><input type="checkbox" (change)="checkCheckBoxvalue18($event, i, dato?.ID)"
                                      *ngIf="boton === 1" [checked]="todo18">
                                    <img src="assets/img/docs.png" alt="" height="30" width="30">{{dato?.NOMBRE}}
                                    <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;"> - Abrir</a>
                                  </td>

                                  <td>
                                    <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                      class="btn btn-outline-dark">Eliminar</button>
                                  </td>

                                </tr>
                              </tbody>
                            </table>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>


                  <div class="panel panel-default">
                    <div class="panel-heading">
                      <h4 class="panel-title">
                        <button (click)="a82019()" data-bs-toggle="collapse" data-bs-parent="#accordion100"
                          href="#collapse200" style="background: none; border: none;">
                          <i class="bi bi-folder-fill">2019</i></button>
                      </h4>
                    </div>
                    <div id="collapse200" class="panel-collapse collapse in" data-bs-parent="#accordion100">
                      <div class="panel-body">
                        <div style="margin: auto; width: 80%; height: 70px; display: flex; justify-content: right;"
                          *ngIf="boton === 1">
                          <button style="height: 38px;" (click)="seleccionarTodo19()" type="button"
                            class="btn btn-outline-dark">Seleccionar Todo</button>
                          <button style="height: 38px;  margin-left: 10px;" (click)="quitarSeleccion19()" type="button"
                            class="btn btn-outline-dark">Desahacer Selección</button>
                          <button style="height: 38px; margin-left: 10px;" (click)="eliminarSeleccionados()"
                            type="button" class="btn btn-outline-dark">Eliminar Seleccionados</button>
                        </div>
                        <div class="fondo">

                          <div id="scroll">
                            <table class="table table-striped">
                              <thead>
                                <tr>
                                  <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre
                                    del
                                    documento</th>
                                  <th scope="col"></th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr *ngFor="let dato of docs19, let i = index">
                                  <td><input type="checkbox" (change)="checkCheckBoxvalue19($event, i, dato?.ID)"
                                      *ngIf="boton === 1" [checked]="todo19">
                                    <img src="assets/img/docs.png" alt="" height="30" width="30">{{dato?.NOMBRE}}
                                    <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;"> - Abrir</a>
                                  </td>
                                  <td>
                                    <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                      class="btn btn-outline-dark">Eliminar</button>
                                  </td>

                                </tr>

                              </tbody>
                            </table>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>


                  <div class="panel panel-default">
                    <div class="panel-heading">
                      <h4 class="panel-title">
                        <button (click)="a82020()" data-bs-toggle="collapse" data-bs-parent="#accordion100"
                          href="#collapse300" style="background: none; border: none;">
                          <i class="bi bi-folder-fill">2020</i></button>
                      </h4>
                    </div>
                    <div id="collapse300" class="panel-collapse collapse in" data-bs-parent="#accordion100">
                      <div class="panel-body">
                        <div style="margin: auto; width: 80%; height: 70px; display: flex; justify-content: right;"
                          *ngIf="boton === 1">
                          <button style="height: 38px;" (click)="seleccionarTodo20()" type="button"
                            class="btn btn-outline-dark">Seleccionar Todo</button>
                          <button style="height: 38px;  margin-left: 10px;" (click)="quitarSeleccion20()" type="button"
                            class="btn btn-outline-dark">Desahacer Selección</button>
                          <button style="height: 38px; margin-left: 10px;" (click)="eliminarSeleccionados()"
                            type="button" class="btn btn-outline-dark">Eliminar Seleccionados</button>
                        </div>
                        <div class="fondo">

                          <div id="scroll">
                            <table class="table table-striped">
                              <thead>
                                <tr>
                                  <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre
                                    del
                                    documento</th>
                                  <th scope="col"></th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr *ngFor="let dato of docs20, let i = index">
                                  <td><input type="checkbox" (change)="checkCheckBoxvalue20($event, i, dato?.ID)"
                                      *ngIf="boton === 1" [checked]="todo20">
                                    <img src="assets/img/docs.png" alt="" height="30" width="30">{{dato?.NOMBRE}}
                                    <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;"> - Abrir</a>
                                  </td>
                                  <td>
                                    <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                      class="btn btn-outline-dark">Eliminar</button>
                                  </td>

                                </tr>
                              </tbody>
                            </table>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>


                  <div class="panel panel-default">
                    <div class="panel-heading">
                      <h4 class="panel-title">
                        <button (click)="a82021()" data-bs-toggle="collapse" data-bs-parent="#accordion100"
                          href="#collapse400" style="background: none; border: none;">
                          <i class="bi bi-folder-fill">2021</i></button>
                      </h4>
                    </div>
                    <div id="collapse400" class="panel-collapse collapse in" data-bs-parent="#accordion100">
                      <div class="panel-body">
                        <div style="margin: auto; width: 80%; height: 70px; display: flex; justify-content: right;"
                          *ngIf="boton === 1">
                          <button style="height: 38px;" (click)="seleccionarTodo21()" type="button"
                            class="btn btn-outline-dark">Seleccionar Todo</button>
                          <button style="height: 38px;  margin-left: 10px;" (click)="quitarSeleccion21()" type="button"
                            class="btn btn-outline-dark">Desahacer Selección</button>
                          <button style="height: 38px; margin-left: 10px;" (click)="eliminarSeleccionados()"
                            type="button" class="btn btn-outline-dark">Eliminar Seleccionados</button>
                        </div>
                        <div class="fondo">

                          <div id="scroll">
                            <table class="table table-striped">
                              <thead>
                                <tr>
                                  <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre
                                    del
                                    documento</th>
                                  <th scope="col"></th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr *ngFor="let dato of docs21, let i = index">
                                  <td><input type="checkbox" (change)="checkCheckBoxvalue21($event, i, dato?.ID)"
                                      *ngIf="boton === 1" [checked]="todo21">
                                    <img src="assets/img/docs.png" alt="" height="30" width="30">{{dato?.NOMBRE}}
                                    <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;"> - Abrir</a>
                                  </td>
                                  <td>
                                    <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                      class="btn btn-outline-dark">Eliminar</button>
                                  </td>

                                </tr>
                              </tbody>
                            </table>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>


                  <div class="panel panel-default">
                    <div class="panel-heading">
                      <h4 class="panel-title">
                        <button (click)="a82022()" data-bs-toggle="collapse" data-bs-parent="#accordion100"
                          href="#collapse500" style="background: none; border: none;">
                          <i class="bi bi-folder-fill">2022</i></button>
                      </h4>
                    </div>
                    <div id="collapse500" class="panel-collapse collapse in" data-bs-parent="#accordion100">
                      <div class="panel-body">
                        <div style="margin: auto; width: 80%; height: 70px; display: flex; justify-content: right;"
                          *ngIf="boton === 1">
                          <button style="height: 38px;" (click)="seleccionarTodo22()" type="button"
                            class="btn btn-outline-dark">Seleccionar Todo</button>
                          <button style="height: 38px;  margin-left: 10px;" (click)="quitarSeleccion22()" type="button"
                            class="btn btn-outline-dark">Desahacer Selección</button>
                          <button style="height: 38px; margin-left: 10px;" (click)="eliminarSeleccionados()"
                            type="button" class="btn btn-outline-dark">Eliminar Seleccionados</button>
                        </div>
                        <div class="fondo">

                          <div id="scroll">
                            <table class="table table-striped">
                              <thead>
                                <tr>
                                  <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre
                                    del
                                    documento</th>
                                  <th scope="col"></th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr *ngFor="let dato of docs22, let i = index">
                                  <td><input type="checkbox" (change)="checkCheckBoxvalue22($event, i, dato?.ID)"
                                      *ngIf="boton === 1" [checked]="todo22">
                                    <img src="assets/img/docs.png" alt="" height="30" width="30">{{dato?.NOMBRE}}
                                    <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;"> - Abrir</a>
                                  </td>
                                  <td>
                                    <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                      class="btn btn-outline-dark">Eliminar</button>
                                  </td>

                                </tr>
                              </tbody>
                            </table>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="panel panel-default">
                    <div class="panel-heading">
                      <h4 class="panel-title">
                        <button (click)="a82023()" data-bs-toggle="collapse" data-bs-parent="#accordion100"
                          href="#collapse2123" style="background: none; border: none;">
                          <i class="bi bi-folder-fill">2023</i></button>
                      </h4>
                    </div>
                    <div id="collapse2123" class="panel-collapse collapse in" data-bs-parent="#accordion100">
                      <div class="panel-body">
                        <div style="margin: auto; width: 80%; height: 70px; display: flex; justify-content: right;"
                          *ngIf="boton === 1">
                          <button style="height: 38px;" (click)="seleccionarTodo23()" type="button"
                            class="btn btn-outline-dark">Seleccionar Todo</button>
                          <button style="height: 38px;  margin-left: 10px;" (click)="quitarSeleccion23()" type="button"
                            class="btn btn-outline-dark">Desahacer Selección</button>
                          <button style="height: 38px; margin-left: 10px;" (click)="eliminarSeleccionados()"
                            type="button" class="btn btn-outline-dark">Eliminar Seleccionados</button>
                        </div>
                        <div class="fondo">

                          <div id="scroll">
                            <table class="table table-striped">
                              <thead>
                                <tr>
                                  <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre
                                    del
                                    documento</th>
                                  <th scope="col"></th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr *ngFor="let dato of docs23, let i = index">
                                  <td><input type="checkbox" (change)="checkCheckBoxvalue23($event, i, dato?.ID)"
                                      *ngIf="boton === 1" [checked]="todo23">
                                    <img src="assets/img/docs.png" alt="" height="30" width="30">{{dato?.NOMBRE}}
                                    <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;"> - Abrir</a>
                                  </td>
                                  <td>
                                    <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                      class="btn btn-outline-dark">Eliminar</button>
                                  </td>

                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

  <div class="panel panel-default">
                    <div class="panel-heading">
                      <h4 class="panel-title">
                        <button (click)="a82024()" data-bs-toggle="collapse" data-bs-parent="#accordion100"
                          href="#collapse2124" style="background: none; border: none;">
                          <i class="bi bi-folder-fill">2024</i></button>
                      </h4>
                    </div>
                    <div id="collapse2124" class="panel-collapse collapse in" data-bs-parent="#accordion100">
                      <div class="panel-body">
                        <div style="margin: auto; width: 80%; height: 70px; display: flex; justify-content: right;"
                          *ngIf="boton === 1">
                          <button style="height: 38px;" (click)="seleccionarTodo24()" type="button"
                            class="btn btn-outline-dark">Seleccionar Todo</button>
                          <button style="height: 38px;  margin-left: 10px;" (click)="quitarSeleccion24()" type="button"
                            class="btn btn-outline-dark">Desahacer Selección</button>
                          <button style="height: 38px; margin-left: 10px;" (click)="eliminarSeleccionados()"
                            type="button" class="btn btn-outline-dark">Eliminar Seleccionados</button>
                        </div>
                        <div class="fondo">

                          <div id="scroll">
                            <table class="table table-striped">
                              <thead>
                                <tr>
                                  <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre
                                    del
                                    documento</th>
                                  <th scope="col"></th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr *ngFor="let dato of docs24, let i = index">
                                  <td><input type="checkbox" (change)="checkCheckBoxvalue24($event, i, dato?.ID)"
                                      *ngIf="boton === 1" [checked]="todo24">
                                    <img src="assets/img/docs.png" alt="" height="30" width="30">{{dato?.NOMBRE}}
                                    <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;"> - Abrir</a>
                                  </td>
                                  <td>
                                    <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                      class="btn btn-outline-dark">Eliminar</button>
                                  </td>

                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>



                </div>
              </div>
            </div>

            <div class="panel panel-default">
              <div class="panel-heading">
                <h4 class="panel-title">
                  <button (click)="area2()" data-bs-toggle="collapse" data-bs-parent="#accordion1" href="#a2"
                    style="background: none; border: none;">
                    <i class="bi bi-folder-fill">Centro de convivencia y alimentación para adultos mayores y grupos
                      prioritarios</i></button>
                </h4>
              </div>
              <div id="a2" class="panel-collapse collapse in" data-bs-parent="#accordion1">
                <div class="panel-group" id="accordion2" style=" margin-left: 20px;">

                  <div class="panel panel-default">
                    <div class="panel-heading">
                      <h4 class="panel-title">
                        <button (click)="a82018()" data-bs-toggle="collapse" data-bs-parent="#accordion2"
                          href="#collapse600" style="background: none; border: none;">
                          <i class="bi bi-folder-fill">2018</i></button>
                      </h4>
                    </div>
                    <div id="collapse600" class="panel-collapse collapse in" data-bs-parent="#accordion2">
                      <div class="panel-body">
                        <div style="margin: auto; width: 80%; height: 70px; display: flex; justify-content: right;"
                          *ngIf="boton === 1">
                          <button style="height: 38px;" (click)="seleccionarTodo18()" type="button"
                            class="btn btn-outline-dark">Seleccionar Todo</button>
                          <button style="height: 38px;  margin-left: 10px;" (click)="quitarSeleccion18()" type="button"
                            class="btn btn-outline-dark">Desahacer Selección</button>
                          <button style="height: 38px; margin-left: 10px;" (click)="eliminarSeleccionados()"
                            type="button" class="btn btn-outline-dark">Eliminar Seleccionados</button>
                        </div>
                        <div class="fondo">

                          <div id="scroll">
                            <table class="table table-striped">
                              <thead>
                                <tr>
                                  <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre
                                    del
                                    documento</th>
                                  <th scope="col"></th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr *ngFor="let dato of docs18, let i = index">
                                  <td><input type="checkbox" (change)="checkCheckBoxvalue18($event, i, dato?.ID)"
                                      *ngIf="boton === 1" [checked]="todo18">
                                    <img src="assets/img/docs.png" alt="" height="30" width="30">{{dato?.NOMBRE}}
                                    <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;"> - Abrir</a>
                                  </td>

                                  <td>
                                    <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                      class="btn btn-outline-dark">Eliminar</button>
                                  </td>

                                </tr>
                              </tbody>
                            </table>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>



                  <div class="panel panel-default">
                    <div class="panel-heading">
                      <h4 class="panel-title">
                        <button (click)="a82019()" data-bs-toggle="collapse" data-bs-parent="#accordion2"
                          href="#collapse700" style="background: none; border: none;">
                          <i class="bi bi-folder-fill">2019</i></button>
                      </h4>
                    </div>
                    <div id="collapse700" class="panel-collapse collapse in" data-bs-parent="#accordion2">
                      <div class="panel-body">
                        <div style="margin: auto; width: 80%; height: 70px; display: flex; justify-content: right;"
                          *ngIf="boton === 1">
                          <button style="height: 38px;" (click)="seleccionarTodo19()" type="button"
                            class="btn btn-outline-dark">Seleccionar Todo</button>
                          <button style="height: 38px;  margin-left: 10px;" (click)="quitarSeleccion19()" type="button"
                            class="btn btn-outline-dark">Desahacer Selección</button>
                          <button style="height: 38px; margin-left: 10px;" (click)="eliminarSeleccionados()"
                            type="button" class="btn btn-outline-dark">Eliminar Seleccionados</button>
                        </div>
                        <div class="fondo">

                          <div id="scroll">
                            <table class="table table-striped">
                              <thead>
                                <tr>
                                  <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre
                                    del
                                    documento</th>
                                  <th scope="col"></th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr *ngFor="let dato of docs19, let i = index">
                                  <td><input type="checkbox" (change)="checkCheckBoxvalue19($event, i, dato?.ID)"
                                      *ngIf="boton === 1" [checked]="todo19">
                                    <img src="assets/img/docs.png" alt="" height="30" width="30">{{dato?.NOMBRE}}
                                    <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;"> - Abrir</a>
                                  </td>
                                  <td>
                                    <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                      class="btn btn-outline-dark">Eliminar</button>
                                  </td>

                                </tr>

                              </tbody>
                            </table>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>


                  <div class="panel panel-default">
                    <div class="panel-heading">
                      <h4 class="panel-title">
                        <button (click)="a82020()" data-bs-toggle="collapse" data-bs-parent="#accordion2"
                          href="#collapse800" style="background: none; border: none;">
                          <i class="bi bi-folder-fill">2020</i></button>
                      </h4>
                    </div>
                    <div id="collapse800" class="panel-collapse collapse in" data-bs-parent="#accordion2">
                      <div class="panel-body">
                        <div style="margin: auto; width: 80%; height: 70px; display: flex; justify-content: right;"
                          *ngIf="boton === 1">
                          <button style="height: 38px;" (click)="seleccionarTodo20()" type="button"
                            class="btn btn-outline-dark">Seleccionar Todo</button>
                          <button style="height: 38px;  margin-left: 10px;" (click)="quitarSeleccion20()" type="button"
                            class="btn btn-outline-dark">Desahacer Selección</button>
                          <button style="height: 38px; margin-left: 10px;" (click)="eliminarSeleccionados()"
                            type="button" class="btn btn-outline-dark">Eliminar Seleccionados</button>
                        </div>
                        <div class="fondo">

                          <div id="scroll">
                            <table class="table table-striped">
                              <thead>
                                <tr>
                                  <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre
                                    del
                                    documento</th>
                                  <th scope="col"></th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr *ngFor="let dato of docs20, let i = index">
                                  <td><input type="checkbox" (change)="checkCheckBoxvalue20($event, i, dato?.ID)"
                                      *ngIf="boton === 1" [checked]="todo20">
                                    <img src="assets/img/docs.png" alt="" height="30" width="30">{{dato?.NOMBRE}}
                                    <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;"> - Abrir</a>
                                  </td>
                                  <td>
                                    <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                      class="btn btn-outline-dark">Eliminar</button>
                                  </td>

                                </tr>
                              </tbody>
                            </table>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>


                  <div class="panel panel-default">
                    <div class="panel-heading">
                      <h4 class="panel-title">
                        <button (click)="a82021()" data-bs-toggle="collapse" data-bs-parent="#accordion2"
                          href="#collapse900" style="background: none; border: none;">
                          <i class="bi bi-folder-fill">2021</i></button>
                      </h4>
                    </div>
                    <div id="collapse900" class="panel-collapse collapse in" data-bs-parent="#accordion2">
                      <div class="panel-body">
                        <div style="margin: auto; width: 80%; height: 70px; display: flex; justify-content: right;"
                          *ngIf="boton === 1">
                          <button style="height: 38px;" (click)="seleccionarTodo21()" type="button"
                            class="btn btn-outline-dark">Seleccionar Todo</button>
                          <button style="height: 38px;  margin-left: 10px;" (click)="quitarSeleccion21()" type="button"
                            class="btn btn-outline-dark">Desahacer Selección</button>
                          <button style="height: 38px; margin-left: 10px;" (click)="eliminarSeleccionados()"
                            type="button" class="btn btn-outline-dark">Eliminar Seleccionados</button>
                        </div>
                        <div class="fondo">

                          <div id="scroll">
                            <table class="table table-striped">
                              <thead>
                                <tr>
                                  <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre
                                    del
                                    documento</th>
                                  <th scope="col"></th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr *ngFor="let dato of docs21, let i = index">
                                  <td><input type="checkbox" (change)="checkCheckBoxvalue21($event, i, dato?.ID)"
                                      *ngIf="boton === 1" [checked]="todo21">
                                    <img src="assets/img/docs.png" alt="" height="30" width="30">{{dato?.NOMBRE}}
                                    <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;"> - Abrir</a>
                                  </td>
                                  <td>
                                    <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                      class="btn btn-outline-dark">Eliminar</button>
                                  </td>

                                </tr>
                              </tbody>
                            </table>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>


                  <div class="panel panel-default">
                    <div class="panel-heading">
                      <h4 class="panel-title">
                        <button (click)="a82022()" data-bs-toggle="collapse" data-bs-parent="#accordion2"
                          href="#collapse1000" style="background: none; border: none;">
                          <i class="bi bi-folder-fill">2022</i></button>
                      </h4>
                    </div>
                    <div id="collapse1000" class="panel-collapse collapse in" data-bs-parent="#accordion2">
                      <div class="panel-body">
                        <div style="margin: auto; width: 80%; height: 70px; display: flex; justify-content: right;"
                          *ngIf="boton === 1">
                          <button style="height: 38px;" (click)="seleccionarTodo22()" type="button"
                            class="btn btn-outline-dark">Seleccionar Todo</button>
                          <button style="height: 38px;  margin-left: 10px;" (click)="quitarSeleccion22()" type="button"
                            class="btn btn-outline-dark">Desahacer Selección</button>
                          <button style="height: 38px; margin-left: 10px;" (click)="eliminarSeleccionados()"
                            type="button" class="btn btn-outline-dark">Eliminar Seleccionados</button>
                        </div>
                        <div class="fondo">

                          <div id="scroll">
                            <table class="table table-striped">
                              <thead>
                                <tr>
                                  <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre
                                    del
                                    documento</th>
                                  <th scope="col"></th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr *ngFor="let dato of docs22, let i = index">
                                  <td><input type="checkbox" (change)="checkCheckBoxvalue22($event, i, dato?.ID)"
                                      *ngIf="boton === 1" [checked]="todo22">
                                    <img src="assets/img/docs.png" alt="" height="30" width="30">{{dato?.NOMBRE}}
                                    <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;"> - Abrir</a>
                                  </td>
                                  <td>
                                    <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                      class="btn btn-outline-dark">Eliminar</button>
                                  </td>

                                </tr>
                              </tbody>
                            </table>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>


                  <div class="panel panel-default">
                    <div class="panel-heading">
                      <h4 class="panel-title">
                        <button (click)="a82023()" data-bs-toggle="collapse" data-bs-parent="#accordion2"
                          href="#collapse21233" style="background: none; border: none;">
                          <i class="bi bi-folder-fill">2023</i></button>
                      </h4>
                    </div>
                    <div id="collapse21233" class="panel-collapse collapse in" data-bs-parent="#accordion2">
                      <div class="panel-body">
                        <div style="margin: auto; width: 80%; height: 70px; display: flex; justify-content: right;"
                          *ngIf="boton === 1">
                          <button style="height: 38px;" (click)="seleccionarTodo23()" type="button"
                            class="btn btn-outline-dark">Seleccionar Todo</button>
                          <button style="height: 38px;  margin-left: 10px;" (click)="quitarSeleccion23()" type="button"
                            class="btn btn-outline-dark">Desahacer Selección</button>
                          <button style="height: 38px; margin-left: 10px;" (click)="eliminarSeleccionados()"
                            type="button" class="btn btn-outline-dark">Eliminar Seleccionados</button>
                        </div>
                        <div class="fondo">

                          <div id="scroll">
                            <table class="table table-striped">
                              <thead>
                                <tr>
                                  <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre
                                    del
                                    documento</th>
                                  <th scope="col"></th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr *ngFor="let dato of docs23, let i = index">
                                  <td><input type="checkbox" (change)="checkCheckBoxvalue23($event, i, dato?.ID)"
                                      *ngIf="boton === 1" [checked]="todo23">
                                    <img src="assets/img/docs.png" alt="" height="30" width="30">{{dato?.NOMBRE}}
                                    <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;"> - Abrir</a>
                                  </td>
                                  <td>
                                    <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                      class="btn btn-outline-dark">Eliminar</button>
                                  </td>

                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>


  <div class="panel panel-default">
                    <div class="panel-heading">
                      <h4 class="panel-title">
                        <button (click)="a82024()" data-bs-toggle="collapse" data-bs-parent="#accordion2"
                          href="#collapse21243" style="background: none; border: none;">
                          <i class="bi bi-folder-fill">2024</i></button>
                      </h4>
                    </div>
                    <div id="collapse21243" class="panel-collapse collapse in" data-bs-parent="#accordion2">
                      <div class="panel-body">
                        <div style="margin: auto; width: 80%; height: 70px; display: flex; justify-content: right;"
                          *ngIf="boton === 1">
                          <button style="height: 38px;" (click)="seleccionarTodo24()" type="button"
                            class="btn btn-outline-dark">Seleccionar Todo</button>
                          <button style="height: 38px;  margin-left: 10px;" (click)="quitarSeleccion24()" type="button"
                            class="btn btn-outline-dark">Desahacer Selección</button>
                          <button style="height: 38px; margin-left: 10px;" (click)="eliminarSeleccionados()"
                            type="button" class="btn btn-outline-dark">Eliminar Seleccionados</button>
                        </div>
                        <div class="fondo">

                          <div id="scroll">
                            <table class="table table-striped">
                              <thead>
                                <tr>
                                  <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre
                                    del
                                    documento</th>
                                  <th scope="col"></th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr *ngFor="let dato of docs24, let i = index">
                                  <td><input type="checkbox" (change)="checkCheckBoxvalue24($event, i, dato?.ID)"
                                      *ngIf="boton === 1" [checked]="todo24">
                                    <img src="assets/img/docs.png" alt="" height="30" width="30">{{dato?.NOMBRE}}
                                    <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;"> - Abrir</a>
                                  </td>
                                  <td>
                                    <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                      class="btn btn-outline-dark">Eliminar</button>
                                  </td>

                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>








                </div>
              </div>
            </div>

            <div class="panel panel-default">
              <div class="panel-heading">
                <h4 class="panel-title">
                  <button (click)="area3()" data-bs-toggle="collapse" data-bs-parent="#accordion1" href="#a3"
                    style="background: none; border: none;">
                    <i class="bi bi-folder-fill">Centro de dia</i></button>
                </h4>
              </div>
              <div id="a3" class="panel-collapse collapse in" data-bs-parent="#accordion1">
                <div class="panel-group" id="accordion3" style=" margin-left: 20px;">

                  <div class="panel panel-default">
                    <div class="panel-heading">
                      <h4 class="panel-title">
                        <button (click)="a82018()" data-bs-toggle="collapse" data-bs-parent="#accordion3"
                          href="#collapse1100" style="background: none; border: none;">
                          <i class="bi bi-folder-fill">2018</i></button>
                      </h4>
                    </div>
                    <div id="collapse1100" class="panel-collapse collapse in" data-bs-parent="#accordion3">
                      <div class="panel-body">
                        <div style="margin: auto; width: 80%; height: 70px; display: flex; justify-content: right;"
                          *ngIf="boton === 1">
                          <button style="height: 38px;" (click)="seleccionarTodo18()" type="button"
                            class="btn btn-outline-dark">Seleccionar Todo</button>
                          <button style="height: 38px;  margin-left: 10px;" (click)="quitarSeleccion18()" type="button"
                            class="btn btn-outline-dark">Desahacer Selección</button>
                          <button style="height: 38px; margin-left: 10px;" (click)="eliminarSeleccionados()"
                            type="button" class="btn btn-outline-dark">Eliminar Seleccionados</button>
                        </div>
                        <div class="fondo">

                          <div id="scroll">
                            <table class="table table-striped">
                              <thead>
                                <tr>
                                  <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre
                                    del
                                    documento</th>
                                  <th scope="col"></th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr *ngFor="let dato of docs18, let i = index">
                                  <td><input type="checkbox" (change)="checkCheckBoxvalue18($event, i, dato?.ID)"
                                      *ngIf="boton === 1" [checked]="todo18">
                                    <img src="assets/img/docs.png" alt="" height="30" width="30">{{dato?.NOMBRE}}
                                    <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;"> - Abrir</a>
                                  </td>

                                  <td>
                                    <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                      class="btn btn-outline-dark">Eliminar</button>
                                  </td>

                                </tr>
                              </tbody>
                            </table>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>



                  <div class="panel panel-default">
                    <div class="panel-heading">
                      <h4 class="panel-title">
                        <button (click)="a82019()" data-bs-toggle="collapse" data-bs-parent="#accordion3"
                          href="#collapse12" style="background: none; border: none;">
                          <i class="bi bi-folder-fill">2019</i></button>
                      </h4>
                    </div>
                    <div id="collapse12" class="panel-collapse collapse in" data-bs-parent="#accordion3">
                      <div class="panel-body">
                        <div style="margin: auto; width: 80%; height: 70px; display: flex; justify-content: right;"
                          *ngIf="boton === 1">
                          <button style="height: 38px;" (click)="seleccionarTodo19()" type="button"
                            class="btn btn-outline-dark">Seleccionar Todo</button>
                          <button style="height: 38px;  margin-left: 10px;" (click)="quitarSeleccion19()" type="button"
                            class="btn btn-outline-dark">Desahacer Selección</button>
                          <button style="height: 38px; margin-left: 10px;" (click)="eliminarSeleccionados()"
                            type="button" class="btn btn-outline-dark">Eliminar Seleccionados</button>
                        </div>
                        <div class="fondo">

                          <div id="scroll">
                            <table class="table table-striped">
                              <thead>
                                <tr>
                                  <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre
                                    del
                                    documento</th>
                                  <th scope="col"></th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr *ngFor="let dato of docs19, let i = index">
                                  <td><input type="checkbox" (change)="checkCheckBoxvalue19($event, i, dato?.ID)"
                                      *ngIf="boton === 1" [checked]="todo19">
                                    <img src="assets/img/docs.png" alt="" height="30" width="30">{{dato?.NOMBRE}}
                                    <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;"> - Abrir</a>
                                  </td>
                                  <td>
                                    <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                      class="btn btn-outline-dark">Eliminar</button>
                                  </td>

                                </tr>

                              </tbody>
                            </table>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>


                  <div class="panel panel-default">
                    <div class="panel-heading">
                      <h4 class="panel-title">
                        <button (click)="a82020()" data-bs-toggle="collapse" data-bs-parent="#accordion3"
                          href="#collapse13" style="background: none; border: none;">
                          <i class="bi bi-folder-fill">2020</i></button>
                      </h4>
                    </div>
                    <div id="collapse13" class="panel-collapse collapse in" data-bs-parent="#accordion3">
                      <div class="panel-body">
                        <div style="margin: auto; width: 80%; height: 70px; display: flex; justify-content: right;"
                          *ngIf="boton === 1">
                          <button style="height: 38px;" (click)="seleccionarTodo20()" type="button"
                            class="btn btn-outline-dark">Seleccionar Todo</button>
                          <button style="height: 38px;  margin-left: 10px;" (click)="quitarSeleccion20()" type="button"
                            class="btn btn-outline-dark">Desahacer Selección</button>
                          <button style="height: 38px; margin-left: 10px;" (click)="eliminarSeleccionados()"
                            type="button" class="btn btn-outline-dark">Eliminar Seleccionados</button>
                        </div>
                        <div class="fondo">

                          <div id="scroll">
                            <table class="table table-striped">
                              <thead>
                                <tr>
                                  <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre
                                    del
                                    documento</th>
                                  <th scope="col"></th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr *ngFor="let dato of docs20, let i = index">
                                  <td><input type="checkbox" (change)="checkCheckBoxvalue20($event, i, dato?.ID)"
                                      *ngIf="boton === 1" [checked]="todo20">
                                    <img src="assets/img/docs.png" alt="" height="30" width="30">{{dato?.NOMBRE}}
                                    <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;"> - Abrir</a>
                                  </td>
                                  <td>
                                    <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                      class="btn btn-outline-dark">Eliminar</button>
                                  </td>

                                </tr>
                              </tbody>
                            </table>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>


                  <div class="panel panel-default">
                    <div class="panel-heading">
                      <h4 class="panel-title">
                        <button (click)="a82021()" data-bs-toggle="collapse" data-bs-parent="#accordion3"
                          href="#collapse14" style="background: none; border: none;">
                          <i class="bi bi-folder-fill">2021</i></button>
                      </h4>
                    </div>
                    <div id="collapse14" class="panel-collapse collapse in" data-bs-parent="#accordion3">
                      <div class="panel-body">
                        <div style="margin: auto; width: 80%; height: 70px; display: flex; justify-content: right;"
                          *ngIf="boton === 1">
                          <button style="height: 38px;" (click)="seleccionarTodo21()" type="button"
                            class="btn btn-outline-dark">Seleccionar Todo</button>
                          <button style="height: 38px;  margin-left: 10px;" (click)="quitarSeleccion21()" type="button"
                            class="btn btn-outline-dark">Desahacer Selección</button>
                          <button style="height: 38px; margin-left: 10px;" (click)="eliminarSeleccionados()"
                            type="button" class="btn btn-outline-dark">Eliminar Seleccionados</button>
                        </div>
                        <div class="fondo">

                          <div id="scroll">
                            <table class="table table-striped">
                              <thead>
                                <tr>
                                  <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre
                                    del
                                    documento</th>
                                  <th scope="col"></th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr *ngFor="let dato of docs21, let i = index">
                                  <td><input type="checkbox" (change)="checkCheckBoxvalue21($event, i, dato?.ID)"
                                      *ngIf="boton === 1" [checked]="todo21">
                                    <img src="assets/img/docs.png" alt="" height="30" width="30">{{dato?.NOMBRE}}
                                    <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;"> - Abrir</a>
                                  </td>
                                  <td>
                                    <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                      class="btn btn-outline-dark">Eliminar</button>
                                  </td>

                                </tr>
                              </tbody>
                            </table>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>


                  <div class="panel panel-default">
                    <div class="panel-heading">
                      <h4 class="panel-title">
                        <button (click)="a82022()" data-bs-toggle="collapse" data-bs-parent="#accordion3"
                          href="#collapse15" style="background: none; border: none;">
                          <i class="bi bi-folder-fill">2022</i></button>
                      </h4>
                    </div>
                    <div id="collapse15" class="panel-collapse collapse in" data-bs-parent="#accordion3">
                      <div class="panel-body">
                        <div style="margin: auto; width: 80%; height: 70px; display: flex; justify-content: right;"
                          *ngIf="boton === 1">
                          <button style="height: 38px;" (click)="seleccionarTodo22()" type="button"
                            class="btn btn-outline-dark">Seleccionar Todo</button>
                          <button style="height: 38px;  margin-left: 10px;" (click)="quitarSeleccion22()" type="button"
                            class="btn btn-outline-dark">Desahacer Selección</button>
                          <button style="height: 38px; margin-left: 10px;" (click)="eliminarSeleccionados()"
                            type="button" class="btn btn-outline-dark">Eliminar Seleccionados</button>
                        </div>
                        <div class="fondo">

                          <div id="scroll">
                            <table class="table table-striped">
                              <thead>
                                <tr>
                                  <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre
                                    del
                                    documento</th>
                                  <th scope="col"></th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr *ngFor="let dato of docs22, let i = index">
                                  <td><input type="checkbox" (change)="checkCheckBoxvalue22($event, i, dato?.ID)"
                                      *ngIf="boton === 1" [checked]="todo22">
                                    <img src="assets/img/docs.png" alt="" height="30" width="30">{{dato?.NOMBRE}}
                                    <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;"> - Abrir</a>
                                  </td>
                                  <td>
                                    <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                      class="btn btn-outline-dark">Eliminar</button>
                                  </td>

                                </tr>
                              </tbody>
                            </table>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>


                  <div class="panel panel-default">
                    <div class="panel-heading">
                      <h4 class="panel-title">
                        <button (click)="a82023()" data-bs-toggle="collapse" data-bs-parent="#accordion3"
                          href="#collapse21232" style="background: none; border: none;">
                          <i class="bi bi-folder-fill">2023</i></button>
                      </h4>
                    </div>
                    <div id="collapse21232" class="panel-collapse collapse in" data-bs-parent="#accordion3">
                      <div class="panel-body">
                        <div style="margin: auto; width: 80%; height: 70px; display: flex; justify-content: right;"
                          *ngIf="boton === 1">
                          <button style="height: 38px;" (click)="seleccionarTodo23()" type="button"
                            class="btn btn-outline-dark">Seleccionar Todo</button>
                          <button style="height: 38px;  margin-left: 10px;" (click)="quitarSeleccion23()" type="button"
                            class="btn btn-outline-dark">Desahacer Selección</button>
                          <button style="height: 38px; margin-left: 10px;" (click)="eliminarSeleccionados()"
                            type="button" class="btn btn-outline-dark">Eliminar Seleccionados</button>
                        </div>
                        <div class="fondo">

                          <div id="scroll">
                            <table class="table table-striped">
                              <thead>
                                <tr>
                                  <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre
                                    del
                                    documento</th>
                                  <th scope="col"></th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr *ngFor="let dato of docs23, let i = index">
                                  <td><input type="checkbox" (change)="checkCheckBoxvalue23($event, i, dato?.ID)"
                                      *ngIf="boton === 1" [checked]="todo23">
                                    <img src="assets/img/docs.png" alt="" height="30" width="30">{{dato?.NOMBRE}}
                                    <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;"> - Abrir</a>
                                  </td>
                                  <td>
                                    <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                      class="btn btn-outline-dark">Eliminar</button>
                                  </td>

                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>


  <div class="panel panel-default">
                    <div class="panel-heading">
                      <h4 class="panel-title">
                        <button (click)="a82024()" data-bs-toggle="collapse" data-bs-parent="#accordion3"
                          href="#collapse21242" style="background: none; border: none;">
                          <i class="bi bi-folder-fill">2024</i></button>
                      </h4>
                    </div>
                    <div id="collapse21242" class="panel-collapse collapse in" data-bs-parent="#accordion3">
                      <div class="panel-body">
                        <div style="margin: auto; width: 80%; height: 70px; display: flex; justify-content: right;"
                          *ngIf="boton === 1">
                          <button style="height: 38px;" (click)="seleccionarTodo24()" type="button"
                            class="btn btn-outline-dark">Seleccionar Todo</button>
                          <button style="height: 38px;  margin-left: 10px;" (click)="quitarSeleccion24()" type="button"
                            class="btn btn-outline-dark">Desahacer Selección</button>
                          <button style="height: 38px; margin-left: 10px;" (click)="eliminarSeleccionados()"
                            type="button" class="btn btn-outline-dark">Eliminar Seleccionados</button>
                        </div>
                        <div class="fondo">

                          <div id="scroll">
                            <table class="table table-striped">
                              <thead>
                                <tr>
                                  <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre
                                    del
                                    documento</th>
                                  <th scope="col"></th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr *ngFor="let dato of docs24, let i = index">
                                  <td><input type="checkbox" (change)="checkCheckBoxvalue24($event, i, dato?.ID)"
                                      *ngIf="boton === 1" [checked]="todo24">
                                    <img src="assets/img/docs.png" alt="" height="30" width="30">{{dato?.NOMBRE}}
                                    <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;"> - Abrir</a>
                                  </td>
                                  <td>
                                    <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                      class="btn btn-outline-dark">Eliminar</button>
                                  </td>

                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>


                </div>
              </div>
            </div>

            <div class="panel panel-default">
              <div class="panel-heading">
                <h4 class="panel-title">
                  <button (click)="area4()" data-bs-toggle="collapse" data-bs-parent="#accordion1" href="#a4"
                    style="background: none; border: none;">
                    <i class="bi bi-folder-fill">Coordinación alimentaria</i></button>
                </h4>
              </div>
              <div id="a4" class="panel-collapse collapse in" data-bs-parent="#accordion1">
                <div class="panel-group" id="accordion4" style=" margin-left: 20px;">

                  <div class="panel panel-default">
                    <div class="panel-heading">
                      <h4 class="panel-title">
                        <button (click)="a82018()" data-bs-toggle="collapse" data-bs-parent="#accordion4"
                          href="#collapse1600" style="background: none; border: none;">
                          <i class="bi bi-folder-fill">2018</i></button>
                      </h4>
                    </div>
                    <div id="collapse1600" class="panel-collapse collapse in" data-bs-parent="#accordion4">
                      <div class="panel-body">
                        <div style="margin: auto; width: 80%; height: 70px; display: flex; justify-content: right;"
                          *ngIf="boton === 1">
                          <button style="height: 38px;" (click)="seleccionarTodo18()" type="button"
                            class="btn btn-outline-dark">Seleccionar Todo</button>
                          <button style="height: 38px;  margin-left: 10px;" (click)="quitarSeleccion18()" type="button"
                            class="btn btn-outline-dark">Desahacer Selección</button>
                          <button style="height: 38px; margin-left: 10px;" (click)="eliminarSeleccionados()"
                            type="button" class="btn btn-outline-dark">Eliminar Seleccionados</button>
                        </div>
                        <div class="fondo">

                          <div id="scroll">
                            <table class="table table-striped">
                              <thead>
                                <tr>
                                  <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre
                                    del
                                    documento</th>
                                  <th scope="col"></th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr *ngFor="let dato of docs18, let i = index">
                                  <td><input type="checkbox" (change)="checkCheckBoxvalue18($event, i, dato?.ID)"
                                      *ngIf="boton === 1" [checked]="todo18">
                                    <img src="assets/img/docs.png" alt="" height="30" width="30">{{dato?.NOMBRE}}
                                    <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;"> - Abrir</a>
                                  </td>

                                  <td>
                                    <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                      class="btn btn-outline-dark">Eliminar</button>
                                  </td>

                                </tr>
                              </tbody>
                            </table>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>



                  <div class="panel panel-default">
                    <div class="panel-heading">
                      <h4 class="panel-title">
                        <button (click)="a82019()" data-bs-toggle="collapse" data-bs-parent="#accordion4"
                          href="#collapse1700" style="background: none; border: none;">
                          <i class="bi bi-folder-fill">2019</i></button>
                      </h4>
                    </div>
                    <div id="collapse1700" class="panel-collapse collapse in" data-bs-parent="#accordion4">
                      <div class="panel-body">
                        <div style="margin: auto; width: 80%; height: 70px; display: flex; justify-content: right;"
                          *ngIf="boton === 1">
                          <button style="height: 38px;" (click)="seleccionarTodo19()" type="button"
                            class="btn btn-outline-dark">Seleccionar Todo</button>
                          <button style="height: 38px;  margin-left: 10px;" (click)="quitarSeleccion19()" type="button"
                            class="btn btn-outline-dark">Desahacer Selección</button>
                          <button style="height: 38px; margin-left: 10px;" (click)="eliminarSeleccionados()"
                            type="button" class="btn btn-outline-dark">Eliminar Seleccionados</button>
                        </div>
                        <div class="fondo">

                          <div id="scroll">
                            <table class="table table-striped">
                              <thead>
                                <tr>
                                  <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre
                                    del
                                    documento</th>
                                  <th scope="col"></th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr *ngFor="let dato of docs19, let i = index">
                                  <td><input type="checkbox" (change)="checkCheckBoxvalue19($event, i, dato?.ID)"
                                      *ngIf="boton === 1" [checked]="todo19">
                                    <img src="assets/img/docs.png" alt="" height="30" width="30">{{dato?.NOMBRE}}
                                    <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;"> - Abrir</a>
                                  </td>
                                  <td>
                                    <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                      class="btn btn-outline-dark">Eliminar</button>
                                  </td>

                                </tr>

                              </tbody>
                            </table>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>


                  <div class="panel panel-default">
                    <div class="panel-heading">
                      <h4 class="panel-title">
                        <button (click)="a82020()" data-bs-toggle="collapse" data-bs-parent="#accordion4"
                          href="#collapse1800" style="background: none; border: none;">
                          <i class="bi bi-folder-fill">2020</i></button>
                      </h4>
                    </div>
                    <div id="collapse1800" class="panel-collapse collapse in" data-bs-parent="#accordion4">
                      <div class="panel-body">
                        <div style="margin: auto; width: 80%; height: 70px; display: flex; justify-content: right;"
                          *ngIf="boton === 1">
                          <button style="height: 38px;" (click)="seleccionarTodo20()" type="button"
                            class="btn btn-outline-dark">Seleccionar Todo</button>
                          <button style="height: 38px;  margin-left: 10px;" (click)="quitarSeleccion20()" type="button"
                            class="btn btn-outline-dark">Desahacer Selección</button>
                          <button style="height: 38px; margin-left: 10px;" (click)="eliminarSeleccionados()"
                            type="button" class="btn btn-outline-dark">Eliminar Seleccionados</button>
                        </div>
                        <div class="fondo">

                          <div id="scroll">
                            <table class="table table-striped">
                              <thead>
                                <tr>
                                  <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre
                                    del
                                    documento</th>
                                  <th scope="col"></th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr *ngFor="let dato of docs20, let i = index">
                                  <td><input type="checkbox" (change)="checkCheckBoxvalue20($event, i, dato?.ID)"
                                      *ngIf="boton === 1" [checked]="todo20">
                                    <img src="assets/img/docs.png" alt="" height="30" width="30">{{dato?.NOMBRE}}
                                    <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;"> - Abrir</a>
                                  </td>
                                  <td>
                                    <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                      class="btn btn-outline-dark">Eliminar</button>
                                  </td>

                                </tr>
                              </tbody>
                            </table>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>


                  <div class="panel panel-default">
                    <div class="panel-heading">
                      <h4 class="panel-title">
                        <button (click)="a82021()" data-bs-toggle="collapse" data-bs-parent="#accordion4"
                          href="#collapse1900" style="background: none; border: none;">
                          <i class="bi bi-folder-fill">2021</i></button>
                      </h4>
                    </div>
                    <div id="collapse1900" class="panel-collapse collapse in" data-bs-parent="#accordion4">
                      <div class="panel-body">
                        <div style="margin: auto; width: 80%; height: 70px; display: flex; justify-content: right;"
                          *ngIf="boton === 1">
                          <button style="height: 38px;" (click)="seleccionarTodo21()" type="button"
                            class="btn btn-outline-dark">Seleccionar Todo</button>
                          <button style="height: 38px;  margin-left: 10px;" (click)="quitarSeleccion21()" type="button"
                            class="btn btn-outline-dark">Desahacer Selección</button>
                          <button style="height: 38px; margin-left: 10px;" (click)="eliminarSeleccionados()"
                            type="button" class="btn btn-outline-dark">Eliminar Seleccionados</button>
                        </div>
                        <div class="fondo">

                          <div id="scroll">
                            <table class="table table-striped">
                              <thead>
                                <tr>
                                  <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre
                                    del
                                    documento</th>
                                  <th scope="col"></th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr *ngFor="let dato of docs21, let i = index">
                                  <td><input type="checkbox" (change)="checkCheckBoxvalue21($event, i, dato?.ID)"
                                      *ngIf="boton === 1" [checked]="todo21">
                                    <img src="assets/img/docs.png" alt="" height="30" width="30">{{dato?.NOMBRE}}
                                    <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;"> - Abrir</a>
                                  </td>
                                  <td>
                                    <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                      class="btn btn-outline-dark">Eliminar</button>
                                  </td>

                                </tr>
                              </tbody>
                            </table>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>


                  <div class="panel panel-default">
                    <div class="panel-heading">
                      <h4 class="panel-title">
                        <button (click)="a82022()" data-bs-toggle="collapse" data-bs-parent="#accordion4"
                          href="#collapse2000" style="background: none; border: none;">
                          <i class="bi bi-folder-fill">2022</i></button>
                      </h4>
                    </div>
                    <div id="collapse2000" class="panel-collapse collapse in" data-bs-parent="#accordion4">
                      <div class="panel-body">
                        <div style="margin: auto; width: 80%; height: 70px; display: flex; justify-content: right;"
                          *ngIf="boton === 1">
                          <button style="height: 38px;" (click)="seleccionarTodo22()" type="button"
                            class="btn btn-outline-dark">Seleccionar Todo</button>
                          <button style="height: 38px;  margin-left: 10px;" (click)="quitarSeleccion22()" type="button"
                            class="btn btn-outline-dark">Desahacer Selección</button>
                          <button style="height: 38px; margin-left: 10px;" (click)="eliminarSeleccionados()"
                            type="button" class="btn btn-outline-dark">Eliminar Seleccionados</button>
                        </div>
                        <div class="fondo">

                          <div id="scroll">
                            <table class="table table-striped">
                              <thead>
                                <tr>
                                  <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre
                                    del
                                    documento</th>
                                  <th scope="col"></th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr *ngFor="let dato of docs22, let i = index">
                                  <td><input type="checkbox" (change)="checkCheckBoxvalue22($event, i, dato?.ID)"
                                      *ngIf="boton === 1" [checked]="todo22">
                                    <img src="assets/img/docs.png" alt="" height="30" width="30">{{dato?.NOMBRE}}
                                    <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;"> - Abrir</a>
                                  </td>
                                  <td>
                                    <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                      class="btn btn-outline-dark">Eliminar</button>
                                  </td>

                                </tr>
                              </tbody>
                            </table>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="panel panel-default">
                    <div class="panel-heading">
                      <h4 class="panel-title">
                        <button (click)="a82023()" data-bs-toggle="collapse" data-bs-parent="#accordion4"
                          href="#collapse21234" style="background: none; border: none;">
                          <i class="bi bi-folder-fill">2023</i></button>
                      </h4>
                    </div>
                    <div id="collapse21234" class="panel-collapse collapse in" data-bs-parent="#accordion4">
                      <div class="panel-body">
                        <div style="margin: auto; width: 80%; height: 70px; display: flex; justify-content: right;"
                          *ngIf="boton === 1">
                          <button style="height: 38px;" (click)="seleccionarTodo23()" type="button"
                            class="btn btn-outline-dark">Seleccionar Todo</button>
                          <button style="height: 38px;  margin-left: 10px;" (click)="quitarSeleccion23()" type="button"
                            class="btn btn-outline-dark">Desahacer Selección</button>
                          <button style="height: 38px; margin-left: 10px;" (click)="eliminarSeleccionados()"
                            type="button" class="btn btn-outline-dark">Eliminar Seleccionados</button>
                        </div>
                        <div class="fondo">

                          <div id="scroll">
                            <table class="table table-striped">
                              <thead>
                                <tr>
                                  <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre
                                    del
                                    documento</th>
                                  <th scope="col"></th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr *ngFor="let dato of docs23, let i = index">
                                  <td><input type="checkbox" (change)="checkCheckBoxvalue23($event, i, dato?.ID)"
                                      *ngIf="boton === 1" [checked]="todo23">
                                    <img src="assets/img/docs.png" alt="" height="30" width="30">{{dato?.NOMBRE}}
                                    <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;"> - Abrir</a>
                                  </td>
                                  <td>
                                    <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                      class="btn btn-outline-dark">Eliminar</button>
                                  </td>

                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>


        <div class="panel panel-default">
                    <div class="panel-heading">
                      <h4 class="panel-title">
                        <button (click)="a82024()" data-bs-toggle="collapse" data-bs-parent="#accordion4"
                          href="#collapse21244" style="background: none; border: none;">
                          <i class="bi bi-folder-fill">2024</i></button>
                      </h4>
                    </div>
                    <div id="collapse21244" class="panel-collapse collapse in" data-bs-parent="#accordion4">
                      <div class="panel-body">
                        <div style="margin: auto; width: 80%; height: 70px; display: flex; justify-content: right;"
                          *ngIf="boton === 1">
                          <button style="height: 38px;" (click)="seleccionarTodo24()" type="button"
                            class="btn btn-outline-dark">Seleccionar Todo</button>
                          <button style="height: 38px;  margin-left: 10px;" (click)="quitarSeleccion24()" type="button"
                            class="btn btn-outline-dark">Desahacer Selección</button>
                          <button style="height: 38px; margin-left: 10px;" (click)="eliminarSeleccionados()"
                            type="button" class="btn btn-outline-dark">Eliminar Seleccionados</button>
                        </div>
                        <div class="fondo">

                          <div id="scroll">
                            <table class="table table-striped">
                              <thead>
                                <tr>
                                  <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre
                                    del
                                    documento</th>
                                  <th scope="col"></th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr *ngFor="let dato of docs24, let i = index">
                                  <td><input type="checkbox" (change)="checkCheckBoxvalue24($event, i, dato?.ID)"
                                      *ngIf="boton === 1" [checked]="todo24">
                                    <img src="assets/img/docs.png" alt="" height="30" width="30">{{dato?.NOMBRE}}
                                    <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;"> - Abrir</a>
                                  </td>
                                  <td>
                                    <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                      class="btn btn-outline-dark">Eliminar</button>
                                  </td>

                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>









                </div>
              </div>
            </div>

            <div class="panel panel-default">
              <div class="panel-heading">
                <h4 class="panel-title">
                  <button (click)="area5()" data-bs-toggle="collapse" data-bs-parent="#accordion1" href="#a5"
                    style="background: none; border: none;">
                    <i class="bi bi-folder-fill">Coordinación administrativa y de recursos financieros</i></button>
                </h4>
              </div>
              <div id="a5" class="panel-collapse collapse in" data-bs-parent="#accordion1">
                <div class="panel-group" id="accordion5" style=" margin-left: 20px;">

                  <div class="panel panel-default">
                    <div class="panel-heading">
                      <h4 class="panel-title">
                        <button (click)="a82018()" data-bs-toggle="collapse" data-bs-parent="#accordion5"
                          href="#collapse2100" style="background: none; border: none;">
                          <i class="bi bi-folder-fill">2018</i></button>
                      </h4>
                    </div>
                    <div id="collapse2100" class="panel-collapse collapse in" data-bs-parent="#accordion5">
                      <div class="panel-body">
                        <div style="margin: auto; width: 80%; height: 70px; display: flex; justify-content: right;"
                          *ngIf="boton === 1">
                          <button style="height: 38px;" (click)="seleccionarTodo18()" type="button"
                            class="btn btn-outline-dark">Seleccionar Todo</button>
                          <button style="height: 38px;  margin-left: 10px;" (click)="quitarSeleccion18()" type="button"
                            class="btn btn-outline-dark">Desahacer Selección</button>
                          <button style="height: 38px; margin-left: 10px;" (click)="eliminarSeleccionados()"
                            type="button" class="btn btn-outline-dark">Eliminar Seleccionados</button>
                        </div>
                        <div class="fondo">

                          <div id="scroll">
                            <table class="table table-striped">
                              <thead>
                                <tr>
                                  <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre
                                    del
                                    documento</th>
                                  <th scope="col"></th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr *ngFor="let dato of docs18, let i = index">
                                  <td><input type="checkbox" (change)="checkCheckBoxvalue18($event, i, dato?.ID)"
                                      *ngIf="boton === 1" [checked]="todo18">
                                    <img src="assets/img/docs.png" alt="" height="30" width="30">{{dato?.NOMBRE}}
                                    <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;"> - Abrir</a>
                                  </td>

                                  <td>
                                    <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                      class="btn btn-outline-dark">Eliminar</button>
                                  </td>

                                </tr>
                              </tbody>
                            </table>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>







                  <div class="panel panel-default">
                    <div class="panel-heading">
                      <h4 class="panel-title">
                        <button (click)="a82019()" data-bs-toggle="collapse" data-bs-parent="#accordion5"
                          href="#collapse2200" style="background: none; border: none;">
                          <i class="bi bi-folder-fill">2019</i></button>
                      </h4>
                    </div>
                    <div id="collapse2200" class="panel-collapse collapse in" data-bs-parent="#accordion5">
                      <div class="panel-body">
                        <div style="margin: auto; width: 80%; height: 70px; display: flex; justify-content: right;"
                          *ngIf="boton === 1">
                          <button style="height: 38px;" (click)="seleccionarTodo19()" type="button"
                            class="btn btn-outline-dark">Seleccionar Todo</button>
                          <button style="height: 38px;  margin-left: 10px;" (click)="quitarSeleccion19()" type="button"
                            class="btn btn-outline-dark">Desahacer Selección</button>
                          <button style="height: 38px; margin-left: 10px;" (click)="eliminarSeleccionados()"
                            type="button" class="btn btn-outline-dark">Eliminar Seleccionados</button>
                        </div>
                        <div class="fondo">

                          <div id="scroll">
                            <table class="table table-striped">
                              <thead>
                                <tr>
                                  <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre
                                    del
                                    documento</th>
                                  <th scope="col"></th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr *ngFor="let dato of docs19, let i = index">
                                  <td><input type="checkbox" (change)="checkCheckBoxvalue19($event, i, dato?.ID)"
                                      *ngIf="boton === 1" [checked]="todo19">
                                    <img src="assets/img/docs.png" alt="" height="30" width="30">{{dato?.NOMBRE}}
                                    <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;"> - Abrir</a>
                                  </td>
                                  <td>
                                    <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                      class="btn btn-outline-dark">Eliminar</button>
                                  </td>

                                </tr>

                              </tbody>
                            </table>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>


                  <div class="panel panel-default">
                    <div class="panel-heading">
                      <h4 class="panel-title">
                        <button (click)="a82020()" data-bs-toggle="collapse" data-bs-parent="#accordion5"
                          href="#collapse2300" style="background: none; border: none;">
                          <i class="bi bi-folder-fill">2020</i></button>
                      </h4>
                    </div>
                    <div id="collapse2300" class="panel-collapse collapse in" data-bs-parent="#accordion5">
                      <div class="panel-body">
                        <div style="margin: auto; width: 80%; height: 70px; display: flex; justify-content: right;"
                          *ngIf="boton === 1">
                          <button style="height: 38px;" (click)="seleccionarTodo20()" type="button"
                            class="btn btn-outline-dark">Seleccionar Todo</button>
                          <button style="height: 38px;  margin-left: 10px;" (click)="quitarSeleccion20()" type="button"
                            class="btn btn-outline-dark">Desahacer Selección</button>
                          <button style="height: 38px; margin-left: 10px;" (click)="eliminarSeleccionados()"
                            type="button" class="btn btn-outline-dark">Eliminar Seleccionados</button>
                        </div>
                        <div class="fondo">

                          <div id="scroll">
                            <table class="table table-striped">
                              <thead>
                                <tr>
                                  <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre
                                    del
                                    documento</th>
                                  <th scope="col"></th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr *ngFor="let dato of docs20, let i = index">
                                  <td><input type="checkbox" (change)="checkCheckBoxvalue20($event, i, dato?.ID)"
                                      *ngIf="boton === 1" [checked]="todo20">
                                    <img src="assets/img/docs.png" alt="" height="30" width="30">{{dato?.NOMBRE}}
                                    <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;"> - Abrir</a>
                                  </td>
                                  <td>
                                    <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                      class="btn btn-outline-dark">Eliminar</button>
                                  </td>

                                </tr>
                              </tbody>
                            </table>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>


                  <div class="panel panel-default">
                    <div class="panel-heading">
                      <h4 class="panel-title">
                        <button (click)="a82021()" data-bs-toggle="collapse" data-bs-parent="#accordion5"
                          href="#collapse2400" style="background: none; border: none;">
                          <i class="bi bi-folder-fill">2021</i></button>
                      </h4>
                    </div>
                    <div id="collapse2400" class="panel-collapse collapse in" data-bs-parent="#accordion5">
                      <div class="panel-body">
                        <div style="margin: auto; width: 80%; height: 70px; display: flex; justify-content: right;"
                          *ngIf="boton === 1">
                          <button style="height: 38px;" (click)="seleccionarTodo21()" type="button"
                            class="btn btn-outline-dark">Seleccionar Todo</button>
                          <button style="height: 38px;  margin-left: 10px;" (click)="quitarSeleccion21()" type="button"
                            class="btn btn-outline-dark">Desahacer Selección</button>
                          <button style="height: 38px; margin-left: 10px;" (click)="eliminarSeleccionados()"
                            type="button" class="btn btn-outline-dark">Eliminar Seleccionados</button>
                        </div>
                        <div class="fondo">

                          <div id="scroll">
                            <table class="table table-striped">
                              <thead>
                                <tr>
                                  <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre
                                    del
                                    documento</th>
                                  <th scope="col"></th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr *ngFor="let dato of docs21, let i = index">
                                  <td><input type="checkbox" (change)="checkCheckBoxvalue21($event, i, dato?.ID)"
                                      *ngIf="boton === 1" [checked]="todo21">
                                    <img src="assets/img/docs.png" alt="" height="30" width="30">{{dato?.NOMBRE}}
                                    <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;"> - Abrir</a>
                                  </td>
                                  <td>
                                    <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                      class="btn btn-outline-dark">Eliminar</button>
                                  </td>

                                </tr>
                              </tbody>
                            </table>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>


                  <div class="panel panel-default">
                    <div class="panel-heading">
                      <h4 class="panel-title">
                        <button (click)="a82022()" data-bs-toggle="collapse" data-bs-parent="#accordion5"
                          href="#collapse2500" style="background: none; border: none;">
                          <i class="bi bi-folder-fill">2022</i></button>
                      </h4>
                    </div>
                    <div id="collapse2500" class="panel-collapse collapse in" data-bs-parent="#accordion5">
                      <div class="panel-body">
                        <div style="margin: auto; width: 80%; height: 70px; display: flex; justify-content: right;"
                          *ngIf="boton === 1">
                          <button style="height: 38px;" (click)="seleccionarTodo22()" type="button"
                            class="btn btn-outline-dark">Seleccionar Todo</button>
                          <button style="height: 38px;  margin-left: 10px;" (click)="quitarSeleccion22()" type="button"
                            class="btn btn-outline-dark">Desahacer Selección</button>
                          <button style="height: 38px; margin-left: 10px;" (click)="eliminarSeleccionados()"
                            type="button" class="btn btn-outline-dark">Eliminar Seleccionados</button>
                        </div>
                        <div class="fondo">

                          <div id="scroll">
                            <table class="table table-striped">
                              <thead>
                                <tr>
                                  <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre
                                    del
                                    documento</th>
                                  <th scope="col"></th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr *ngFor="let dato of docs22, let i = index">
                                  <td><input type="checkbox" (change)="checkCheckBoxvalue22($event, i, dato?.ID)"
                                      *ngIf="boton === 1" [checked]="todo22">
                                    <img src="assets/img/docs.png" alt="" height="30" width="30">{{dato?.NOMBRE}}
                                    <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;"> - Abrir</a>
                                  </td>
                                  <td>
                                    <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                      class="btn btn-outline-dark">Eliminar</button>
                                  </td>

                                </tr>
                              </tbody>
                            </table>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>


                  <div class="panel panel-default">
                    <div class="panel-heading">
                      <h4 class="panel-title">
                        <button (click)="a82023()" data-bs-toggle="collapse" data-bs-parent="#accordion5"
                          href="#collapse21235" style="background: none; border: none;">
                          <i class="bi bi-folder-fill">2023</i></button>
                      </h4>
                    </div>
                    <div id="collapse21235" class="panel-collapse collapse in" data-bs-parent="#accordion5">
                      <div class="panel-body">
                        <div style="margin: auto; width: 80%; height: 70px; display: flex; justify-content: right;"
                          *ngIf="boton === 1">
                          <button style="height: 38px;" (click)="seleccionarTodo23()" type="button"
                            class="btn btn-outline-dark">Seleccionar Todo</button>
                          <button style="height: 38px;  margin-left: 10px;" (click)="quitarSeleccion23()" type="button"
                            class="btn btn-outline-dark">Desahacer Selección</button>
                          <button style="height: 38px; margin-left: 10px;" (click)="eliminarSeleccionados()"
                            type="button" class="btn btn-outline-dark">Eliminar Seleccionados</button>
                        </div>
                        <div class="fondo">

                          <div id="scroll">
                            <table class="table table-striped">
                              <thead>
                                <tr>
                                  <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre
                                    del
                                    documento</th>
                                  <th scope="col"></th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr *ngFor="let dato of docs23, let i = index">
                                  <td><input type="checkbox" (change)="checkCheckBoxvalue23($event, i, dato?.ID)"
                                      *ngIf="boton === 1" [checked]="todo23">
                                    <img src="assets/img/docs.png" alt="" height="30" width="30">{{dato?.NOMBRE}}
                                    <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;"> - Abrir</a>
                                  </td>
                                  <td>
                                    <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                      class="btn btn-outline-dark">Eliminar</button>
                                  </td>

                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>


   <div class="panel panel-default">
                    <div class="panel-heading">
                      <h4 class="panel-title">
                        <button (click)="a82024()" data-bs-toggle="collapse" data-bs-parent="#accordion5"
                          href="#collapse21245" style="background: none; border: none;">
                          <i class="bi bi-folder-fill">2024</i></button>
                      </h4>
                    </div>
                    <div id="collapse21245" class="panel-collapse collapse in" data-bs-parent="#accordion5">
                      <div class="panel-body">
                        <div style="margin: auto; width: 80%; height: 70px; display: flex; justify-content: right;"
                          *ngIf="boton === 1">
                          <button style="height: 38px;" (click)="seleccionarTodo24()" type="button"
                            class="btn btn-outline-dark">Seleccionar Todo</button>
                          <button style="height: 38px;  margin-left: 10px;" (click)="quitarSeleccion24()" type="button"
                            class="btn btn-outline-dark">Desahacer Selección</button>
                          <button style="height: 38px; margin-left: 10px;" (click)="eliminarSeleccionados()"
                            type="button" class="btn btn-outline-dark">Eliminar Seleccionados</button>
                        </div>
                        <div class="fondo">

                          <div id="scroll">
                            <table class="table table-striped">
                              <thead>
                                <tr>
                                  <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre
                                    del
                                    documento</th>
                                  <th scope="col"></th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr *ngFor="let dato of docs24, let i = index">
                                  <td><input type="checkbox" (change)="checkCheckBoxvalue24($event, i, dato?.ID)"
                                      *ngIf="boton === 1" [checked]="todo24">
                                    <img src="assets/img/docs.png" alt="" height="30" width="30">{{dato?.NOMBRE}}
                                    <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;"> - Abrir</a>
                                  </td>
                                  <td>
                                    <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                      class="btn btn-outline-dark">Eliminar</button>
                                  </td>

                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>








                </div>
              </div>
            </div>


            <div class="panel panel-default">
              <div class="panel-heading">
                <h4 class="panel-title">
                  <button (click)="area13()" data-bs-toggle="collapse" data-bs-parent="#accordion1" href="#a51"
                    style="background: none; border: none;">
                    <i class="bi bi-folder-fill">Coordinación Nutricional</i></button>
                </h4>
              </div>

              <div id="a51" class="panel-collapse collapse in" data-bs-parent="#accordion1">
                <div class="panel-group" id="accordion51" style=" margin-left: 20px;">

                  <div class="panel panel-default">
                    <div class="panel-heading">
                      <h4 class="panel-title">
                        <button (click)="a82018()" data-bs-toggle="collapse" data-bs-parent="#accordion51"
                          href="#collapse21001" style="background: none; border: none;">
                          <i class="bi bi-folder-fill">2018</i></button>
                      </h4>
                    </div>
                    <div id="collapse21001" class="panel-collapse collapse in" data-bs-parent="#accordion51">
              <div class="panel-body">
                <div style="margin: auto; width: 80%; height: 70px; display: flex; justify-content: right;" *ngIf="boton === 1">
                  <button style="height: 38px;" (click)="seleccionarTodo18()" type="button"
                  class="btn btn-outline-dark">Seleccionar Todo</button>
                  <button style="height: 38px;  margin-left: 10px;" (click)="quitarSeleccion18()" type="button"
                  class="btn btn-outline-dark">Desahacer Selección</button>
                  <button style="height: 38px; margin-left: 10px;"  (click)="eliminarSeleccionados()" type="button"
                  class="btn btn-outline-dark">Eliminar Seleccionados</button>
                </div>
                <div class="fondo">

                  <div id="scroll">
                    <table class="table table-striped">
                      <thead>
                        <tr>
                          <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre del
                            documento</th>
                          <th scope="col"></th>
                        </tr>
                      </thead>

                      <tbody>
                        <tr *ngFor="let dato of docs18, let i = index">
                          <td><input type="checkbox" (change)="checkCheckBoxvalue18($event, i, dato?.ID)" *ngIf="boton === 1" [checked]="todo18">
                            <img src="assets/img/docs.png" alt="" height="30" width="30">{{dato?.NOMBRE}}
                            <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;"> - Abrir</a>
                          </td>

                          <td>
                            <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                              class="btn btn-outline-dark">Eliminar</button>
                          </td>

                        </tr>
                      </tbody>
                    </table>

                  </div>
                </div>
              </div>
                    </div>
                  </div>



                  <div class="panel panel-default">
                    <div class="panel-heading">
                      <h4 class="panel-title">
                        <button (click)="a82019()" data-bs-toggle="collapse" data-bs-parent="#accordion51"
                          href="#collapse220011" style="background: none; border: none;">
                          <i class="bi bi-folder-fill">2019</i></button>
                      </h4>
                    </div>
                    <div id="collapse220011" class="panel-collapse collapse in" data-bs-parent="#accordion51">
            <div class="panel-body">
              <div style="margin: auto; width: 80%; height: 70px; display: flex; justify-content: right;" *ngIf="boton === 1">
                <button style="height: 38px;" (click)="seleccionarTodo19()" type="button"
                class="btn btn-outline-dark">Seleccionar Todo</button>
                <button style="height: 38px;  margin-left: 10px;" (click)="quitarSeleccion19()" type="button"
                class="btn btn-outline-dark">Desahacer Selección</button>
                <button style="height: 38px; margin-left: 10px;"  (click)="eliminarSeleccionados()" type="button"
                class="btn btn-outline-dark">Eliminar Seleccionados</button>
              </div>
              <div class="fondo">

                <div id="scroll">
                  <table class="table table-striped">
                    <thead>
                      <tr>
                        <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre del
                          documento</th>
                        <th scope="col"></th>
                      </tr>
                    </thead>

                    <tbody>
                      <tr *ngFor="let dato of docs19, let i = index">
                        <td><input type="checkbox" (change)="checkCheckBoxvalue19($event, i, dato?.ID)" *ngIf="boton === 1" [checked]="todo19">
                          <img src="assets/img/docs.png" alt="" height="30" width="30">{{dato?.NOMBRE}}
                          <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;"> - Abrir</a>
                        </td>
                        <td>
                          <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                            class="btn btn-outline-dark">Eliminar</button>
                        </td>

                      </tr>

                    </tbody>
                  </table>

                </div>
              </div>
            </div>
                    </div>
                  </div>


                  <div class="panel panel-default">
                    <div class="panel-heading">
                      <h4 class="panel-title">
                        <button (click)="a82020()" data-bs-toggle="collapse" data-bs-parent="#accordion51"
                          href="#collapse230011" style="background: none; border: none;">
                          <i class="bi bi-folder-fill">2020</i></button>
                      </h4>
                    </div>
                    <div id="collapse230011" class="panel-collapse collapse in" data-bs-parent="#accordion51">
            <div class="panel-body">
              <div style="margin: auto; width: 80%; height: 70px; display: flex; justify-content: right;" *ngIf="boton === 1">
                <button style="height: 38px;" (click)="seleccionarTodo20()" type="button"
                class="btn btn-outline-dark">Seleccionar Todo</button>
                <button style="height: 38px;  margin-left: 10px;" (click)="quitarSeleccion20()" type="button"
                class="btn btn-outline-dark">Desahacer Selección</button>
                <button style="height: 38px; margin-left: 10px;"  (click)="eliminarSeleccionados()" type="button"
                class="btn btn-outline-dark">Eliminar Seleccionados</button>
              </div>
              <div class="fondo">

                <div id="scroll">
                  <table class="table table-striped">
                    <thead>
                      <tr>
                        <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre del
                          documento</th>
                        <th scope="col"></th>
                      </tr>
                    </thead>

                    <tbody>
                      <tr *ngFor="let dato of docs20, let i = index">
                        <td><input type="checkbox" (change)="checkCheckBoxvalue20($event, i, dato?.ID)" *ngIf="boton === 1" [checked]="todo20">
                          <img src="assets/img/docs.png" alt="" height="30" width="30">{{dato?.NOMBRE}}
                          <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;"> - Abrir</a>
                        </td>
                        <td>
                          <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                            class="btn btn-outline-dark">Eliminar</button>
                        </td>

                      </tr>
                    </tbody>
                  </table>

                </div>
              </div>
            </div>
                    </div>
                  </div>


                  <div class="panel panel-default">
                    <div class="panel-heading">
                      <h4 class="panel-title">
                        <button (click)="a82021()" data-bs-toggle="collapse" data-bs-parent="#accordion51"
                          href="#collapse240011" style="background: none; border: none;">
                          <i class="bi bi-folder-fill">2021</i></button>
                      </h4>
                    </div>
                    <div id="collapse240011" class="panel-collapse collapse in" data-bs-parent="#accordion51">
            <div class="panel-body">
              <div style="margin: auto; width: 80%; height: 70px; display: flex; justify-content: right;" *ngIf="boton === 1">
                <button style="height: 38px;" (click)="seleccionarTodo21()" type="button"
                class="btn btn-outline-dark">Seleccionar Todo</button>
                <button style="height: 38px;  margin-left: 10px;" (click)="quitarSeleccion21()" type="button"
                class="btn btn-outline-dark">Desahacer Selección</button>
                <button style="height: 38px; margin-left: 10px;"  (click)="eliminarSeleccionados()" type="button"
                class="btn btn-outline-dark">Eliminar Seleccionados</button>
              </div>
              <div class="fondo">

                <div id="scroll">
                  <table class="table table-striped">
                    <thead>
                      <tr>
                        <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre del
                          documento</th>
                        <th scope="col"></th>
                      </tr>
                    </thead>

                    <tbody>
                      <tr *ngFor="let dato of docs21, let i = index">
                        <td><input type="checkbox" (change)="checkCheckBoxvalue21($event, i, dato?.ID)" *ngIf="boton === 1" [checked]="todo21">
                          <img src="assets/img/docs.png" alt="" height="30" width="30">{{dato?.NOMBRE}}
                          <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;"> - Abrir</a>
                        </td>
                        <td>
                          <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                            class="btn btn-outline-dark">Eliminar</button>
                        </td>

                      </tr>
                    </tbody>
                  </table>

                </div>
              </div>
            </div>
                    </div>
                  </div>


                  <div class="panel panel-default">
                    <div class="panel-heading">
                      <h4 class="panel-title">
                        <button (click)="a82022()" data-bs-toggle="collapse" data-bs-parent="#accordion51"
                          href="#collapse250011" style="background: none; border: none;">
                          <i class="bi bi-folder-fill">2022</i></button>
                      </h4>
                    </div>
                    <div id="collapse250011" class="panel-collapse collapse in" data-bs-parent="#accordion51">
            <div class="panel-body">
              <div style="margin: auto; width: 80%; height: 70px; display: flex; justify-content: right;" *ngIf="boton === 1">
                <button style="height: 38px;" (click)="seleccionarTodo22()" type="button"
                class="btn btn-outline-dark">Seleccionar Todo</button>
                <button style="height: 38px;  margin-left: 10px;" (click)="quitarSeleccion22()" type="button"
                class="btn btn-outline-dark">Desahacer Selección</button>
                <button style="height: 38px; margin-left: 10px;"  (click)="eliminarSeleccionados()" type="button"
                class="btn btn-outline-dark">Eliminar Seleccionados</button>
              </div>
              <div class="fondo">

                <div id="scroll">
                  <table class="table table-striped">
                    <thead>
                      <tr>
                        <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre del
                          documento</th>
                        <th scope="col"></th>
                      </tr>
                    </thead>

                    <tbody>
                      <tr *ngFor="let dato of docs22, let i = index">
                        <td><input type="checkbox" (change)="checkCheckBoxvalue22($event, i, dato?.ID)" *ngIf="boton === 1" [checked]="todo22">
                          <img src="assets/img/docs.png" alt="" height="30" width="30">{{dato?.NOMBRE}}
                          <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;"> - Abrir</a>
                        </td>
                        <td>
                          <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                            class="btn btn-outline-dark">Eliminar</button>
                        </td>

                      </tr>
                    </tbody>
                  </table>

                </div>
              </div>
            </div>
                    </div>
                  </div>

                  <div class="panel panel-default">
                    <div class="panel-heading">
                      <h4 class="panel-title">
                        <button (click)="a82023()" data-bs-toggle="collapse" data-bs-parent="#accordion51"
                          href="#collapse212351" style="background: none; border: none;">
                          <i class="bi bi-folder-fill">2023</i></button>
                      </h4>
                    </div>
                    <div id="collapse212351" class="panel-collapse collapse in" data-bs-parent="#accordion51">
                      <div class="panel-body">
                        <div style="margin: auto; width: 80%; height: 70px; display: flex; justify-content: right;"
                          *ngIf="boton === 1">
                          <button style="height: 38px;" (click)="seleccionarTodo23()" type="button"
                            class="btn btn-outline-dark">Seleccionar Todo</button>
                          <button style="height: 38px;  margin-left: 10px;" (click)="quitarSeleccion23()" type="button"
                            class="btn btn-outline-dark">Desahacer Selección</button>
                          <button style="height: 38px; margin-left: 10px;" (click)="eliminarSeleccionados()"
                            type="button" class="btn btn-outline-dark">Eliminar Seleccionados</button>
                        </div>
                        <div class="fondo">

                          <div id="scroll">
                            <table class="table table-striped">
                              <thead>
                                <tr>
                                  <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre
                                    del
                                    documento</th>
                                  <th scope="col"></th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr *ngFor="let dato of docs23, let i = index">
                                  <td><input type="checkbox" (change)="checkCheckBoxvalue23($event, i, dato?.ID)"
                                      *ngIf="boton === 1" [checked]="todo23">
                                    <img src="assets/img/docs.png" alt="" height="30" width="30">{{dato?.NOMBRE}}
                                    <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;"> - Abrir</a>
                                  </td>
                                  <td>
                                    <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                      class="btn btn-outline-dark">Eliminar</button>
                                  </td>

                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

   <div class="panel panel-default">
                    <div class="panel-heading">
                      <h4 class="panel-title">
                        <button (click)="a82024()" data-bs-toggle="collapse" data-bs-parent="#accordion51"
                          href="#collapse212451" style="background: none; border: none;">
                          <i class="bi bi-folder-fill">2024</i></button>
                      </h4>
                    </div>
                    <div id="collapse212451" class="panel-collapse collapse in" data-bs-parent="#accordion51">
                      <div class="panel-body">
                        <div style="margin: auto; width: 80%; height: 70px; display: flex; justify-content: right;"
                          *ngIf="boton === 1">
                          <button style="height: 38px;" (click)="seleccionarTodo24()" type="button"
                            class="btn btn-outline-dark">Seleccionar Todo</button>
                          <button style="height: 38px;  margin-left: 10px;" (click)="quitarSeleccion24()" type="button"
                            class="btn btn-outline-dark">Desahacer Selección</button>
                          <button style="height: 38px; margin-left: 10px;" (click)="eliminarSeleccionados()"
                            type="button" class="btn btn-outline-dark">Eliminar Seleccionados</button>
                        </div>
                        <div class="fondo">

                          <div id="scroll">
                            <table class="table table-striped">
                              <thead>
                                <tr>
                                  <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre
                                    del
                                    documento</th>
                                  <th scope="col"></th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr *ngFor="let dato of docs24, let i = index">
                                  <td><input type="checkbox" (change)="checkCheckBoxvalue24($event, i, dato?.ID)"
                                      *ngIf="boton === 1" [checked]="todo24">
                                    <img src="assets/img/docs.png" alt="" height="30" width="30">{{dato?.NOMBRE}}
                                    <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;"> - Abrir</a>
                                  </td>
                                  <td>
                                    <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                      class="btn btn-outline-dark">Eliminar</button>
                                  </td>

                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>









                </div>
              </div>
            </div>

            <div class="panel panel-default">
              <div class="panel-heading">
                <h4 class="panel-title">
                  <button (click)="area6()" data-bs-toggle="collapse" data-bs-parent="#accordion1" href="#a6"
                    style="background: none; border: none;">
                    <i class="bi bi-folder-fill">Delegación instituciional de la procuradoria de protección de niñas,
                      niños y adolescentes</i></button>
                </h4>
              </div>
              <div id="a6" class="panel-collapse collapse in" data-bs-parent="#accordion1">
                <div class="panel-group" id="accordion6" style=" margin-left: 20px;">

                  <div class="panel panel-default">
                    <div class="panel-heading">
                      <h4 class="panel-title">
                        <button (click)="a82018()" data-bs-toggle="collapse" data-bs-parent="#accordion6"
                          href="#collapse10000" style="background: none; border: none;">
                          <i class="bi bi-folder-fill">2018</i></button>
                      </h4>
                    </div>
                    <div id="collapse10000" class="panel-collapse collapse in" data-bs-parent="#accordion6">
                      <div class="panel-body">
                        <div style="margin: auto; width: 80%; height: 70px; display: flex; justify-content: right;"
                          *ngIf="boton === 1">
                          <button style="height: 38px;" (click)="seleccionarTodo18()" type="button"
                            class="btn btn-outline-dark">Seleccionar Todo</button>
                          <button style="height: 38px;  margin-left: 10px;" (click)="quitarSeleccion18()" type="button"
                            class="btn btn-outline-dark">Desahacer Selección</button>
                          <button style="height: 38px; margin-left: 10px;" (click)="eliminarSeleccionados()"
                            type="button" class="btn btn-outline-dark">Eliminar Seleccionados</button>
                        </div>
                        <div class="fondo">

                          <div id="scroll">
                            <table class="table table-striped">
                              <thead>
                                <tr>
                                  <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre
                                    del
                                    documento</th>
                                  <th scope="col"></th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr *ngFor="let dato of docs18, let i = index">
                                  <td><input type="checkbox" (change)="checkCheckBoxvalue18($event, i, dato?.ID)"
                                      *ngIf="boton === 1" [checked]="todo18">
                                    <img src="assets/img/docs.png" alt="" height="30" width="30">{{dato?.NOMBRE}}
                                    <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;"> - Abrir</a>
                                  </td>

                                  <td>
                                    <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                      class="btn btn-outline-dark">Eliminar</button>
                                  </td>

                                </tr>
                              </tbody>
                            </table>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>







                  <div class="panel panel-default">
                    <div class="panel-heading">
                      <h4 class="panel-title">
                        <button (click)="a82019()" data-bs-toggle="collapse" data-bs-parent="#accordion6"
                          href="#collapse20000" style="background: none; border: none;">
                          <i class="bi bi-folder-fill">2019</i></button>
                      </h4>
                    </div>
                    <div id="collapse20000" class="panel-collapse collapse in" data-bs-parent="#accordion6">
                      <div class="panel-body">
                        <div style="margin: auto; width: 80%; height: 70px; display: flex; justify-content: right;"
                          *ngIf="boton === 1">
                          <button style="height: 38px;" (click)="seleccionarTodo19()" type="button"
                            class="btn btn-outline-dark">Seleccionar Todo</button>
                          <button style="height: 38px;  margin-left: 10px;" (click)="quitarSeleccion19()" type="button"
                            class="btn btn-outline-dark">Desahacer Selección</button>
                          <button style="height: 38px; margin-left: 10px;" (click)="eliminarSeleccionados()"
                            type="button" class="btn btn-outline-dark">Eliminar Seleccionados</button>
                        </div>
                        <div class="fondo">

                          <div id="scroll">
                            <table class="table table-striped">
                              <thead>
                                <tr>
                                  <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre
                                    del
                                    documento</th>
                                  <th scope="col"></th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr *ngFor="let dato of docs19, let i = index">
                                  <td><input type="checkbox" (change)="checkCheckBoxvalue19($event, i, dato?.ID)"
                                      *ngIf="boton === 1" [checked]="todo19">
                                    <img src="assets/img/docs.png" alt="" height="30" width="30">{{dato?.NOMBRE}}
                                    <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;"> - Abrir</a>
                                  </td>
                                  <td>
                                    <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                      class="btn btn-outline-dark">Eliminar</button>
                                  </td>

                                </tr>

                              </tbody>
                            </table>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>


                  <div class="panel panel-default">
                    <div class="panel-heading">
                      <h4 class="panel-title">
                        <button (click)="a82020()" data-bs-toggle="collapse" data-bs-parent="#accordion6"
                          href="#collapse30000" style="background: none; border: none;">
                          <i class="bi bi-folder-fill">2020</i></button>
                      </h4>
                    </div>
                    <div id="collapse30000" class="panel-collapse collapse in" data-bs-parent="#accordion6">
                      <div class="panel-body">
                        <div style="margin: auto; width: 80%; height: 70px; display: flex; justify-content: right;"
                          *ngIf="boton === 1">
                          <button style="height: 38px;" (click)="seleccionarTodo20()" type="button"
                            class="btn btn-outline-dark">Seleccionar Todo</button>
                          <button style="height: 38px;  margin-left: 10px;" (click)="quitarSeleccion20()" type="button"
                            class="btn btn-outline-dark">Desahacer Selección</button>
                          <button style="height: 38px; margin-left: 10px;" (click)="eliminarSeleccionados()"
                            type="button" class="btn btn-outline-dark">Eliminar Seleccionados</button>
                        </div>
                        <div class="fondo">

                          <div id="scroll">
                            <table class="table table-striped">
                              <thead>
                                <tr>
                                  <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre
                                    del
                                    documento</th>
                                  <th scope="col"></th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr *ngFor="let dato of docs20, let i = index">
                                  <td><input type="checkbox" (change)="checkCheckBoxvalue20($event, i, dato?.ID)"
                                      *ngIf="boton === 1" [checked]="todo20">
                                    <img src="assets/img/docs.png" alt="" height="30" width="30">{{dato?.NOMBRE}}
                                    <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;"> - Abrir</a>
                                  </td>
                                  <td>
                                    <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                      class="btn btn-outline-dark">Eliminar</button>
                                  </td>

                                </tr>
                              </tbody>
                            </table>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>


                  <div class="panel panel-default">
                    <div class="panel-heading">
                      <h4 class="panel-title">
                        <button (click)="a82021()" data-bs-toggle="collapse" data-bs-parent="#accordion6"
                          href="#collapse40000" style="background: none; border: none;">
                          <i class="bi bi-folder-fill">2021</i></button>
                      </h4>
                    </div>
                    <div id="collapse40000" class="panel-collapse collapse in" data-bs-parent="#accordion6">
                      <div class="panel-body">
                        <div style="margin: auto; width: 80%; height: 70px; display: flex; justify-content: right;"
                          *ngIf="boton === 1">
                          <button style="height: 38px;" (click)="seleccionarTodo21()" type="button"
                            class="btn btn-outline-dark">Seleccionar Todo</button>
                          <button style="height: 38px;  margin-left: 10px;" (click)="quitarSeleccion21()" type="button"
                            class="btn btn-outline-dark">Desahacer Selección</button>
                          <button style="height: 38px; margin-left: 10px;" (click)="eliminarSeleccionados()"
                            type="button" class="btn btn-outline-dark">Eliminar Seleccionados</button>
                        </div>
                        <div class="fondo">

                          <div id="scroll">
                            <table class="table table-striped">
                              <thead>
                                <tr>
                                  <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre
                                    del
                                    documento</th>
                                  <th scope="col"></th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr *ngFor="let dato of docs21, let i = index">
                                  <td><input type="checkbox" (change)="checkCheckBoxvalue21($event, i, dato?.ID)"
                                      *ngIf="boton === 1" [checked]="todo21">
                                    <img src="assets/img/docs.png" alt="" height="30" width="30">{{dato?.NOMBRE}}
                                    <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;"> - Abrir</a>
                                  </td>
                                  <td>
                                    <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                      class="btn btn-outline-dark">Eliminar</button>
                                  </td>

                                </tr>
                              </tbody>
                            </table>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>


                  <div class="panel panel-default">
                    <div class="panel-heading">
                      <h4 class="panel-title">
                        <button (click)="a82022()" data-bs-toggle="collapse" data-bs-parent="#accordion6"
                          href="#collapse50000" style="background: none; border: none;">
                          <i class="bi bi-folder-fill">2022</i></button>
                      </h4>
                    </div>
                    <div id="collapse50000" class="panel-collapse collapse in" data-bs-parent="#accordion6">
                      <div class="panel-body">
                        <div style="margin: auto; width: 80%; height: 70px; display: flex; justify-content: right;"
                          *ngIf="boton === 1">
                          <button style="height: 38px;" (click)="seleccionarTodo22()" type="button"
                            class="btn btn-outline-dark">Seleccionar Todo</button>
                          <button style="height: 38px;  margin-left: 10px;" (click)="quitarSeleccion22()" type="button"
                            class="btn btn-outline-dark">Desahacer Selección</button>
                          <button style="height: 38px; margin-left: 10px;" (click)="eliminarSeleccionados()"
                            type="button" class="btn btn-outline-dark">Eliminar Seleccionados</button>
                        </div>
                        <div class="fondo">

                          <div id="scroll">
                            <table class="table table-striped">
                              <thead>
                                <tr>
                                  <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre
                                    del
                                    documento</th>
                                  <th scope="col"></th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr *ngFor="let dato of docs22, let i = index">
                                  <td><input type="checkbox" (change)="checkCheckBoxvalue22($event, i, dato?.ID)"
                                      *ngIf="boton === 1" [checked]="todo22">
                                    <img src="assets/img/docs.png" alt="" height="30" width="30">{{dato?.NOMBRE}}
                                    <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;"> - Abrir</a>
                                  </td>
                                  <td>
                                    <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                      class="btn btn-outline-dark">Eliminar</button>
                                  </td>

                                </tr>
                              </tbody>
                            </table>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="panel panel-default">
                    <div class="panel-heading">
                      <h4 class="panel-title">
                        <button (click)="a82023()" data-bs-toggle="collapse" data-bs-parent="#accordion6"
                          href="#collapse21236" style="background: none; border: none;">
                          <i class="bi bi-folder-fill">2023</i></button>
                      </h4>
                    </div>
                    <div id="collapse21236" class="panel-collapse collapse in" data-bs-parent="#accordion6">
                      <div class="panel-body">
                        <div style="margin: auto; width: 80%; height: 70px; display: flex; justify-content: right;"
                          *ngIf="boton === 1">
                          <button style="height: 38px;" (click)="seleccionarTodo23()" type="button"
                            class="btn btn-outline-dark">Seleccionar Todo</button>
                          <button style="height: 38px;  margin-left: 10px;" (click)="quitarSeleccion23()" type="button"
                            class="btn btn-outline-dark">Desahacer Selección</button>
                          <button style="height: 38px; margin-left: 10px;" (click)="eliminarSeleccionados()"
                            type="button" class="btn btn-outline-dark">Eliminar Seleccionados</button>
                        </div>
                        <div class="fondo">

                          <div id="scroll">
                            <table class="table table-striped">
                              <thead>
                                <tr>
                                  <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre
                                    del
                                    documento</th>
                                  <th scope="col"></th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr *ngFor="let dato of docs23, let i = index">
                                  <td><input type="checkbox" (change)="checkCheckBoxvalue23($event, i, dato?.ID)"
                                      *ngIf="boton === 1" [checked]="todo23">
                                    <img src="assets/img/docs.png" alt="" height="30" width="30">{{dato?.NOMBRE}}
                                    <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;"> - Abrir</a>
                                  </td>
                                  <td>
                                    <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                      class="btn btn-outline-dark">Eliminar</button>
                                  </td>

                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>


                  <div class="panel panel-default">
                    <div class="panel-heading">
                      <h4 class="panel-title">
                        <button (click)="a82024()" data-bs-toggle="collapse" data-bs-parent="#accordion6"
                          href="#collapse21246" style="background: none; border: none;">
                          <i class="bi bi-folder-fill">2024</i></button>
                      </h4>
                    </div>
                    <div id="collapse21246" class="panel-collapse collapse in" data-bs-parent="#accordion6">
                      <div class="panel-body">
                        <div style="margin: auto; width: 80%; height: 70px; display: flex; justify-content: right;"
                          *ngIf="boton === 1">
                          <button style="height: 38px;" (click)="seleccionarTodo24()" type="button"
                            class="btn btn-outline-dark">Seleccionar Todo</button>
                          <button style="height: 38px;  margin-left: 10px;" (click)="quitarSeleccion24()" type="button"
                            class="btn btn-outline-dark">Desahacer Selección</button>
                          <button style="height: 38px; margin-left: 10px;" (click)="eliminarSeleccionados()"
                            type="button" class="btn btn-outline-dark">Eliminar Seleccionados</button>
                        </div>
                        <div class="fondo">

                          <div id="scroll">
                            <table class="table table-striped">
                              <thead>
                                <tr>
                                  <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre
                                    del
                                    documento</th>
                                  <th scope="col"></th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr *ngFor="let dato of docs24, let i = index">
                                  <td><input type="checkbox" (change)="checkCheckBoxvalue24($event, i, dato?.ID)"
                                      *ngIf="boton === 1" [checked]="todo24">
                                    <img src="assets/img/docs.png" alt="" height="30" width="30">{{dato?.NOMBRE}}
                                    <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;"> - Abrir</a>
                                  </td>
                                  <td>
                                    <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                      class="btn btn-outline-dark">Eliminar</button>
                                  </td>

                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>









                </div>
              </div>
            </div>
            <div class="panel panel-default">
              <div class="panel-heading">
                <h4 class="panel-title">
                  <button (click)="area7()" data-bs-toggle="collapse" data-bs-parent="#accordion1" href="#a7"
                    style="background: none; border: none;">
                    <i class="bi bi-folder-fill">Dirección</i></button>
                </h4>
              </div>
              <div id="a7" class="panel-collapse collapse in" data-bs-parent="#accordion1">
                <div class="panel-group" id="accordion7" style=" margin-left: 20px;">

                  <div class="panel panel-default">
                    <div class="panel-heading">
                      <h4 class="panel-title">
                        <button (click)="a82018()" data-bs-toggle="collapse" data-bs-parent="#accordion7"
                          href="#collapse26" style="background: none; border: none;">
                          <i class="bi bi-folder-fill">2018</i></button>
                      </h4>
                    </div>
                    <div id="collapse26" class="panel-collapse collapse in" data-bs-parent="#accordion7">
                      <div class="panel-body">
                        <div style="margin: auto; width: 80%; height: 70px; display: flex; justify-content: right;"
                          *ngIf="boton === 1">
                          <button style="height: 38px;" (click)="seleccionarTodo18()" type="button"
                            class="btn btn-outline-dark">Seleccionar Todo</button>
                          <button style="height: 38px;  margin-left: 10px;" (click)="quitarSeleccion18()" type="button"
                            class="btn btn-outline-dark">Desahacer Selección</button>
                          <button style="height: 38px; margin-left: 10px;" (click)="eliminarSeleccionados()"
                            type="button" class="btn btn-outline-dark">Eliminar Seleccionados</button>
                        </div>
                        <div class="fondo">

                          <div id="scroll">
                            <table class="table table-striped">
                              <thead>
                                <tr>
                                  <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre
                                    del
                                    documento</th>
                                  <th scope="col"></th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr *ngFor="let dato of docs18, let i = index">
                                  <td><input type="checkbox" (change)="checkCheckBoxvalue18($event, i, dato?.ID)"
                                      *ngIf="boton === 1" [checked]="todo18">
                                    <img src="assets/img/docs.png" alt="" height="30" width="30">{{dato?.NOMBRE}}
                                    <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;"> - Abrir</a>
                                  </td>

                                  <td>
                                    <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                      class="btn btn-outline-dark">Eliminar</button>
                                  </td>

                                </tr>
                              </tbody>
                            </table>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>



                  <div class="panel panel-default">
                    <div class="panel-heading">
                      <h4 class="panel-title">
                        <button (click)="a82019()" data-bs-toggle="collapse" data-bs-parent="#accordion7"
                          href="#collapse27" style="background: none; border: none;">
                          <i class="bi bi-folder-fill">2019</i></button>
                      </h4>
                    </div>
                    <div id="collapse27" class="panel-collapse collapse in" data-bs-parent="#accordion7">
                      <div class="panel-body">
                        <div style="margin: auto; width: 80%; height: 70px; display: flex; justify-content: right;"
                          *ngIf="boton === 1">
                          <button style="height: 38px;" (click)="seleccionarTodo19()" type="button"
                            class="btn btn-outline-dark">Seleccionar Todo</button>
                          <button style="height: 38px;  margin-left: 10px;" (click)="quitarSeleccion19()" type="button"
                            class="btn btn-outline-dark">Desahacer Selección</button>
                          <button style="height: 38px; margin-left: 10px;" (click)="eliminarSeleccionados()"
                            type="button" class="btn btn-outline-dark">Eliminar Seleccionados</button>
                        </div>
                        <div class="fondo">

                          <div id="scroll">
                            <table class="table table-striped">
                              <thead>
                                <tr>
                                  <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre
                                    del
                                    documento</th>
                                  <th scope="col"></th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr *ngFor="let dato of docs19, let i = index">
                                  <td><input type="checkbox" (change)="checkCheckBoxvalue19($event, i, dato?.ID)"
                                      *ngIf="boton === 1" [checked]="todo19">
                                    <img src="assets/img/docs.png" alt="" height="30" width="30">{{dato?.NOMBRE}}
                                    <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;"> - Abrir</a>
                                  </td>
                                  <td>
                                    <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                      class="btn btn-outline-dark">Eliminar</button>
                                  </td>

                                </tr>

                              </tbody>
                            </table>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>


                  <div class="panel panel-default">
                    <div class="panel-heading">
                      <h4 class="panel-title">
                        <button (click)="a82020()" data-bs-toggle="collapse" data-bs-parent="#accordion7"
                          href="#collapse37" style="background: none; border: none;">
                          <i class="bi bi-folder-fill">2020</i></button>
                      </h4>
                    </div>
                    <div id="collapse37" class="panel-collapse collapse in" data-bs-parent="#accordion7">
                      <div class="panel-body">
                        <div style="margin: auto; width: 80%; height: 70px; display: flex; justify-content: right;"
                          *ngIf="boton === 1">
                          <button style="height: 38px;" (click)="seleccionarTodo20()" type="button"
                            class="btn btn-outline-dark">Seleccionar Todo</button>
                          <button style="height: 38px;  margin-left: 10px;" (click)="quitarSeleccion20()" type="button"
                            class="btn btn-outline-dark">Desahacer Selección</button>
                          <button style="height: 38px; margin-left: 10px;" (click)="eliminarSeleccionados()"
                            type="button" class="btn btn-outline-dark">Eliminar Seleccionados</button>
                        </div>
                        <div class="fondo">

                          <div id="scroll">
                            <table class="table table-striped">
                              <thead>
                                <tr>
                                  <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre
                                    del
                                    documento</th>
                                  <th scope="col"></th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr *ngFor="let dato of docs20, let i = index">
                                  <td><input type="checkbox" (change)="checkCheckBoxvalue20($event, i, dato?.ID)"
                                      *ngIf="boton === 1" [checked]="todo20">
                                    <img src="assets/img/docs.png" alt="" height="30" width="30">{{dato?.NOMBRE}}
                                    <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;"> - Abrir</a>
                                  </td>
                                  <td>
                                    <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                      class="btn btn-outline-dark">Eliminar</button>
                                  </td>

                                </tr>
                              </tbody>
                            </table>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>


                  <div class="panel panel-default">
                    <div class="panel-heading">
                      <h4 class="panel-title">
                        <button (click)="a82021()" data-bs-toggle="collapse" data-bs-parent="#accordion7"
                          href="#collapse47" style="background: none; border: none;">
                          <i class="bi bi-folder-fill">2021</i></button>
                      </h4>
                    </div>
                    <div id="collapse47" class="panel-collapse collapse in" data-bs-parent="#accordion7">
                      <div class="panel-body">
                        <div style="margin: auto; width: 80%; height: 70px; display: flex; justify-content: right;"
                          *ngIf="boton === 1">
                          <button style="height: 38px;" (click)="seleccionarTodo21()" type="button"
                            class="btn btn-outline-dark">Seleccionar Todo</button>
                          <button style="height: 38px;  margin-left: 10px;" (click)="quitarSeleccion21()" type="button"
                            class="btn btn-outline-dark">Desahacer Selección</button>
                          <button style="height: 38px; margin-left: 10px;" (click)="eliminarSeleccionados()"
                            type="button" class="btn btn-outline-dark">Eliminar Seleccionados</button>
                        </div>
                        <div class="fondo">

                          <div id="scroll">
                            <table class="table table-striped">
                              <thead>
                                <tr>
                                  <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre
                                    del
                                    documento</th>
                                  <th scope="col"></th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr *ngFor="let dato of docs21, let i = index">
                                  <td><input type="checkbox" (change)="checkCheckBoxvalue21($event, i, dato?.ID)"
                                      *ngIf="boton === 1" [checked]="todo21">
                                    <img src="assets/img/docs.png" alt="" height="30" width="30">{{dato?.NOMBRE}}
                                    <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;"> - Abrir</a>
                                  </td>
                                  <td>
                                    <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                      class="btn btn-outline-dark">Eliminar</button>
                                  </td>

                                </tr>
                              </tbody>
                            </table>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>


                  <div class="panel panel-default">
                    <div class="panel-heading">
                      <h4 class="panel-title">
                        <button (click)="a82022()" data-bs-toggle="collapse" data-bs-parent="#accordion7"
                          href="#collapse57" style="background: none; border: none;">
                          <i class="bi bi-folder-fill">2022</i></button>
                      </h4>
                    </div>
                    <div id="collapse57" class="panel-collapse collapse in" data-bs-parent="#accordion7">
                      <div class="panel-body">
                        <div style="margin: auto; width: 80%; height: 70px; display: flex; justify-content: right;"
                          *ngIf="boton === 1">
                          <button style="height: 38px;" (click)="seleccionarTodo22()" type="button"
                            class="btn btn-outline-dark">Seleccionar Todo</button>
                          <button style="height: 38px;  margin-left: 10px;" (click)="quitarSeleccion22()" type="button"
                            class="btn btn-outline-dark">Desahacer Selección</button>
                          <button style="height: 38px; margin-left: 10px;" (click)="eliminarSeleccionados()"
                            type="button" class="btn btn-outline-dark">Eliminar Seleccionados</button>
                        </div>
                        <div class="fondo">

                          <div id="scroll">
                            <table class="table table-striped">
                              <thead>
                                <tr>
                                  <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre
                                    del
                                    documento</th>
                                  <th scope="col"></th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr *ngFor="let dato of docs22, let i = index">
                                  <td><input type="checkbox" (change)="checkCheckBoxvalue22($event, i, dato?.ID)"
                                      *ngIf="boton === 1" [checked]="todo22">
                                    <img src="assets/img/docs.png" alt="" height="30" width="30">{{dato?.NOMBRE}}
                                    <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;"> - Abrir</a>
                                  </td>
                                  <td>
                                    <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                      class="btn btn-outline-dark">Eliminar</button>
                                  </td>

                                </tr>
                              </tbody>
                            </table>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>


                  <div class="panel panel-default">
                    <div class="panel-heading">
                      <h4 class="panel-title">
                        <button (click)="a82023()" data-bs-toggle="collapse" data-bs-parent="#accordion7"
                          href="#collapse21237" style="background: none; border: none;">
                          <i class="bi bi-folder-fill">2023</i></button>
                      </h4>
                    </div>
                    <div id="collapse21237" class="panel-collapse collapse in" data-bs-parent="#accordion7">
                      <div class="panel-body">
                        <div style="margin: auto; width: 80%; height: 70px; display: flex; justify-content: right;"
                          *ngIf="boton === 1">
                          <button style="height: 38px;" (click)="seleccionarTodo23()" type="button"
                            class="btn btn-outline-dark">Seleccionar Todo</button>
                          <button style="height: 38px;  margin-left: 10px;" (click)="quitarSeleccion23()" type="button"
                            class="btn btn-outline-dark">Desahacer Selección</button>
                          <button style="height: 38px; margin-left: 10px;" (click)="eliminarSeleccionados()"
                            type="button" class="btn btn-outline-dark">Eliminar Seleccionados</button>
                        </div>
                        <div class="fondo">

                          <div id="scroll">
                            <table class="table table-striped">
                              <thead>
                                <tr>
                                  <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre
                                    del
                                    documento</th>
                                  <th scope="col"></th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr *ngFor="let dato of docs23, let i = index">
                                  <td><input type="checkbox" (change)="checkCheckBoxvalue23($event, i, dato?.ID)"
                                      *ngIf="boton === 1" [checked]="todo23">
                                    <img src="assets/img/docs.png" alt="" height="30" width="30">{{dato?.NOMBRE}}
                                    <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;"> - Abrir</a>
                                  </td>
                                  <td>
                                    <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                      class="btn btn-outline-dark">Eliminar</button>
                                  </td>

                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>


  <div class="panel panel-default">
                    <div class="panel-heading">
                      <h4 class="panel-title">
                        <button (click)="a82024()" data-bs-toggle="collapse" data-bs-parent="#accordion7"
                          href="#collapse21247" style="background: none; border: none;">
                          <i class="bi bi-folder-fill">2024</i></button>
                      </h4>
                    </div>
                    <div id="collapse21247" class="panel-collapse collapse in" data-bs-parent="#accordion7">
                      <div class="panel-body">
                        <div style="margin: auto; width: 80%; height: 70px; display: flex; justify-content: right;"
                          *ngIf="boton === 1">
                          <button style="height: 38px;" (click)="seleccionarTodo24()" type="button"
                            class="btn btn-outline-dark">Seleccionar Todo</button>
                          <button style="height: 38px;  margin-left: 10px;" (click)="quitarSeleccion24()" type="button"
                            class="btn btn-outline-dark">Desahacer Selección</button>
                          <button style="height: 38px; margin-left: 10px;" (click)="eliminarSeleccionados()"
                            type="button" class="btn btn-outline-dark">Eliminar Seleccionados</button>
                        </div>
                        <div class="fondo">

                          <div id="scroll">
                            <table class="table table-striped">
                              <thead>
                                <tr>
                                  <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre
                                    del
                                    documento</th>
                                  <th scope="col"></th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr *ngFor="let dato of docs24, let i = index">
                                  <td><input type="checkbox" (change)="checkCheckBoxvalue24($event, i, dato?.ID)"
                                      *ngIf="boton === 1" [checked]="todo24">
                                    <img src="assets/img/docs.png" alt="" height="30" width="30">{{dato?.NOMBRE}}
                                    <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;"> - Abrir</a>
                                  </td>
                                  <td>
                                    <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                      class="btn btn-outline-dark">Eliminar</button>
                                  </td>

                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>







                </div>
              </div>
            </div>
            <div class="panel panel-default">
              <div class="panel-heading">
                <h4 class="panel-title">
                  <button (click)="area8()" data-bs-toggle="collapse" data-bs-parent="#accordion1" href="#a8"
                    style="background: none; border: none;">
                    <i class="bi bi-folder-fill">Recursos Humanos</i></button>
                </h4>
              </div>
              <div id="a8" class="panel-collapse collapse in" data-bs-parent="#accordion1">
                <div class="panel-group" id="accordion8" style=" margin-left: 20px;">

                  <div class="panel panel-default">
                    <div class="panel-heading">
                      <h4 class="panel-title">
                        <button (click)="a82018()" data-bs-toggle="collapse" data-bs-parent="#accordion8"
                          href="#collapse28" style="background: none; border: none;">
                          <i class="bi bi-folder-fill">2018</i></button>
                      </h4>
                    </div>
                    <div id="collapse28" class="panel-collapse collapse in" data-bs-parent="#accordion8">
                      <div class="panel-body">
                        <div style="margin: auto; width: 80%; height: 70px; display: flex; justify-content: right;"
                          *ngIf="boton === 1">
                          <button style="height: 38px;" (click)="seleccionarTodo18()" type="button"
                            class="btn btn-outline-dark">Seleccionar Todo</button>
                          <button style="height: 38px;  margin-left: 10px;" (click)="quitarSeleccion18()" type="button"
                            class="btn btn-outline-dark">Desahacer Selección</button>
                          <button style="height: 38px; margin-left: 10px;" (click)="eliminarSeleccionados()"
                            type="button" class="btn btn-outline-dark">Eliminar Seleccionados</button>
                        </div>
                        <div class="fondo">

                          <div id="scroll">
                            <table class="table table-striped">
                              <thead>
                                <tr>
                                  <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre
                                    del
                                    documento</th>
                                  <th scope="col"></th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr *ngFor="let dato of docs18, let i = index">
                                  <td><input type="checkbox" (change)="checkCheckBoxvalue18($event, i, dato?.ID)"
                                      *ngIf="boton === 1" [checked]="todo18">
                                    <img src="assets/img/docs.png" alt="" height="30" width="30">{{dato?.NOMBRE}}
                                    <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;"> - Abrir</a>
                                  </td>

                                  <td>
                                    <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                      class="btn btn-outline-dark">Eliminar</button>
                                  </td>

                                </tr>
                              </tbody>
                            </table>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>


                  <div class="panel panel-default">
                    <div class="panel-heading">
                      <h4 class="panel-title">
                        <button (click)="a82019()" data-bs-toggle="collapse" data-bs-parent="#accordion8"
                          href="#collapse29" style="background: none; border: none;">
                          <i class="bi bi-folder-fill">2019</i></button>
                      </h4>
                    </div>
                    <div id="collapse29" class="panel-collapse collapse in" data-bs-parent="#accordion8">
                      <div class="panel-body">
                        <div style="margin: auto; width: 80%; height: 70px; display: flex; justify-content: right;"
                          *ngIf="boton === 1">
                          <button style="height: 38px;" (click)="seleccionarTodo19()" type="button"
                            class="btn btn-outline-dark">Seleccionar Todo</button>
                          <button style="height: 38px;  margin-left: 10px;" (click)="quitarSeleccion19()" type="button"
                            class="btn btn-outline-dark">Desahacer Selección</button>
                          <button style="height: 38px; margin-left: 10px;" (click)="eliminarSeleccionados()"
                            type="button" class="btn btn-outline-dark">Eliminar Seleccionados</button>
                        </div>
                        <div class="fondo">

                          <div id="scroll">
                            <table class="table table-striped">
                              <thead>
                                <tr>
                                  <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre
                                    del
                                    documento</th>
                                  <th scope="col"></th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr *ngFor="let dato of docs19, let i = index">
                                  <td><input type="checkbox" (change)="checkCheckBoxvalue19($event, i, dato?.ID)"
                                      *ngIf="boton === 1" [checked]="todo19">
                                    <img src="assets/img/docs.png" alt="" height="30" width="30">{{dato?.NOMBRE}}
                                    <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;"> - Abrir</a>
                                  </td>
                                  <td>
                                    <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                      class="btn btn-outline-dark">Eliminar</button>
                                  </td>

                                </tr>

                              </tbody>
                            </table>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>


                  <div class="panel panel-default">
                    <div class="panel-heading">
                      <h4 class="panel-title">
                        <button (click)="a82020()" data-bs-toggle="collapse" data-bs-parent="#accordion8"
                          href="#collapse39" style="background: none; border: none;">
                          <i class="bi bi-folder-fill">2020</i></button>
                      </h4>
                    </div>
                    <div id="collapse39" class="panel-collapse collapse in" data-bs-parent="#accordion8">
                      <div class="panel-body">
                        <div style="margin: auto; width: 80%; height: 70px; display: flex; justify-content: right;"
                          *ngIf="boton === 1">
                          <button style="height: 38px;" (click)="seleccionarTodo20()" type="button"
                            class="btn btn-outline-dark">Seleccionar Todo</button>
                          <button style="height: 38px;  margin-left: 10px;" (click)="quitarSeleccion20()" type="button"
                            class="btn btn-outline-dark">Desahacer Selección</button>
                          <button style="height: 38px; margin-left: 10px;" (click)="eliminarSeleccionados()"
                            type="button" class="btn btn-outline-dark">Eliminar Seleccionados</button>
                        </div>
                        <div class="fondo">

                          <div id="scroll">
                            <table class="table table-striped">
                              <thead>
                                <tr>
                                  <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre
                                    del
                                    documento</th>
                                  <th scope="col"></th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr *ngFor="let dato of docs20, let i = index">
                                  <td><input type="checkbox" (change)="checkCheckBoxvalue20($event, i, dato?.ID)"
                                      *ngIf="boton === 1" [checked]="todo20">
                                    <img src="assets/img/docs.png" alt="" height="30" width="30">{{dato?.NOMBRE}}
                                    <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;"> - Abrir</a>
                                  </td>
                                  <td>
                                    <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                      class="btn btn-outline-dark">Eliminar</button>
                                  </td>

                                </tr>
                              </tbody>
                            </table>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>


                  <div class="panel panel-default">
                    <div class="panel-heading">
                      <h4 class="panel-title">
                        <button (click)="a82021()" data-bs-toggle="collapse" data-bs-parent="#accordion8"
                          href="#collapse49" style="background: none; border: none;">
                          <i class="bi bi-folder-fill">2021</i></button>
                      </h4>
                    </div>
                    <div id="collapse49" class="panel-collapse collapse in" data-bs-parent="#accordion8">
                      <div class="panel-body">
                        <div style="margin: auto; width: 80%; height: 70px; display: flex; justify-content: right;"
                          *ngIf="boton === 1">
                          <button style="height: 38px;" (click)="seleccionarTodo21()" type="button"
                            class="btn btn-outline-dark">Seleccionar Todo</button>
                          <button style="height: 38px;  margin-left: 10px;" (click)="quitarSeleccion21()" type="button"
                            class="btn btn-outline-dark">Desahacer Selección</button>
                          <button style="height: 38px; margin-left: 10px;" (click)="eliminarSeleccionados()"
                            type="button" class="btn btn-outline-dark">Eliminar Seleccionados</button>
                        </div>
                        <div class="fondo">

                          <div id="scroll">
                            <table class="table table-striped">
                              <thead>
                                <tr>
                                  <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre
                                    del
                                    documento</th>
                                  <th scope="col"></th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr *ngFor="let dato of docs21, let i = index">
                                  <td><input type="checkbox" (change)="checkCheckBoxvalue21($event, i, dato?.ID)"
                                      *ngIf="boton === 1" [checked]="todo21">
                                    <img src="assets/img/docs.png" alt="" height="30" width="30">{{dato?.NOMBRE}}
                                    <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;"> - Abrir</a>
                                  </td>
                                  <td>
                                    <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                      class="btn btn-outline-dark">Eliminar</button>
                                  </td>

                                </tr>
                              </tbody>
                            </table>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>


                  <div class="panel panel-default">
                    <div class="panel-heading">
                      <h4 class="panel-title">
                        <button (click)="a82022()" data-bs-toggle="collapse" data-bs-parent="#accordion8"
                          href="#collapse59" style="background: none; border: none;">
                          <i class="bi bi-folder-fill">2022</i></button>
                      </h4>
                    </div>
                    <div id="collapse59" class="panel-collapse collapse in" data-bs-parent="#accordion8">
                      <div class="panel-body">
                        <div style="margin: auto; width: 80%; height: 70px; display: flex; justify-content: right;"
                          *ngIf="boton === 1">
                          <button style="height: 38px;" (click)="seleccionarTodo22()" type="button"
                            class="btn btn-outline-dark">Seleccionar Todo</button>
                          <button style="height: 38px;  margin-left: 10px;" (click)="quitarSeleccion22()" type="button"
                            class="btn btn-outline-dark">Desahacer Selección</button>
                          <button style="height: 38px; margin-left: 10px;" (click)="eliminarSeleccionados()"
                            type="button" class="btn btn-outline-dark">Eliminar Seleccionados</button>
                        </div>
                        <div class="fondo">

                          <div id="scroll">
                            <table class="table table-striped">
                              <thead>
                                <tr>
                                  <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre
                                    del
                                    documento</th>
                                  <th scope="col"></th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr *ngFor="let dato of docs22, let i = index">
                                  <td><input type="checkbox" (change)="checkCheckBoxvalue22($event, i, dato?.ID)"
                                      *ngIf="boton === 1" [checked]="todo22">
                                    <img src="assets/img/docs.png" alt="" height="30" width="30">{{dato?.NOMBRE}}
                                    <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;"> - Abrir</a>
                                  </td>
                                  <td>
                                    <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                      class="btn btn-outline-dark">Eliminar</button>
                                  </td>

                                </tr>
                              </tbody>
                            </table>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>


                  <div class="panel panel-default">
                    <div class="panel-heading">
                      <h4 class="panel-title">
                        <button (click)="a82023()" data-bs-toggle="collapse" data-bs-parent="#accordion8"
                          href="#collapse21238" style="background: none; border: none;">
                          <i class="bi bi-folder-fill">2023</i></button>
                      </h4>
                    </div>
                    <div id="collapse21238" class="panel-collapse collapse in" data-bs-parent="#accordion8">
                      <div class="panel-body">
                        <div style="margin: auto; width: 80%; height: 70px; display: flex; justify-content: right;"
                          *ngIf="boton === 1">
                          <button style="height: 38px;" (click)="seleccionarTodo23()" type="button"
                            class="btn btn-outline-dark">Seleccionar Todo</button>
                          <button style="height: 38px;  margin-left: 10px;" (click)="quitarSeleccion23()" type="button"
                            class="btn btn-outline-dark">Desahacer Selección</button>
                          <button style="height: 38px; margin-left: 10px;" (click)="eliminarSeleccionados()"
                            type="button" class="btn btn-outline-dark">Eliminar Seleccionados</button>
                        </div>
                        <div class="fondo">

                          <div id="scroll">
                            <table class="table table-striped">
                              <thead>
                                <tr>
                                  <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre
                                    del
                                    documento</th>
                                  <th scope="col"></th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr *ngFor="let dato of docs23, let i = index">
                                  <td><input type="checkbox" (change)="checkCheckBoxvalue23($event, i, dato?.ID)"
                                      *ngIf="boton === 1" [checked]="todo23">
                                    <img src="assets/img/docs.png" alt="" height="30" width="30">{{dato?.NOMBRE}}
                                    <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;"> - Abrir</a>
                                  </td>
                                  <td>
                                    <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                      class="btn btn-outline-dark">Eliminar</button>
                                  </td>

                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>


   <div class="panel panel-default">
                    <div class="panel-heading">
                      <h4 class="panel-title">
                        <button (click)="a82024()" data-bs-toggle="collapse" data-bs-parent="#accordion8"
                          href="#collapse21248" style="background: none; border: none;">
                          <i class="bi bi-folder-fill">2024</i></button>
                      </h4>
                    </div>
                    <div id="collapse21248" class="panel-collapse collapse in" data-bs-parent="#accordion8">
                      <div class="panel-body">
                        <div style="margin: auto; width: 80%; height: 70px; display: flex; justify-content: right;"
                          *ngIf="boton === 1">
                          <button style="height: 38px;" (click)="seleccionarTodo24()" type="button"
                            class="btn btn-outline-dark">Seleccionar Todo</button>
                          <button style="height: 38px;  margin-left: 10px;" (click)="quitarSeleccion24()" type="button"
                            class="btn btn-outline-dark">Desahacer Selección</button>
                          <button style="height: 38px; margin-left: 10px;" (click)="eliminarSeleccionados()"
                            type="button" class="btn btn-outline-dark">Eliminar Seleccionados</button>
                        </div>
                        <div class="fondo">

                          <div id="scroll">
                            <table class="table table-striped">
                              <thead>
                                <tr>
                                  <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre
                                    del
                                    documento</th>
                                  <th scope="col"></th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr *ngFor="let dato of docs24, let i = index">
                                  <td><input type="checkbox" (change)="checkCheckBoxvalue24($event, i, dato?.ID)"
                                      *ngIf="boton === 1" [checked]="todo24">
                                    <img src="assets/img/docs.png" alt="" height="30" width="30">{{dato?.NOMBRE}}
                                    <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;"> - Abrir</a>
                                  </td>
                                  <td>
                                    <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                      class="btn btn-outline-dark">Eliminar</button>
                                  </td>

                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>




                </div>
              </div>
            </div>
            <div class="panel panel-default">
              <div class="panel-heading">
                <h4 class="panel-title">
                  <button (click)="area9()" data-bs-toggle="collapse" data-bs-parent="#accordion1" href="#a9"
                    style="background: none; border: none;">
                    <i class="bi bi-folder-fill">Trabajo social</i></button>
                </h4>
              </div>
              <div id="a9" class="panel-collapse collapse in" data-bs-parent="#accordion1">
                <div class="panel-group" id="accordion9" style=" margin-left: 20px;">

                  <div class="panel panel-default">
                    <div class="panel-heading">
                      <h4 class="panel-title">
                        <button (click)="a82018()" data-bs-toggle="collapse" data-bs-parent="#accordion9"
                          href="#collapse30" style="background: none; border: none;">
                          <i class="bi bi-folder-fill">2018</i></button>
                      </h4>
                    </div>
                    <div id="collapse30" class="panel-collapse collapse in" data-bs-parent="#accordion9">
                      <div class="panel-body">
                        <div style="margin: auto; width: 80%; height: 70px; display: flex; justify-content: right;"
                          *ngIf="boton === 1">
                          <button style="height: 38px;" (click)="seleccionarTodo18()" type="button"
                            class="btn btn-outline-dark">Seleccionar Todo</button>
                          <button style="height: 38px;  margin-left: 10px;" (click)="quitarSeleccion18()" type="button"
                            class="btn btn-outline-dark">Desahacer Selección</button>
                          <button style="height: 38px; margin-left: 10px;" (click)="eliminarSeleccionados()"
                            type="button" class="btn btn-outline-dark">Eliminar Seleccionados</button>
                        </div>
                        <div class="fondo">

                          <div id="scroll">
                            <table class="table table-striped">
                              <thead>
                                <tr>
                                  <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre
                                    del
                                    documento</th>
                                  <th scope="col"></th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr *ngFor="let dato of docs18, let i = index">
                                  <td><input type="checkbox" (change)="checkCheckBoxvalue18($event, i, dato?.ID)"
                                      *ngIf="boton === 1" [checked]="todo18">
                                    <img src="assets/img/docs.png" alt="" height="30" width="30">{{dato?.NOMBRE}}
                                    <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;"> - Abrir</a>
                                  </td>

                                  <td>
                                    <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                      class="btn btn-outline-dark">Eliminar</button>
                                  </td>

                                </tr>
                              </tbody>
                            </table>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>


                  <div class="panel panel-default">
                    <div class="panel-heading">
                      <h4 class="panel-title">
                        <button (click)="a82019()" data-bs-toggle="collapse" data-bs-parent="#accordion9"
                          href="#collapse31" style="background: none; border: none;">
                          <i class="bi bi-folder-fill">2019</i></button>
                      </h4>
                    </div>
                    <div id="collapse31" class="panel-collapse collapse in" data-bs-parent="#accordion9">
                      <div class="panel-body">
                        <div style="margin: auto; width: 80%; height: 70px; display: flex; justify-content: right;"
                          *ngIf="boton === 1">
                          <button style="height: 38px;" (click)="seleccionarTodo19()" type="button"
                            class="btn btn-outline-dark">Seleccionar Todo</button>
                          <button style="height: 38px;  margin-left: 10px;" (click)="quitarSeleccion19()" type="button"
                            class="btn btn-outline-dark">Desahacer Selección</button>
                          <button style="height: 38px; margin-left: 10px;" (click)="eliminarSeleccionados()"
                            type="button" class="btn btn-outline-dark">Eliminar Seleccionados</button>
                        </div>
                        <div class="fondo">

                          <div id="scroll">
                            <table class="table table-striped">
                              <thead>
                                <tr>
                                  <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre
                                    del
                                    documento</th>
                                  <th scope="col"></th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr *ngFor="let dato of docs19, let i = index">
                                  <td><input type="checkbox" (change)="checkCheckBoxvalue19($event, i, dato?.ID)"
                                      *ngIf="boton === 1" [checked]="todo19">
                                    <img src="assets/img/docs.png" alt="" height="30" width="30">{{dato?.NOMBRE}}
                                    <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;"> - Abrir</a>
                                  </td>
                                  <td>
                                    <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                      class="btn btn-outline-dark">Eliminar</button>
                                  </td>

                                </tr>

                              </tbody>
                            </table>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>


                  <div class="panel panel-default">
                    <div class="panel-heading">
                      <h4 class="panel-title">
                        <button (click)="a82020()" data-bs-toggle="collapse" data-bs-parent="#accordion9"
                          href="#collapse32" style="background: none; border: none;">
                          <i class="bi bi-folder-fill">2020</i></button>
                      </h4>
                    </div>
                    <div id="collapse32" class="panel-collapse collapse in" data-bs-parent="#accordion9">
                      <div class="panel-body">
                        <div style="margin: auto; width: 80%; height: 70px; display: flex; justify-content: right;"
                          *ngIf="boton === 1">
                          <button style="height: 38px;" (click)="seleccionarTodo20()" type="button"
                            class="btn btn-outline-dark">Seleccionar Todo</button>
                          <button style="height: 38px;  margin-left: 10px;" (click)="quitarSeleccion20()" type="button"
                            class="btn btn-outline-dark">Desahacer Selección</button>
                          <button style="height: 38px; margin-left: 10px;" (click)="eliminarSeleccionados()"
                            type="button" class="btn btn-outline-dark">Eliminar Seleccionados</button>
                        </div>
                        <div class="fondo">

                          <div id="scroll">
                            <table class="table table-striped">
                              <thead>
                                <tr>
                                  <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre
                                    del
                                    documento</th>
                                  <th scope="col"></th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr *ngFor="let dato of docs20, let i = index">
                                  <td><input type="checkbox" (change)="checkCheckBoxvalue20($event, i, dato?.ID)"
                                      *ngIf="boton === 1" [checked]="todo20">
                                    <img src="assets/img/docs.png" alt="" height="30" width="30">{{dato?.NOMBRE}}
                                    <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;"> - Abrir</a>
                                  </td>
                                  <td>
                                    <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                      class="btn btn-outline-dark">Eliminar</button>
                                  </td>

                                </tr>
                              </tbody>
                            </table>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>


                  <div class="panel panel-default">
                    <div class="panel-heading">
                      <h4 class="panel-title">
                        <button (click)="a82021()" data-bs-toggle="collapse" data-bs-parent="#accordion9"
                          href="#collapse33" style="background: none; border: none;">
                          <i class="bi bi-folder-fill">2021</i></button>
                      </h4>
                    </div>
                    <div id="collapse33" class="panel-collapse collapse in" data-bs-parent="#accordion9">
                      <div class="panel-body">
                        <div style="margin: auto; width: 80%; height: 70px; display: flex; justify-content: right;"
                          *ngIf="boton === 1">
                          <button style="height: 38px;" (click)="seleccionarTodo21()" type="button"
                            class="btn btn-outline-dark">Seleccionar Todo</button>
                          <button style="height: 38px;  margin-left: 10px;" (click)="quitarSeleccion21()" type="button"
                            class="btn btn-outline-dark">Desahacer Selección</button>
                          <button style="height: 38px; margin-left: 10px;" (click)="eliminarSeleccionados()"
                            type="button" class="btn btn-outline-dark">Eliminar Seleccionados</button>
                        </div>
                        <div class="fondo">

                          <div id="scroll">
                            <table class="table table-striped">
                              <thead>
                                <tr>
                                  <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre
                                    del
                                    documento</th>
                                  <th scope="col"></th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr *ngFor="let dato of docs21, let i = index">
                                  <td><input type="checkbox" (change)="checkCheckBoxvalue21($event, i, dato?.ID)"
                                      *ngIf="boton === 1" [checked]="todo21">
                                    <img src="assets/img/docs.png" alt="" height="30" width="30">{{dato?.NOMBRE}}
                                    <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;"> - Abrir</a>
                                  </td>
                                  <td>
                                    <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                      class="btn btn-outline-dark">Eliminar</button>
                                  </td>

                                </tr>
                              </tbody>
                            </table>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>


                  <div class="panel panel-default">
                    <div class="panel-heading">
                      <h4 class="panel-title">
                        <button (click)="a82022()" data-bs-toggle="collapse" data-bs-parent="#accordion9"
                          href="#collapse101" style="background: none; border: none;">
                          <i class="bi bi-folder-fill">2022</i></button>
                      </h4>
                    </div>
                    <div id="collapse101" class="panel-collapse collapse in" data-bs-parent="#accordion9">
                      <div class="panel-body">
                        <div style="margin: auto; width: 80%; height: 70px; display: flex; justify-content: right;"
                          *ngIf="boton === 1">
                          <button style="height: 38px;" (click)="seleccionarTodo22()" type="button"
                            class="btn btn-outline-dark">Seleccionar Todo</button>
                          <button style="height: 38px;  margin-left: 10px;" (click)="quitarSeleccion22()" type="button"
                            class="btn btn-outline-dark">Desahacer Selección</button>
                          <button style="height: 38px; margin-left: 10px;" (click)="eliminarSeleccionados()"
                            type="button" class="btn btn-outline-dark">Eliminar Seleccionados</button>
                        </div>
                        <div class="fondo">

                          <div id="scroll">
                            <table class="table table-striped">
                              <thead>
                                <tr>
                                  <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre
                                    del
                                    documento</th>
                                  <th scope="col"></th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr *ngFor="let dato of docs22, let i = index">
                                  <td><input type="checkbox" (change)="checkCheckBoxvalue22($event, i, dato?.ID)"
                                      *ngIf="boton === 1" [checked]="todo22">
                                    <img src="assets/img/docs.png" alt="" height="30" width="30">{{dato?.NOMBRE}}
                                    <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;"> - Abrir</a>
                                  </td>
                                  <td>
                                    <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                      class="btn btn-outline-dark">Eliminar</button>
                                  </td>

                                </tr>
                              </tbody>
                            </table>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>


                  <div class="panel panel-default">
                    <div class="panel-heading">
                      <h4 class="panel-title">
                        <button (click)="a82023()" data-bs-toggle="collapse" data-bs-parent="#accordion9"
                          href="#collapse21239" style="background: none; border: none;">
                          <i class="bi bi-folder-fill">2023</i></button>
                      </h4>
                    </div>
                    <div id="collapse21239" class="panel-collapse collapse in" data-bs-parent="#accordion9">
                      <div class="panel-body">
                        <div style="margin: auto; width: 80%; height: 70px; display: flex; justify-content: right;"
                          *ngIf="boton === 1">
                          <button style="height: 38px;" (click)="seleccionarTodo23()" type="button"
                            class="btn btn-outline-dark">Seleccionar Todo</button>
                          <button style="height: 38px;  margin-left: 10px;" (click)="quitarSeleccion23()" type="button"
                            class="btn btn-outline-dark">Desahacer Selección</button>
                          <button style="height: 38px; margin-left: 10px;" (click)="eliminarSeleccionados()"
                            type="button" class="btn btn-outline-dark">Eliminar Seleccionados</button>
                        </div>
                        <div class="fondo">

                          <div id="scroll">
                            <table class="table table-striped">
                              <thead>
                                <tr>
                                  <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre
                                    del
                                    documento</th>
                                  <th scope="col"></th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr *ngFor="let dato of docs23, let i = index">
                                  <td><input type="checkbox" (change)="checkCheckBoxvalue23($event, i, dato?.ID)"
                                      *ngIf="boton === 1" [checked]="todo23">
                                    <img src="assets/img/docs.png" alt="" height="30" width="30">{{dato?.NOMBRE}}
                                    <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;"> - Abrir</a>
                                  </td>
                                  <td>
                                    <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                      class="btn btn-outline-dark">Eliminar</button>
                                  </td>

                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>



                      <div class="panel panel-default">
                    <div class="panel-heading">
                      <h4 class="panel-title">
                        <button (click)="a82024()" data-bs-toggle="collapse" data-bs-parent="#accordion9"
                          href="#collapse21249" style="background: none; border: none;">
                          <i class="bi bi-folder-fill">2024</i></button>
                      </h4>
                    </div>
                    <div id="collapse21249" class="panel-collapse collapse in" data-bs-parent="#accordion9">
                      <div class="panel-body">
                        <div style="margin: auto; width: 80%; height: 70px; display: flex; justify-content: right;"
                          *ngIf="boton === 1">
                          <button style="height: 38px;" (click)="seleccionarTodo24()" type="button"
                            class="btn btn-outline-dark">Seleccionar Todo</button>
                          <button style="height: 38px;  margin-left: 10px;" (click)="quitarSeleccion24()" type="button"
                            class="btn btn-outline-dark">Desahacer Selección</button>
                          <button style="height: 38px; margin-left: 10px;" (click)="eliminarSeleccionados()"
                            type="button" class="btn btn-outline-dark">Eliminar Seleccionados</button>
                        </div>
                        <div class="fondo">

                          <div id="scroll">
                            <table class="table table-striped">
                              <thead>
                                <tr>
                                  <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre
                                    del
                                    documento</th>
                                  <th scope="col"></th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr *ngFor="let dato of docs24, let i = index">
                                  <td><input type="checkbox" (change)="checkCheckBoxvalue24($event, i, dato?.ID)"
                                      *ngIf="boton === 1" [checked]="todo24">
                                    <img src="assets/img/docs.png" alt="" height="30" width="30">{{dato?.NOMBRE}}
                                    <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;"> - Abrir</a>
                                  </td>
                                  <td>
                                    <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                      class="btn btn-outline-dark">Eliminar</button>
                                  </td>

                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>


                </div>
              </div>
            </div>
            <div class="panel panel-default">
              <div class="panel-heading">
                <h4 class="panel-title">
                  <button (click)="area10()" data-bs-toggle="collapse" data-bs-parent="#accordion1" href="#a10"
                    style="background: none; border: none;">
                    <i class="bi bi-folder-fill">Unidad de atención a la violencia familiar</i></button>
                </h4>
              </div>
              <div id="a10" class="panel-collapse collapse in" data-bs-parent="#accordion1">
                <div class="panel-group" id="accordion10" style=" margin-left: 20px;">

                  <div class="panel panel-default">
                    <div class="panel-heading">
                      <h4 class="panel-title">
                        <button (click)="a82018()" data-bs-toggle="collapse" data-bs-parent="#accordion10"
                          href="#collapse102" style="background: none; border: none;">
                          <i class="bi bi-folder-fill">2018</i></button>
                      </h4>
                    </div>
                    <div id="collapse102" class="panel-collapse collapse in" data-bs-parent="#accordion10">
                      <div class="panel-body">
                        <div style="margin: auto; width: 80%; height: 70px; display: flex; justify-content: right;"
                          *ngIf="boton === 1">
                          <button style="height: 38px;" (click)="seleccionarTodo18()" type="button"
                            class="btn btn-outline-dark">Seleccionar Todo</button>
                          <button style="height: 38px;  margin-left: 10px;" (click)="quitarSeleccion18()" type="button"
                            class="btn btn-outline-dark">Desahacer Selección</button>
                          <button style="height: 38px; margin-left: 10px;" (click)="eliminarSeleccionados()"
                            type="button" class="btn btn-outline-dark">Eliminar Seleccionados</button>
                        </div>
                        <div class="fondo">

                          <div id="scroll">
                            <table class="table table-striped">
                              <thead>
                                <tr>
                                  <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre
                                    del
                                    documento</th>
                                  <th scope="col"></th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr *ngFor="let dato of docs18, let i = index">
                                  <td><input type="checkbox" (change)="checkCheckBoxvalue18($event, i, dato?.ID)"
                                      *ngIf="boton === 1" [checked]="todo18">
                                    <img src="assets/img/docs.png" alt="" height="30" width="30">{{dato?.NOMBRE}}
                                    <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;"> - Abrir</a>
                                  </td>

                                  <td>
                                    <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                      class="btn btn-outline-dark">Eliminar</button>
                                  </td>

                                </tr>
                              </tbody>
                            </table>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>


                  <div class="panel panel-default">
                    <div class="panel-heading">
                      <h4 class="panel-title">
                        <button (click)="a82019()" data-bs-toggle="collapse" data-bs-parent="#accordion10"
                          href="#collapse103" style="background: none; border: none;">
                          <i class="bi bi-folder-fill">2019</i></button>
                      </h4>
                    </div>
                    <div id="collapse103" class="panel-collapse collapse in" data-bs-parent="#accordion10">
                      <div class="panel-body">
                        <div style="margin: auto; width: 80%; height: 70px; display: flex; justify-content: right;"
                          *ngIf="boton === 1">
                          <button style="height: 38px;" (click)="seleccionarTodo19()" type="button"
                            class="btn btn-outline-dark">Seleccionar Todo</button>
                          <button style="height: 38px;  margin-left: 10px;" (click)="quitarSeleccion19()" type="button"
                            class="btn btn-outline-dark">Desahacer Selección</button>
                          <button style="height: 38px; margin-left: 10px;" (click)="eliminarSeleccionados()"
                            type="button" class="btn btn-outline-dark">Eliminar Seleccionados</button>
                        </div>
                        <div class="fondo">

                          <div id="scroll">
                            <table class="table table-striped">
                              <thead>
                                <tr>
                                  <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre
                                    del
                                    documento</th>
                                  <th scope="col"></th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr *ngFor="let dato of docs19, let i = index">
                                  <td><input type="checkbox" (change)="checkCheckBoxvalue19($event, i, dato?.ID)"
                                      *ngIf="boton === 1" [checked]="todo19">
                                    <img src="assets/img/docs.png" alt="" height="30" width="30">{{dato?.NOMBRE}}
                                    <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;"> - Abrir</a>
                                  </td>
                                  <td>
                                    <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                      class="btn btn-outline-dark">Eliminar</button>
                                  </td>

                                </tr>

                              </tbody>
                            </table>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>


                  <div class="panel panel-default">
                    <div class="panel-heading">
                      <h4 class="panel-title">
                        <button (click)="a82020()" data-bs-toggle="collapse" data-bs-parent="#accordion10"
                          href="#collapse104" style="background: none; border: none;">
                          <i class="bi bi-folder-fill">2020</i></button>
                      </h4>
                    </div>
                    <div id="collapse104" class="panel-collapse collapse in" data-bs-parent="#accordion10">
                      <div class="panel-body">
                        <div style="margin: auto; width: 80%; height: 70px; display: flex; justify-content: right;"
                          *ngIf="boton === 1">
                          <button style="height: 38px;" (click)="seleccionarTodo20()" type="button"
                            class="btn btn-outline-dark">Seleccionar Todo</button>
                          <button style="height: 38px;  margin-left: 10px;" (click)="quitarSeleccion20()" type="button"
                            class="btn btn-outline-dark">Desahacer Selección</button>
                          <button style="height: 38px; margin-left: 10px;" (click)="eliminarSeleccionados()"
                            type="button" class="btn btn-outline-dark">Eliminar Seleccionados</button>
                        </div>
                        <div class="fondo">

                          <div id="scroll">
                            <table class="table table-striped">
                              <thead>
                                <tr>
                                  <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre
                                    del
                                    documento</th>
                                  <th scope="col"></th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr *ngFor="let dato of docs20, let i = index">
                                  <td><input type="checkbox" (change)="checkCheckBoxvalue20($event, i, dato?.ID)"
                                      *ngIf="boton === 1" [checked]="todo20">
                                    <img src="assets/img/docs.png" alt="" height="30" width="30">{{dato?.NOMBRE}}
                                    <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;"> - Abrir</a>
                                  </td>
                                  <td>
                                    <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                      class="btn btn-outline-dark">Eliminar</button>
                                  </td>

                                </tr>
                              </tbody>
                            </table>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>


                  <div class="panel panel-default">
                    <div class="panel-heading">
                      <h4 class="panel-title">
                        <button (click)="a82021()" data-bs-toggle="collapse" data-bs-parent="#accordion10"
                          href="#collapse105" style="background: none; border: none;">
                          <i class="bi bi-folder-fill">2021</i></button>
                      </h4>
                    </div>
                    <div id="collapse105" class="panel-collapse collapse in" data-bs-parent="#accordion10">
                      <div class="panel-body">
                        <div style="margin: auto; width: 80%; height: 70px; display: flex; justify-content: right;"
                          *ngIf="boton === 1">
                          <button style="height: 38px;" (click)="seleccionarTodo21()" type="button"
                            class="btn btn-outline-dark">Seleccionar Todo</button>
                          <button style="height: 38px;  margin-left: 10px;" (click)="quitarSeleccion21()" type="button"
                            class="btn btn-outline-dark">Desahacer Selección</button>
                          <button style="height: 38px; margin-left: 10px;" (click)="eliminarSeleccionados()"
                            type="button" class="btn btn-outline-dark">Eliminar Seleccionados</button>
                        </div>
                        <div class="fondo">

                          <div id="scroll">
                            <table class="table table-striped">
                              <thead>
                                <tr>
                                  <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre
                                    del
                                    documento</th>
                                  <th scope="col"></th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr *ngFor="let dato of docs21, let i = index">
                                  <td><input type="checkbox" (change)="checkCheckBoxvalue21($event, i, dato?.ID)"
                                      *ngIf="boton === 1" [checked]="todo21">
                                    <img src="assets/img/docs.png" alt="" height="30" width="30">{{dato?.NOMBRE}}
                                    <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;"> - Abrir</a>
                                  </td>
                                  <td>
                                    <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                      class="btn btn-outline-dark">Eliminar</button>
                                  </td>

                                </tr>
                              </tbody>
                            </table>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>


                  <div class="panel panel-default">
                    <div class="panel-heading">
                      <h4 class="panel-title">
                        <button (click)="a82022()" data-bs-toggle="collapse" data-bs-parent="#accordion10"
                          href="#collapse106" style="background: none; border: none;">
                          <i class="bi bi-folder-fill">2022</i></button>
                      </h4>
                    </div>
                    <div id="collapse106" class="panel-collapse collapse in" data-bs-parent="#accordion10">
                      <div class="panel-body">
                        <div style="margin: auto; width: 80%; height: 70px; display: flex; justify-content: right;"
                          *ngIf="boton === 1">
                          <button style="height: 38px;" (click)="seleccionarTodo22()" type="button"
                            class="btn btn-outline-dark">Seleccionar Todo</button>
                          <button style="height: 38px;  margin-left: 10px;" (click)="quitarSeleccion22()" type="button"
                            class="btn btn-outline-dark">Desahacer Selección</button>
                          <button style="height: 38px; margin-left: 10px;" (click)="eliminarSeleccionados()"
                            type="button" class="btn btn-outline-dark">Eliminar Seleccionados</button>
                        </div>
                        <div class="fondo">

                          <div id="scroll">
                            <table class="table table-striped">
                              <thead>
                                <tr>
                                  <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre
                                    del
                                    documento</th>
                                  <th scope="col"></th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr *ngFor="let dato of docs22, let i = index">
                                  <td><input type="checkbox" (change)="checkCheckBoxvalue22($event, i, dato?.ID)"
                                      *ngIf="boton === 1" [checked]="todo22">
                                    <img src="assets/img/docs.png" alt="" height="30" width="30">{{dato?.NOMBRE}}
                                    <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;"> - Abrir</a>
                                  </td>
                                  <td>
                                    <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                      class="btn btn-outline-dark">Eliminar</button>
                                  </td>

                                </tr>
                              </tbody>
                            </table>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>


                  <div class="panel panel-default">
                    <div class="panel-heading">
                      <h4 class="panel-title">
                        <button (click)="a82023()" data-bs-toggle="collapse" data-bs-parent="#accordion10"
                          href="#collapse212310" style="background: none; border: none;">
                          <i class="bi bi-folder-fill">2023</i></button>
                      </h4>
                    </div>
                    <div id="collapse212310" class="panel-collapse collapse in" data-bs-parent="#accordion10">
                      <div class="panel-body">
                        <div style="margin: auto; width: 80%; height: 70px; display: flex; justify-content: right;"
                          *ngIf="boton === 1">
                          <button style="height: 38px;" (click)="seleccionarTodo23()" type="button"
                            class="btn btn-outline-dark">Seleccionar Todo</button>
                          <button style="height: 38px;  margin-left: 10px;" (click)="quitarSeleccion23()" type="button"
                            class="btn btn-outline-dark">Desahacer Selección</button>
                          <button style="height: 38px; margin-left: 10px;" (click)="eliminarSeleccionados()"
                            type="button" class="btn btn-outline-dark">Eliminar Seleccionados</button>
                        </div>
                        <div class="fondo">

                          <div id="scroll">
                            <table class="table table-striped">
                              <thead>
                                <tr>
                                  <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre
                                    del
                                    documento</th>
                                  <th scope="col"></th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr *ngFor="let dato of docs23, let i = index">
                                  <td><input type="checkbox" (change)="checkCheckBoxvalue23($event, i, dato?.ID)"
                                      *ngIf="boton === 1" [checked]="todo23">
                                    <img src="assets/img/docs.png" alt="" height="30" width="30">{{dato?.NOMBRE}}
                                    <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;"> - Abrir</a>
                                  </td>
                                  <td>
                                    <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                      class="btn btn-outline-dark">Eliminar</button>
                                  </td>

                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>


     <div class="panel panel-default">
                    <div class="panel-heading">
                      <h4 class="panel-title">
                        <button (click)="a82024()" data-bs-toggle="collapse" data-bs-parent="#accordion10"
                          href="#collapse212410" style="background: none; border: none;">
                          <i class="bi bi-folder-fill">2024</i></button>
                      </h4>
                    </div>
                    <div id="collapse212410" class="panel-collapse collapse in" data-bs-parent="#accordion10">
                      <div class="panel-body">
                        <div style="margin: auto; width: 80%; height: 70px; display: flex; justify-content: right;"
                          *ngIf="boton === 1">
                          <button style="height: 38px;" (click)="seleccionarTodo24()" type="button"
                            class="btn btn-outline-dark">Seleccionar Todo</button>
                          <button style="height: 38px;  margin-left: 10px;" (click)="quitarSeleccion24()" type="button"
                            class="btn btn-outline-dark">Desahacer Selección</button>
                          <button style="height: 38px; margin-left: 10px;" (click)="eliminarSeleccionados()"
                            type="button" class="btn btn-outline-dark">Eliminar Seleccionados</button>
                        </div>
                        <div class="fondo">

                          <div id="scroll">
                            <table class="table table-striped">
                              <thead>
                                <tr>
                                  <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre
                                    del
                                    documento</th>
                                  <th scope="col"></th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr *ngFor="let dato of docs24, let i = index">
                                  <td><input type="checkbox" (change)="checkCheckBoxvalue24($event, i, dato?.ID)"
                                      *ngIf="boton === 1" [checked]="todo24">
                                    <img src="assets/img/docs.png" alt="" height="30" width="30">{{dato?.NOMBRE}}
                                    <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;"> - Abrir</a>
                                  </td>
                                  <td>
                                    <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                      class="btn btn-outline-dark">Eliminar</button>
                                  </td>

                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>








                </div>
              </div>
            </div>
            <div class="panel panel-default">
              <div class="panel-heading">
                <h4 class="panel-title">
                  <button (click)="area11()" data-bs-toggle="collapse" data-bs-parent="#accordion1" href="#a11"
                    style="background: none; border: none;">
                    <i class="bi bi-folder-fill">Unidad de transparencia</i></button>
                </h4>
              </div>
              <div id="a11" class="panel-collapse collapse in" data-bs-parent="#accordion1">
                <div class="panel-group" id="accordion11" style=" margin-left: 20px;">

                  <div class="panel panel-default">
                    <div class="panel-heading">
                      <h4 class="panel-title">
                        <button (click)="a82018()" data-bs-toggle="collapse" data-bs-parent="#accordion11"
                          href="#collapse108" style="background: none; border: none;">
                          <i class="bi bi-folder-fill">2018</i></button>
                      </h4>
                    </div>
                    <div id="collapse108" class="panel-collapse collapse in" data-bs-parent="#accordion11">
                      <div class="panel-body">
                        <div style="margin: auto; width: 80%; height: 70px; display: flex; justify-content: right;"
                          *ngIf="boton === 1">
                          <button style="height: 38px;" (click)="seleccionarTodo18()" type="button"
                            class="btn btn-outline-dark">Seleccionar Todo</button>
                          <button style="height: 38px;  margin-left: 10px;" (click)="quitarSeleccion18()" type="button"
                            class="btn btn-outline-dark">Desahacer Selección</button>
                          <button style="height: 38px; margin-left: 10px;" (click)="eliminarSeleccionados()"
                            type="button" class="btn btn-outline-dark">Eliminar Seleccionados</button>
                        </div>
                        <div class="fondo">

                          <div id="scroll">
                            <table class="table table-striped">
                              <thead>
                                <tr>
                                  <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre
                                    del
                                    documento</th>
                                  <th scope="col"></th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr *ngFor="let dato of docs18, let i = index">
                                  <td><input type="checkbox" (change)="checkCheckBoxvalue18($event, i, dato?.ID)"
                                      *ngIf="boton === 1" [checked]="todo18">
                                    <img src="assets/img/docs.png" alt="" height="30" width="30">{{dato?.NOMBRE}}
                                    <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;"> - Abrir</a>
                                  </td>

                                  <td>
                                    <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                      class="btn btn-outline-dark">Eliminar</button>
                                  </td>

                                </tr>
                              </tbody>
                            </table>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>


                  <div class="panel panel-default">
                    <div class="panel-heading">
                      <h4 class="panel-title">
                        <button (click)="a82019()" data-bs-toggle="collapse" data-bs-parent="#accordion11"
                          href="#collapse109" style="background: none; border: none;">
                          <i class="bi bi-folder-fill">2019</i></button>
                      </h4>
                    </div>
                    <div id="collapse109" class="panel-collapse collapse in" data-bs-parent="#accordion11">
                      <div class="panel-body">
                        <div style="margin: auto; width: 80%; height: 70px; display: flex; justify-content: right;"
                          *ngIf="boton === 1">
                          <button style="height: 38px;" (click)="seleccionarTodo19()" type="button"
                            class="btn btn-outline-dark">Seleccionar Todo</button>
                          <button style="height: 38px;  margin-left: 10px;" (click)="quitarSeleccion19()" type="button"
                            class="btn btn-outline-dark">Desahacer Selección</button>
                          <button style="height: 38px; margin-left: 10px;" (click)="eliminarSeleccionados()"
                            type="button" class="btn btn-outline-dark">Eliminar Seleccionados</button>
                        </div>
                        <div class="fondo">

                          <div id="scroll">
                            <table class="table table-striped">
                              <thead>
                                <tr>
                                  <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre
                                    del
                                    documento</th>
                                  <th scope="col"></th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr *ngFor="let dato of docs19, let i = index">
                                  <td><input type="checkbox" (change)="checkCheckBoxvalue19($event, i, dato?.ID)"
                                      *ngIf="boton === 1" [checked]="todo19">
                                    <img src="assets/img/docs.png" alt="" height="30" width="30">{{dato?.NOMBRE}}
                                    <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;"> - Abrir</a>
                                  </td>
                                  <td>
                                    <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                      class="btn btn-outline-dark">Eliminar</button>
                                  </td>

                                </tr>

                              </tbody>
                            </table>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>


                  <div class="panel panel-default">
                    <div class="panel-heading">
                      <h4 class="panel-title">
                        <button (click)="a82020()" data-bs-toggle="collapse" data-bs-parent="#accordion11"
                          href="#collapse110" style="background: none; border: none;">
                          <i class="bi bi-folder-fill">2020</i></button>
                      </h4>
                    </div>
                    <div id="collapse110" class="panel-collapse collapse in" data-bs-parent="#accordion11">
                      <div class="panel-body">
                        <div style="margin: auto; width: 80%; height: 70px; display: flex; justify-content: right;"
                          *ngIf="boton === 1">
                          <button style="height: 38px;" (click)="seleccionarTodo20()" type="button"
                            class="btn btn-outline-dark">Seleccionar Todo</button>
                          <button style="height: 38px;  margin-left: 10px;" (click)="quitarSeleccion20()" type="button"
                            class="btn btn-outline-dark">Desahacer Selección</button>
                          <button style="height: 38px; margin-left: 10px;" (click)="eliminarSeleccionados()"
                            type="button" class="btn btn-outline-dark">Eliminar Seleccionados</button>
                        </div>
                        <div class="fondo">

                          <div id="scroll">
                            <table class="table table-striped">
                              <thead>
                                <tr>
                                  <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre
                                    del
                                    documento</th>
                                  <th scope="col"></th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr *ngFor="let dato of docs20, let i = index">
                                  <td><input type="checkbox" (change)="checkCheckBoxvalue20($event, i, dato?.ID)"
                                      *ngIf="boton === 1" [checked]="todo20">
                                    <img src="assets/img/docs.png" alt="" height="30" width="30">{{dato?.NOMBRE}}
                                    <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;"> - Abrir</a>
                                  </td>
                                  <td>
                                    <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                      class="btn btn-outline-dark">Eliminar</button>
                                  </td>

                                </tr>
                              </tbody>
                            </table>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>


                  <div class="panel panel-default">
                    <div class="panel-heading">
                      <h4 class="panel-title">
                        <button (click)="a82021()" data-bs-toggle="collapse" data-bs-parent="#accordion11"
                          href="#collapse111" style="background: none; border: none;">
                          <i class="bi bi-folder-fill">2021</i></button>
                      </h4>
                    </div>
                    <div id="collapse111" class="panel-collapse collapse in" data-bs-parent="#accordion11">
                      <div class="panel-body">
                        <div style="margin: auto; width: 80%; height: 70px; display: flex; justify-content: right;"
                          *ngIf="boton === 1">
                          <button style="height: 38px;" (click)="seleccionarTodo21()" type="button"
                            class="btn btn-outline-dark">Seleccionar Todo</button>
                          <button style="height: 38px;  margin-left: 10px;" (click)="quitarSeleccion21()" type="button"
                            class="btn btn-outline-dark">Desahacer Selección</button>
                          <button style="height: 38px; margin-left: 10px;" (click)="eliminarSeleccionados()"
                            type="button" class="btn btn-outline-dark">Eliminar Seleccionados</button>
                        </div>
                        <div class="fondo">

                          <div id="scroll">
                            <table class="table table-striped">
                              <thead>
                                <tr>
                                  <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre
                                    del
                                    documento</th>
                                  <th scope="col"></th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr *ngFor="let dato of docs21, let i = index">
                                  <td><input type="checkbox" (change)="checkCheckBoxvalue21($event, i, dato?.ID)"
                                      *ngIf="boton === 1" [checked]="todo21">
                                    <img src="assets/img/docs.png" alt="" height="30" width="30">{{dato?.NOMBRE}}
                                    <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;"> - Abrir</a>
                                  </td>
                                  <td>
                                    <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                      class="btn btn-outline-dark">Eliminar</button>
                                  </td>

                                </tr>
                              </tbody>
                            </table>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>


                  <div class="panel panel-default">
                    <div class="panel-heading">
                      <h4 class="panel-title">
                        <button (click)="a82022()" data-bs-toggle="collapse" data-bs-parent="#accordion11"
                          href="#collapse112" style="background: none; border: none;">
                          <i class="bi bi-folder-fill">2022</i></button>
                      </h4>
                    </div>
                    <div id="collapse112" class="panel-collapse collapse in" data-bs-parent="#accordion11">
                      <div class="panel-body">
                        <div style="margin: auto; width: 80%; height: 70px; display: flex; justify-content: right;"
                          *ngIf="boton === 1">
                          <button style="height: 38px;" (click)="seleccionarTodo22()" type="button"
                            class="btn btn-outline-dark">Seleccionar Todo</button>
                          <button style="height: 38px;  margin-left: 10px;" (click)="quitarSeleccion22()" type="button"
                            class="btn btn-outline-dark">Desahacer Selección</button>
                          <button style="height: 38px; margin-left: 10px;" (click)="eliminarSeleccionados()"
                            type="button" class="btn btn-outline-dark">Eliminar Seleccionados</button>
                        </div>
                        <div class="fondo">

                          <div id="scroll">
                            <table class="table table-striped">
                              <thead>
                                <tr>
                                  <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre
                                    del
                                    documento</th>
                                  <th scope="col"></th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr *ngFor="let dato of docs22, let i = index">
                                  <td><input type="checkbox" (change)="checkCheckBoxvalue22($event, i, dato?.ID)"
                                      *ngIf="boton === 1" [checked]="todo22">
                                    <img src="assets/img/docs.png" alt="" height="30" width="30">{{dato?.NOMBRE}}
                                    <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;"> - Abrir</a>
                                  </td>
                                  <td>
                                    <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                      class="btn btn-outline-dark">Eliminar</button>
                                  </td>

                                </tr>
                              </tbody>
                            </table>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="panel panel-default">
                    <div class="panel-heading">
                      <h4 class="panel-title">
                        <button (click)="a82023()" data-bs-toggle="collapse" data-bs-parent="#accordion11"
                          href="#collapse212311" style="background: none; border: none;">
                          <i class="bi bi-folder-fill">2023</i></button>
                      </h4>
                    </div>
                    <div id="collapse212311" class="panel-collapse collapse in" data-bs-parent="#accordion11">
                      <div class="panel-body">
                        <div style="margin: auto; width: 80%; height: 70px; display: flex; justify-content: right;"
                          *ngIf="boton === 1">
                          <button style="height: 38px;" (click)="seleccionarTodo23()" type="button"
                            class="btn btn-outline-dark">Seleccionar Todo</button>
                          <button style="height: 38px;  margin-left: 10px;" (click)="quitarSeleccion23()" type="button"
                            class="btn btn-outline-dark">Desahacer Selección</button>
                          <button style="height: 38px; margin-left: 10px;" (click)="eliminarSeleccionados()"
                            type="button" class="btn btn-outline-dark">Eliminar Seleccionados</button>
                        </div>
                        <div class="fondo">

                          <div id="scroll">
                            <table class="table table-striped">
                              <thead>
                                <tr>
                                  <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre
                                    del
                                    documento</th>
                                  <th scope="col"></th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr *ngFor="let dato of docs23, let i = index">
                                  <td><input type="checkbox" (change)="checkCheckBoxvalue23($event, i, dato?.ID)"
                                      *ngIf="boton === 1" [checked]="todo23">
                                    <img src="assets/img/docs.png" alt="" height="30" width="30">{{dato?.NOMBRE}}
                                    <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;"> - Abrir</a>
                                  </td>
                                  <td>
                                    <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                      class="btn btn-outline-dark">Eliminar</button>
                                  </td>

                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

    <div class="panel panel-default">
                    <div class="panel-heading">
                      <h4 class="panel-title">
                        <button (click)="a82024()" data-bs-toggle="collapse" data-bs-parent="#accordion11"
                          href="#collapse212411" style="background: none; border: none;">
                          <i class="bi bi-folder-fill">2024</i></button>
                      </h4>
                    </div>
                    <div id="collapse212411" class="panel-collapse collapse in" data-bs-parent="#accordion11">
                      <div class="panel-body">
                        <div style="margin: auto; width: 80%; height: 70px; display: flex; justify-content: right;"
                          *ngIf="boton === 1">
                          <button style="height: 38px;" (click)="seleccionarTodo24()" type="button"
                            class="btn btn-outline-dark">Seleccionar Todo</button>
                          <button style="height: 38px;  margin-left: 10px;" (click)="quitarSeleccion24()" type="button"
                            class="btn btn-outline-dark">Desahacer Selección</button>
                          <button style="height: 38px; margin-left: 10px;" (click)="eliminarSeleccionados()"
                            type="button" class="btn btn-outline-dark">Eliminar Seleccionados</button>
                        </div>
                        <div class="fondo">

                          <div id="scroll">
                            <table class="table table-striped">
                              <thead>
                                <tr>
                                  <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre
                                    del
                                    documento</th>
                                  <th scope="col"></th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr *ngFor="let dato of docs24, let i = index">
                                  <td><input type="checkbox" (change)="checkCheckBoxvalue24($event, i, dato?.ID)"
                                      *ngIf="boton === 1" [checked]="todo24">
                                    <img src="assets/img/docs.png" alt="" height="30" width="30">{{dato?.NOMBRE}}
                                    <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;"> - Abrir</a>
                                  </td>
                                  <td>
                                    <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                      class="btn btn-outline-dark">Eliminar</button>
                                  </td>

                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
            <div class="panel panel-default">
              <div class="panel-heading">
                <h4 class="panel-title">
                  <button (click)="area12()" data-bs-toggle="collapse" data-bs-parent="#accordion1" href="#a12"
                    style="background: none; border: none;">
                    <i class="bi bi-folder-fill">Unidad regional de rehabilitación</i></button>
                </h4>
              </div>
              <div id="a12" class="panel-collapse collapse in" data-bs-parent="#accordion1">
                <div class="panel-group" id="accordion12" style=" margin-left: 20px;">

                  <div class="panel panel-default">
                    <div class="panel-heading">
                      <h4 class="panel-title">
                        <button (click)="a82018()" data-bs-toggle="collapse" data-bs-parent="#accordion12"
                          href="#collapse113" style="background: none; border: none;">
                          <i class="bi bi-folder-fill">2018</i></button>
                      </h4>
                    </div>
                    <div id="collapse113" class="panel-collapse collapse in" data-bs-parent="#accordion12">
                      <div class="panel-body">
                        <div style="margin: auto; width: 80%; height: 70px; display: flex; justify-content: right;"
                          *ngIf="boton === 1">
                          <button style="height: 38px;" (click)="seleccionarTodo18()" type="button"
                            class="btn btn-outline-dark">Seleccionar Todo</button>
                          <button style="height: 38px;  margin-left: 10px;" (click)="quitarSeleccion18()" type="button"
                            class="btn btn-outline-dark">Desahacer Selección</button>
                          <button style="height: 38px; margin-left: 10px;" (click)="eliminarSeleccionados()"
                            type="button" class="btn btn-outline-dark">Eliminar Seleccionados</button>
                        </div>
                        <div class="fondo">

                          <div id="scroll">
                            <table class="table table-striped">
                              <thead>
                                <tr>
                                  <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre
                                    del
                                    documento</th>
                                  <th scope="col"></th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr *ngFor="let dato of docs18, let i = index">
                                  <td><input type="checkbox" (change)="checkCheckBoxvalue18($event, i, dato?.ID)"
                                      *ngIf="boton === 1" [checked]="todo18">
                                    <img src="assets/img/docs.png" alt="" height="30" width="30">{{dato?.NOMBRE}}
                                    <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;"> - Abrir</a>
                                  </td>

                                  <td>
                                    <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                      class="btn btn-outline-dark">Eliminar</button>
                                  </td>

                                </tr>
                              </tbody>
                            </table>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>


                  <div class="panel panel-default">
                    <div class="panel-heading">
                      <h4 class="panel-title">
                        <button (click)="a82019()" data-bs-toggle="collapse" data-bs-parent="#accordion12"
                          href="#collapse114" style="background: none; border: none;">
                          <i class="bi bi-folder-fill">2019</i></button>
                      </h4>
                    </div>
                    <div id="collapse114" class="panel-collapse collapse in" data-bs-parent="#accordion12">
                      <div class="panel-body">
                        <div style="margin: auto; width: 80%; height: 70px; display: flex; justify-content: right;"
                          *ngIf="boton === 1">
                          <button style="height: 38px;" (click)="seleccionarTodo19()" type="button"
                            class="btn btn-outline-dark">Seleccionar Todo</button>
                          <button style="height: 38px;  margin-left: 10px;" (click)="quitarSeleccion19()" type="button"
                            class="btn btn-outline-dark">Desahacer Selección</button>
                          <button style="height: 38px; margin-left: 10px;" (click)="eliminarSeleccionados()"
                            type="button" class="btn btn-outline-dark">Eliminar Seleccionados</button>
                        </div>
                        <div class="fondo">

                          <div id="scroll">
                            <table class="table table-striped">
                              <thead>
                                <tr>
                                  <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre
                                    del
                                    documento</th>
                                  <th scope="col"></th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr *ngFor="let dato of docs19, let i = index">
                                  <td><input type="checkbox" (change)="checkCheckBoxvalue19($event, i, dato?.ID)"
                                      *ngIf="boton === 1" [checked]="todo19">
                                    <img src="assets/img/docs.png" alt="" height="30" width="30">{{dato?.NOMBRE}}
                                    <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;"> - Abrir</a>
                                  </td>
                                  <td>
                                    <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                      class="btn btn-outline-dark">Eliminar</button>
                                  </td>

                                </tr>

                              </tbody>
                            </table>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>


                  <div class="panel panel-default">
                    <div class="panel-heading">
                      <h4 class="panel-title">
                        <button (click)="a82020()" data-bs-toggle="collapse" data-bs-parent="#accordion12"
                          href="#collapse115" style="background: none; border: none;">
                          <i class="bi bi-folder-fill">2020</i></button>
                      </h4>
                    </div>
                    <div id="collapse115" class="panel-collapse collapse in" data-bs-parent="#accordion12">
                      <div class="panel-body">
                        <div style="margin: auto; width: 80%; height: 70px; display: flex; justify-content: right;"
                          *ngIf="boton === 1">
                          <button style="height: 38px;" (click)="seleccionarTodo20()" type="button"
                            class="btn btn-outline-dark">Seleccionar Todo</button>
                          <button style="height: 38px;  margin-left: 10px;" (click)="quitarSeleccion20()" type="button"
                            class="btn btn-outline-dark">Desahacer Selección</button>
                          <button style="height: 38px; margin-left: 10px;" (click)="eliminarSeleccionados()"
                            type="button" class="btn btn-outline-dark">Eliminar Seleccionados</button>
                        </div>
                        <div class="fondo">

                          <div id="scroll">
                            <table class="table table-striped">
                              <thead>
                                <tr>
                                  <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre
                                    del
                                    documento</th>
                                  <th scope="col"></th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr *ngFor="let dato of docs20, let i = index">
                                  <td><input type="checkbox" (change)="checkCheckBoxvalue20($event, i, dato?.ID)"
                                      *ngIf="boton === 1" [checked]="todo20">
                                    <img src="assets/img/docs.png" alt="" height="30" width="30">{{dato?.NOMBRE}}
                                    <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;"> - Abrir</a>
                                  </td>
                                  <td>
                                    <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                      class="btn btn-outline-dark">Eliminar</button>
                                  </td>

                                </tr>
                              </tbody>
                            </table>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>


                  <div class="panel panel-default">
                    <div class="panel-heading">
                      <h4 class="panel-title">
                        <button (click)="a82021()" data-bs-toggle="collapse" data-bs-parent="#accordion12"
                          href="#collapse116" style="background: none; border: none;">
                          <i class="bi bi-folder-fill">2021</i></button>
                      </h4>
                    </div>
                    <div id="collapse116" class="panel-collapse collapse in" data-bs-parent="#accordion12">
                      <div class="panel-body">
                        <div style="margin: auto; width: 80%; height: 70px; display: flex; justify-content: right;"
                          *ngIf="boton === 1">
                          <button style="height: 38px;" (click)="seleccionarTodo21()" type="button"
                            class="btn btn-outline-dark">Seleccionar Todo</button>
                          <button style="height: 38px;  margin-left: 10px;" (click)="quitarSeleccion21()" type="button"
                            class="btn btn-outline-dark">Desahacer Selección</button>
                          <button style="height: 38px; margin-left: 10px;" (click)="eliminarSeleccionados()"
                            type="button" class="btn btn-outline-dark">Eliminar Seleccionados</button>
                        </div>
                        <div class="fondo">

                          <div id="scroll">
                            <table class="table table-striped">
                              <thead>
                                <tr>
                                  <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre
                                    del
                                    documento</th>
                                  <th scope="col"></th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr *ngFor="let dato of docs21, let i = index">
                                  <td><input type="checkbox" (change)="checkCheckBoxvalue21($event, i, dato?.ID)"
                                      *ngIf="boton === 1" [checked]="todo21">
                                    <img src="assets/img/docs.png" alt="" height="30" width="30">{{dato?.NOMBRE}}
                                    <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;"> - Abrir</a>
                                  </td>
                                  <td>
                                    <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                      class="btn btn-outline-dark">Eliminar</button>
                                  </td>

                                </tr>
                              </tbody>
                            </table>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>


                  <div class="panel panel-default">
                    <div class="panel-heading">
                      <h4 class="panel-title">
                        <button (click)="a82022()" data-bs-toggle="collapse" data-bs-parent="#accordion12"
                          href="#collapse117" style="background: none; border: none;">
                          <i class="bi bi-folder-fill">2022</i></button>
                      </h4>
                    </div>
                    <div id="collapse117" class="panel-collapse collapse in" data-bs-parent="#accordion12">
                      <div class="panel-body">
                        <div style="margin: auto; width: 80%; height: 70px; display: flex; justify-content: right;"
                          *ngIf="boton === 1">
                          <button style="height: 38px;" (click)="seleccionarTodo22()" type="button"
                            class="btn btn-outline-dark">Seleccionar Todo</button>
                          <button style="height: 38px;  margin-left: 10px;" (click)="quitarSeleccion22()" type="button"
                            class="btn btn-outline-dark">Desahacer Selección</button>
                          <button style="height: 38px; margin-left: 10px;" (click)="eliminarSeleccionados()"
                            type="button" class="btn btn-outline-dark">Eliminar Seleccionados</button>
                        </div>
                        <div class="fondo">

                          <div id="scroll">
                            <table class="table table-striped">
                              <thead>
                                <tr>
                                  <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre
                                    del
                                    documento</th>
                                  <th scope="col"></th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr *ngFor="let dato of docs22, let i = index">
                                  <td><input type="checkbox" (change)="checkCheckBoxvalue22($event, i, dato?.ID)"
                                      *ngIf="boton === 1" [checked]="todo22">
                                    <img src="assets/img/docs.png" alt="" height="30" width="30">{{dato?.NOMBRE}}
                                    <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;"> - Abrir</a>
                                  </td>
                                  <td>
                                    <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                      class="btn btn-outline-dark">Eliminar</button>
                                  </td>

                                </tr>
                              </tbody>
                            </table>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>


                  <div class="panel panel-default">
                    <div class="panel-heading">
                      <h4 class="panel-title">
                        <button (click)="a82023()" data-bs-toggle="collapse" data-bs-parent="#accordion12"
                          href="#collapse212321" style="background: none; border: none;">
                          <i class="bi bi-folder-fill">2023</i></button>
                      </h4>
                    </div>
                    <div id="collapse212321" class="panel-collapse collapse in" data-bs-parent="#accordion12">
                      <div class="panel-body">
                        <div style="margin: auto; width: 80%; height: 70px; display: flex; justify-content: right;"
                          *ngIf="boton === 1">
                          <button style="height: 38px;" (click)="seleccionarTodo23()" type="button"
                            class="btn btn-outline-dark">Seleccionar Todo</button>
                          <button style="height: 38px;  margin-left: 10px;" (click)="quitarSeleccion23()" type="button"
                            class="btn btn-outline-dark">Desahacer Selección</button>
                          <button style="height: 38px; margin-left: 10px;" (click)="eliminarSeleccionados()"
                            type="button" class="btn btn-outline-dark">Eliminar Seleccionados</button>
                        </div>
                        <div class="fondo">

                          <div id="scroll">
                            <table class="table table-striped">
                              <thead>
                                <tr>
                                  <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre
                                    del
                                    documento</th>
                                  <th scope="col"></th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr *ngFor="let dato of docs23, let i = index">
                                  <td><input type="checkbox" (change)="checkCheckBoxvalue23($event, i, dato?.ID)"
                                      *ngIf="boton === 1" [checked]="todo23">
                                    <img src="assets/img/docs.png" alt="" height="30" width="30">{{dato?.NOMBRE}}
                                    <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;"> - Abrir</a>
                                  </td>
                                  <td>
                                    <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                      class="btn btn-outline-dark">Eliminar</button>
                                  </td>

                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

   <div class="panel panel-default">
                    <div class="panel-heading">
                      <h4 class="panel-title">
                        <button (click)="a82024()" data-bs-toggle="collapse" data-bs-parent="#accordion12"
                          href="#collapse212421" style="background: none; border: none;">
                          <i class="bi bi-folder-fill">2024</i></button>
                      </h4>
                    </div>
                    <div id="collapse212421" class="panel-collapse collapse in" data-bs-parent="#accordion12">
                      <div class="panel-body">
                        <div style="margin: auto; width: 80%; height: 70px; display: flex; justify-content: right;"
                          *ngIf="boton === 1">
                          <button style="height: 38px;" (click)="seleccionarTodo24()" type="button"
                            class="btn btn-outline-dark">Seleccionar Todo</button>
                          <button style="height: 38px;  margin-left: 10px;" (click)="quitarSeleccion24()" type="button"
                            class="btn btn-outline-dark">Desahacer Selección</button>
                          <button style="height: 38px; margin-left: 10px;" (click)="eliminarSeleccionados()"
                            type="button" class="btn btn-outline-dark">Eliminar Seleccionados</button>
                        </div>
                        <div class="fondo">

                          <div id="scroll">
                            <table class="table table-striped">
                              <thead>
                                <tr>
                                  <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre
                                    del
                                    documento</th>
                                  <th scope="col"></th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr *ngFor="let dato of docs24, let i = index">
                                  <td><input type="checkbox" (change)="checkCheckBoxvalue24($event, i, dato?.ID)"
                                      *ngIf="boton === 1" [checked]="todo24">
                                    <img src="assets/img/docs.png" alt="" height="30" width="30">{{dato?.NOMBRE}}
                                    <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;"> - Abrir</a>
                                  </td>
                                  <td>
                                    <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                      class="btn btn-outline-dark">Eliminar</button>
                                  </td>

                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>


                </div>
              </div>
            </div>

          </div><!-- ======= Fin areas======= -->
        </div>
      </div>
      <!--Ventana Modal Editar Nombre-->

      <!--Termina Ventana Modal Accion Editar Nombre-->


    </section><!-- ======= Fin Seccion Carpetas por año ======= -->

  </main><!-- End #main -->


  <a href="#" class="back-to-top d-flex align-items-center justify-content-center"><i
      class="bi bi-arrow-up-short"></i></a>

  <!-- Vendor JS Files -->
  <script src="/assets/vendor/aos/aos.js"></script>
  <script src="/assets/vendor/bootstrap/js/bootstrap.bundle.min.js"></script>
  <script src="/assets/vendor/glightbox/js/glightbox.min.js"></script>
  <script src="/assets/vendor/isotope-layout/isotope.pkgd.min.js"></script>
  <script src="/assets/vendor/swiper/swiper-bundle.min.js"></script>
  <script src="/assets/vendor/waypoints/noframework.waypoints.js"></script>
  <script src="/assets/vendor/php-email-form/validate.js"></script>

  <!-- Template Main JS File -->
  <script src="/assets/js/main.js"></script>

</body>

</html>
