import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Fraccion1Service } from 'src/services/Articulo8/Fraccion1.service';
import { GeneralService } from 'src/services/general.service';
import { SweetAlertService } from 'src/services/sweet-alert.service';

@Component({
  selector: 'app-fra9',
  templateUrl: './fra9.component.html',
  styleUrls: ['./fra9.component.scss']
})
export class Fra9Component implements OnInit {
  docs: any;
  docs18: any;
  docs19: any;
  docs20: any;
  docs21: any;
  docs22: any;
  docs23: any;
  docs24: any;
  curri:any
  docsSubsidio: any;
  boton: any;
  tri: any;
  area: any;
  fecha: any;
  displayStyleEditarNombre = "none";
  borrar: string[] = [];
  valor: any;
  todo18: any;
  todo19: any;
  todo20: any;
  todo21: any;
  todo22: any;
  todo23: any;
  todo24: any;
  eliminarForm: any = {
    ESTATUS: 0,
    NOMBRE: undefined,
    ENLACE: undefined,

  };
  constructor(private api: Fraccion1Service, private alerts: SweetAlertService, private seervice: GeneralService) { }

  ngOnInit(): void {

    localStorage.setItem('fraccion', '9');
    localStorage.setItem('inciso', '0');

    if (localStorage.getItem('token')) {
      this.boton = 1;
    }

  }
  checkCheckBoxvalue18(event:any, index:any, id: any){
    console.log(event.target.checked)
    this.todo18 = null;
    this.valor = event.target.checked;
    if(this.valor === true){
      console.log('verdadero');
      console.log(index);
      this.borrar[index]=this.docs18[index].ID;
      console.log(this.borrar);

    }else if(this.valor === false){
      console.log('falso');
      console.log(index);
      let indice = this.borrar.indexOf(id);
      this.borrar.splice(indice, 1);
      console.log(this.borrar);

    }
  }

  seleccionarTodo18(){
    this.todo18 = true;
    let valores = this.docs18.length;
    for( let i = 0; i < valores; i++){
      console.log(i);
      this.borrar[i]=this.docs18[i].ID;
      console.log(this.borrar);

    }

  }

  quitarSeleccion18(){
    let valores = this.borrar.length;
    for( let i = 0; i < valores; i++){
      console.log(i);
      this.borrar.pop();
      console.log(this.borrar);
      this.todo18 = false;
    }
  }

  checkCheckBoxvalue19(event:any, index:any, id: any){
    console.log(event.target.checked)
    this.todo19 = null;
    this.valor = event.target.checked;
    if(this.valor === true){
      console.log('verdadero');
      console.log(index);
      this.borrar[index]=this.docs19[index].ID;
      console.log(this.borrar);

    }else if(this.valor === false){
      console.log('falso');
      console.log(index);
      let indice = this.borrar.indexOf(id);
      this.borrar.splice(indice, 1);
      console.log(this.borrar);

    }
  }

  seleccionarTodo19(){
    this.todo19 = true;
    let valores = this.docs19.length;
    for( let i = 0; i < valores; i++){
      console.log(i);
      this.borrar[i]=this.docs19[i].ID;
      console.log(this.borrar);

    }

  }

  quitarSeleccion19(){
    let valores = this.borrar.length;
    for( let i = 0; i < valores; i++){
      console.log(i);
      this.borrar.pop();
      console.log(this.borrar);
      this.todo19 = false;
    }
  }

  checkCheckBoxvalue20(event:any, index:any, id: any){
    console.log(event.target.checked)
    this.todo20 = null;
    this.valor = event.target.checked;
    if(this.valor === true){
      console.log('verdadero');
      console.log(index);
      this.borrar[index]=this.docs20[index].ID;
      console.log(this.borrar);

    }else if(this.valor === false){
      console.log('falso');
      console.log(index);
      let indice = this.borrar.indexOf(id);
      this.borrar.splice(indice, 1);
      console.log(this.borrar);

    }
  }

  seleccionarTodo20(){
    this.todo20 = true;
    let valores = this.docs20.length;
    for( let i = 0; i < valores; i++){
      console.log(i);
      this.borrar[i]=this.docs20[i].ID;
      console.log(this.borrar);

    }

  }

  quitarSeleccion20(){
    let valores = this.borrar.length;
    for( let i = 0; i < valores; i++){
      console.log(i);
      this.borrar.pop();
      console.log(this.borrar);
      this.todo20 = false;
    }
  }
  checkCheckBoxvalue21(event:any, index:any, id: any){
    console.log(event.target.checked)
    this.todo21 = null;
    this.valor = event.target.checked;
    if(this.valor === true){
      console.log('verdadero');
      console.log(index);
      this.borrar[index]=this.docs21[index].ID;
      console.log(this.borrar);

    }else if(this.valor === false){
      console.log('falso');
      console.log(index);
      let indice = this.borrar.indexOf(id);
      this.borrar.splice(indice, 1);
      console.log(this.borrar);

    }
  }

  seleccionarTodo21(){
    this.todo21 = true;
    let valores = this.docs21.length;
    for( let i = 0; i < valores; i++){
      console.log(i);
      this.borrar[i]=this.docs21[i].ID;
      console.log(this.borrar);

    }

  }

  quitarSeleccion21(){
    let valores = this.borrar.length;
    for( let i = 0; i < valores; i++){
      console.log(i);
      this.borrar.pop();
      console.log(this.borrar);
      this.todo21 = false;
    }
  }
  checkCheckBoxvalue22(event:any, index:any, id: any){
    console.log(event.target.checked)
    this.todo22 = null;
    this.valor = event.target.checked;
    if(this.valor === true){
      console.log('verdadero');
      console.log(index);
      this.borrar[index]=this.docs22[index].ID;
      console.log(this.borrar);

    }else if(this.valor === false){
      console.log('falso');
      console.log(index);
      let indice = this.borrar.indexOf(id);
      this.borrar.splice(indice, 1);
      console.log(this.borrar);

    }
  }

  seleccionarTodo22(){
    this.todo22 = true;
    let valores = this.docs22.length;
    for( let i = 0; i < valores; i++){
      console.log(i);
      this.borrar[i]=this.docs22[i].ID;
      console.log(this.borrar);

    }

  }
  quitarSeleccion22(){
    let valores = this.borrar.length;
    for( let i = 0; i < valores; i++){
      console.log(i);
      this.borrar.pop();
      console.log(this.borrar);
      this.todo22 = false;
    }
  }

  checkCheckBoxvalue23(event:any, index:any, id: any){
    console.log(event.target.checked)
    this.todo23 = null;
    this.valor = event.target.checked;
    if(this.valor === true){
      console.log('verdadero');
      console.log(index);
      this.borrar[index]=this.docs23[index].ID;
      console.log(this.borrar);

    }else if(this.valor === false){
      console.log('falso');
      console.log(index);
      let indice = this.borrar.indexOf(id);
      this.borrar.splice(indice, 1);
      console.log(this.borrar);

    }
  }

  seleccionarTodo23(){
    this.todo23 = true;
    let valores = this.docs23.length;
    for( let i = 0; i < valores; i++){
      console.log(i);
      this.borrar[i]=this.docs23[i].ID;
      console.log(this.borrar);

    }

  }
  quitarSeleccion23(){
    let valores = this.borrar.length;
    for( let i = 0; i < valores; i++){
      console.log(i);
      this.borrar.pop();
      console.log(this.borrar);
      this.todo23 = false;
    }
  }

  checkCheckBoxvalue24(event:any, index:any, id: any){
    console.log(event.target.checked)
    this.todo24 = null;
    this.valor = event.target.checked;
    if(this.valor === true){
      console.log('verdadero');
      console.log(index);
      this.borrar[index]=this.docs24[index].ID;
      console.log(this.borrar);

    }else if(this.valor === false){
      console.log('falso');
      console.log(index);
      let indice = this.borrar.indexOf(id);
      this.borrar.splice(indice, 1);
      console.log(this.borrar);

    }
  }

  seleccionarTodo24(){
    this.todo24 = true;
    let valores = this.docs24.length;
    for( let i = 0; i < valores; i++){
      console.log(i);
      this.borrar[i]=this.docs24[i].ID;
      console.log(this.borrar);

    }

  }
  quitarSeleccion24(){
    let valores = this.borrar.length;
    for( let i = 0; i < valores; i++){
      console.log(i);
      this.borrar.pop();
      console.log(this.borrar);
      this.todo24 = false;
    }
  }




  eliminarSeleccionados(){

    this.alerts.alertaConfirmacionEliminar('Eliminar documento', '¿Desea eliminar este documento?')
    .then((res: any) => {

      if (res.isConfirmed) {
        for(let numero of this.borrar){
         console.log(numero);
         this.eliminarForm.ID = numero;
         this.eliminarForm.ESTATUS = 1;
         this.seervice.eliminarEnlace(this.eliminarForm).subscribe(
           (data: any) => {

           })
         this.alerts.alertaRealizado().then((res: any) => {
          location.reload()
         })

        }

      }

    })
  }


  a82018() {
    localStorage.setItem('fecha', '2018');
    this.tri = localStorage.getItem('area');
    console.log(this.tri);
    this.fecha = localStorage.getItem('fecha');
    console.log(this.fecha);

    this.api.obtenerAreas(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), this.fecha, this.tri).subscribe(res => {
      this.docs18 = res.body
      console.log(this.docs18);

    })
  }

  a82019() {
    localStorage.setItem('fecha', '2019');
    this.tri = localStorage.getItem('area');
    console.log(this.tri);
    this.fecha = localStorage.getItem('fecha');
    console.log(this.fecha);

    this.api.obtenerAreas(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), this.fecha, this.tri).subscribe(res => {
      this.docs19 = res.body
      console.log(this.docs19);

    })
  }

  a82020() {
    localStorage.setItem('fecha', '2020');
    this.tri = localStorage.getItem('area');
    console.log(this.tri);
    this.fecha = localStorage.getItem('fecha');
    console.log(this.fecha);

    this.api.obtenerAreas(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), this.fecha, this.tri).subscribe(res => {
      this.docs20 = res.body
      console.log(this.docs20);

    })
  }

  a82021() {
    localStorage.setItem('fecha', '2021');
    this.tri = localStorage.getItem('area');
    console.log(this.tri);
    this.fecha = localStorage.getItem('fecha');
    console.log(this.fecha);

    this.api.obtenerAreas(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), this.fecha, this.tri).subscribe(res => {
      this.docs21 = res.body
      console.log(this.docs21);

    })
  }

  a82022() {
    localStorage.setItem('fecha', '2022');
    this.tri = localStorage.getItem('area');
    console.log(this.tri);
    this.fecha = localStorage.getItem('fecha');
    console.log(this.fecha);

    this.api.obtenerAreas(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), this.fecha, this.tri).subscribe(res => {
      this.docs22 = res.body
      console.log(this.docs22);

    })
  }

  a82024() {
    localStorage.setItem('fecha', '2024');
    this.tri = localStorage.getItem('area');
    console.log(this.tri);
    this.fecha = localStorage.getItem('fecha');
    console.log(this.fecha);

    this.api.obtenerAreas(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), this.fecha, this.tri).subscribe(res => {
      this.docs24 = res.body
      console.log(this.docs24);

    })
  }

  a82023() {
    localStorage.setItem('fecha', '2023');
    this.tri = localStorage.getItem('area');
    console.log(this.tri);
    this.fecha = localStorage.getItem('fecha');
    console.log(this.fecha);

    this.api.obtenerA82018(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), this.fecha, this.tri).subscribe(res => {
      this.docs23 = res.body
      console.log(this.docs23);

    })
  }

  currircular(ano:any) {



    this.api.obtenerA82018(9, 0,ano,854).subscribe(res => {
      this.curri = res.body
      console.log(this.curri);

    })
  }


  eliminar(ID: any) {

    this.alerts.alertaConfirmacionEliminar('Eliminar documento', '¿Desea eliminar este documento?')
      .then((res: any) => {

        if (res.isConfirmed) {
          this.eliminarForm.ID = ID;
          this.eliminarForm.ESTATUS = 1;
          this.seervice.eliminarEnlace(this.eliminarForm).subscribe(
            (data: any) => {


              console.log(ID);

            })
          this.alerts.alertaRealizado().then((res: any) => {
          })
        }

      })


  }
  area1() {
    localStorage.setItem('area', '1');
  }
  area2() {
    localStorage.setItem('area', '2');
  }
  area3() {
    localStorage.setItem('area', '3');
  }
  area4() {
    localStorage.setItem('area', '4');
  }
  area5() {
    localStorage.setItem('area', '5');
  }
  area6() {
    localStorage.setItem('area', '6');
  }
  area7() {
    localStorage.setItem('area', '7');
  }
  area8() {
    localStorage.setItem('area', '8');
  }
  area9() {
    localStorage.setItem('area', '9');
  }
  area10() {
    localStorage.setItem('area', '10');
  }
  area11() {
    localStorage.setItem('area', '11');
  }
  area12() {
    localStorage.setItem('area', '12');
  }
  area13() {
    localStorage.setItem('area', '13');
  }
  openModalEditarNombre() {
    this.displayStyleEditarNombre = "block";
  }

  closePopUpNombre() {
    this.displayStyleEditarNombre = "none";
  }

  fraccion1a() {
    localStorage.setItem('fraccion', '1')
    localStorage.setItem('inciso', '1')
  }
  fraccion1b() {
    localStorage.setItem('fraccion', '1')
    localStorage.setItem('inciso', '2')
  }
  fraccion1c() {
    localStorage.setItem('fraccion', '1')
    localStorage.setItem('inciso', '3')
  }
  fraccion1d() {
    localStorage.setItem('fraccion', '1')
    localStorage.setItem('inciso', '4')
  }
  fraccion1e() {
    localStorage.setItem('fraccion', '1')
    localStorage.setItem('inciso', '5')
  }
  fraccion1f() {
    localStorage.setItem('fraccion', '1')
    localStorage.setItem('inciso', '6')
  }
  fraccion1g() {
    localStorage.setItem('fraccion', '1')
    localStorage.setItem('inciso', '7')
  }
  fraccion1h() {
    localStorage.setItem('fraccion', '1')
    localStorage.setItem('inciso', '8')
  }
  fraccion1i() {
    localStorage.setItem('fraccion', '1')
    localStorage.setItem('inciso', '9')
  }
  fraccion1j() {
    localStorage.setItem('fraccion', '1')
    localStorage.setItem('inciso', '10')
  }
  fraccion1k() {
    localStorage.setItem('fraccion', '1')
    localStorage.setItem('inciso', '11')
  }
  fraccion1l() {
    localStorage.setItem('fraccion', '1')
    localStorage.setItem('inciso', '12')
  }
  fraccion1m() {
    localStorage.setItem('fraccion', '1')
    localStorage.setItem('inciso', '13')
  }
  fraccion1n() {
    localStorage.setItem('fraccion', '1')
    localStorage.setItem('inciso', '14')
  }
  fraccion1n1() {
    localStorage.setItem('fraccion', '1')
    localStorage.setItem('inciso', '15')
  }
  fraccion2a() {
    localStorage.setItem('fraccion', '2')
    localStorage.setItem('inciso', '1')
  }
  fraccion2b() {
    localStorage.setItem('fraccion', '2')
    localStorage.setItem('inciso', '2')
  }
  fraccion2c() {
    localStorage.setItem('fraccion', '2')
    localStorage.setItem('inciso', '3')
  }
  fraccion2d() {
    localStorage.setItem('fraccion', '2')
    localStorage.setItem('inciso', '4')
  }
  fraccion2e() {
    localStorage.setItem('fraccion', '2')
    localStorage.setItem('inciso', '5')
  }
  fraccion3a() {
    localStorage.setItem('fraccion', '3')
    localStorage.setItem('inciso', '1')
  }
  fraccion3b() {
    localStorage.setItem('fraccion', '3')
    localStorage.setItem('inciso', '2')
  }
  fraccion3c() {
    localStorage.setItem('fraccion', '3')
    localStorage.setItem('inciso', '3')
  }
  fraccion3d() {
    localStorage.setItem('fraccion', '3')
    localStorage.setItem('inciso', '4')
  }
  fraccion3e() {
    localStorage.setItem('fraccion', '3')
    localStorage.setItem('inciso', '5')
  }
  fraccion3f() {
    localStorage.setItem('fraccion', '3')
    localStorage.setItem('inciso', '6')
  }
  fraccion3g() {
    localStorage.setItem('fraccion', '3')
    localStorage.setItem('inciso', '7')
  }
  fraccion4a() {
    localStorage.setItem('fraccion', '4')
    localStorage.setItem('inciso', '1')
  }
  fraccion4b() {
    localStorage.setItem('fraccion', '4')
    localStorage.setItem('inciso', '2')
  }
  fraccion4c() {
    localStorage.setItem('fraccion', '4')
    localStorage.setItem('inciso', '3')
  }
  fraccion4d() {
    localStorage.setItem('fraccion', '4')
    localStorage.setItem('inciso', '4')
  }
  fraccion4e() {
    localStorage.setItem('fraccion', '4')
    localStorage.setItem('inciso', '5')
  }
  fraccion4f() {
    localStorage.setItem('fraccion', '4')
    localStorage.setItem('inciso', '6')
  }
  fraccion4g() {
    localStorage.setItem('fraccion', '4')
    localStorage.setItem('inciso', '7')
  }
  fraccion4h() {
    localStorage.setItem('fraccion', '4')
    localStorage.setItem('inciso', '8')
  }
  fraccion4i() {
    localStorage.setItem('fraccion', '4')
    localStorage.setItem('inciso', '9')
  }
  fraccion5a() {
    localStorage.setItem('fraccion', '5')
    localStorage.setItem('inciso', '1')
  }
  fraccion5b() {
    localStorage.setItem('fraccion', '5')
    localStorage.setItem('inciso', '2')
  }
  fraccion5c() {
    localStorage.setItem('fraccion', '5')
    localStorage.setItem('inciso', '3')
  }
  fraccion5d() {
    localStorage.setItem('fraccion', '5')
    localStorage.setItem('inciso', '4')
  }
  fraccion5e() {
    localStorage.setItem('fraccion', '5')
    localStorage.setItem('inciso', '5')
  }
  fraccion5f() {
    localStorage.setItem('fraccion', '5')
    localStorage.setItem('inciso', '6')
  }
  fraccion5g() {
    localStorage.setItem('fraccion', '5')
    localStorage.setItem('inciso', '7')
  }
  fraccion5h() {
    localStorage.setItem('fraccion', '5')
    localStorage.setItem('inciso', '8')
  }
  fraccion5i() {
    localStorage.setItem('fraccion', '5')
    localStorage.setItem('inciso', '9')
  }
  fraccion5j() {
    localStorage.setItem('fraccion', '5')
    localStorage.setItem('inciso', '10')
  }
  fraccion5k() {
    localStorage.setItem('fraccion', '5')
    localStorage.setItem('inciso', '11')
  }
  fraccion5l() {
    localStorage.setItem('fraccion', '5')
    localStorage.setItem('inciso', '12')
  }
  fraccion5m() {
    localStorage.setItem('fraccion', '5')
    localStorage.setItem('inciso', '13')
  }
  fraccion5n() {
    localStorage.setItem('fraccion', '5')
    localStorage.setItem('inciso', '14')
  }
  fraccion5n1() {
    localStorage.setItem('fraccion', '5')
    localStorage.setItem('inciso', '15')
  }
  fraccion5o() {
    localStorage.setItem('fraccion', '5')
    localStorage.setItem('inciso', '16')
  }
  fraccion5p() {
    localStorage.setItem('fraccion', '5')
    localStorage.setItem('inciso', '17')
  }
  fraccion5q() {
    localStorage.setItem('fraccion', '5')
    localStorage.setItem('inciso', '18')
  }
  fraccion5r() {
    localStorage.setItem('fraccion', '5')
    localStorage.setItem('inciso', '19')
  }
  fraccion5s() {
    localStorage.setItem('fraccion', '5')
    localStorage.setItem('inciso', '20')
  }
  fraccion5t() {
    localStorage.setItem('fraccion', '5')
    localStorage.setItem('inciso', '21')
  }
  fraccion5u() {
    localStorage.setItem('fraccion', '5')
    localStorage.setItem('inciso', '22')
  }
  fraccion5v() {
    localStorage.setItem('fraccion', '5')
    localStorage.setItem('inciso', '23')
  }
  fraccion5w() {
    localStorage.setItem('fraccion', '5')
    localStorage.setItem('inciso', '24')
  }
  fraccion5x() {
    localStorage.setItem('fraccion', '5')
    localStorage.setItem('inciso', '25')
  }
  fraccion5y() {
    localStorage.setItem('fraccion', '5')
    localStorage.setItem('inciso', '26')
  }
  fraccion5z() {
    localStorage.setItem('fraccion', '5')
    localStorage.setItem('inciso', '27')
  }
  fraccion6a() {
    localStorage.setItem('fraccion', '6')
    localStorage.setItem('inciso', '1')
  }
  fraccion6b() {
    localStorage.setItem('fraccion', '6')
    localStorage.setItem('inciso', '2')
  }
  fraccion6c() {
    localStorage.setItem('fraccion', '6')
    localStorage.setItem('inciso', '3')
  }
  fraccion6d() {
    localStorage.setItem('fraccion', '6')
    localStorage.setItem('inciso', '4')
  }
  fraccion6e() {
    localStorage.setItem('fraccion', '6')
    localStorage.setItem('inciso', '5')
  }
  fraccion6f() {
    localStorage.setItem('fraccion', '6')
    localStorage.setItem('inciso', '6')
  }
  fraccion6g() {
    localStorage.setItem('fraccion', '6')
    localStorage.setItem('inciso', '7')
  }
  fraccion6h() {
    localStorage.setItem('fraccion', '6')
    localStorage.setItem('inciso', '8')
  }
  fraccion6i() {
    localStorage.setItem('fraccion', '6')
    localStorage.setItem('inciso', '9')
  }
  fraccion6j() {
    localStorage.setItem('fraccion', '6')
    localStorage.setItem('inciso', '10')
  }
  fraccion6k() {
    localStorage.setItem('fraccion', '6')
    localStorage.setItem('inciso', '11')
  }
  fraccion6l() {
    localStorage.setItem('fraccion', '6')
    localStorage.setItem('inciso', '12')
  }
  fraccion6m() {
    localStorage.setItem('fraccion', '6')
    localStorage.setItem('inciso', '13')
  }
  fraccion6n() {
    localStorage.setItem('fraccion', '6')
    localStorage.setItem('inciso', '14')
  }
  a8fraccion7() {
    localStorage.setItem('fraccion', '7')
  }
  a8fraccion8() {
    localStorage.setItem('fraccion', '8')
  }
  a8fraccion9() {
    localStorage.setItem('fraccion', '9')
  }
  a8fraccion10() {
    localStorage.setItem('fraccion', '10')
  }
  a8fraccion11() {
    localStorage.setItem('fraccion', '11')
  }
  a8fraccion12() {
    localStorage.setItem('fraccion', '12')
  }
  a8fraccion13() {
    localStorage.setItem('fraccion', '13')
  }
  a8fraccion14() {
    localStorage.setItem('fraccion', '14')
  }

  fraccion1() {
    localStorage.setItem('fraccion', '1')
  }

  fraccion2() {
    localStorage.setItem('fraccion', '2')
  }
  fraccion3() {
    localStorage.setItem('fraccion', '3')
  }
  fraccion4() {
    localStorage.setItem('fraccion', '4')
  }
  fraccion5() {
    localStorage.setItem('fraccion', '5')
  }
  fraccion6() {
    localStorage.setItem('fraccion', '6')
  }
  fraccion7() {
    localStorage.setItem('fraccion', '7')
  }
  fraccion8() {
    localStorage.setItem('fraccion', '8')
  }
  fraccion9() {
    localStorage.setItem('fraccion', '9')
  }
  fraccion10() {
    localStorage.setItem('fraccion', '10')
  }
  fraccion11() {
    localStorage.setItem('fraccion', '11')
  }
  fraccion12() {
    localStorage.setItem('fraccion', '12')
  }
  fraccion13() {
    localStorage.setItem('fraccion', '13')
  }
  fraccion14() {
    localStorage.setItem('fraccion', '14')
  }
  fraccion15() {
    localStorage.setItem('fraccion', '15')
  }
  fraccion16() {
    localStorage.setItem('fraccion', '16')
  }
  fraccion17() {
    localStorage.setItem('fraccion', '17')
  }
  fraccion18() {
    localStorage.setItem('fraccion', '18')
  }
  fraccion19() {
    localStorage.setItem('fraccion', '19')
  }
  fraccion20() {
    localStorage.setItem('fraccion', '20')
  }
  fraccion21() {
    localStorage.setItem('fraccion', '21')
  }
  fraccion22() {
    localStorage.setItem('fraccion', '22')
  }
  fraccion23() {
    localStorage.setItem('fraccion', '23')
  }
  fraccion24() {
    localStorage.setItem('fraccion', '24')
  }
  fraccion25() {
    localStorage.setItem('fraccion', '25')
  }
  fraccion26() {
    localStorage.setItem('fraccion', '26')
  }

  fraccion27() {
    localStorage.setItem('fraccion', '27')
  }
  fraccion28() {
    localStorage.setItem('fraccion', '28')
  }
}
