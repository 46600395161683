import { Component, OnInit } from '@angular/core';
import { GeneralService } from 'src/services/general.service';
import { Fraccion1Service } from 'src/services/Articulo8/Fraccion1.service';
import { SweetAlertService } from 'src/services/sweet-alert.service';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-in6-a',
  templateUrl: './in6-a.component.html',
  styleUrls: ['./in6-a.component.scss']
})
export class In6AComponent implements OnInit {
  docs: any;
  docs18: any;
  docs19: any;
  docs20: any;
  docs21: any;
  docs22: any;
  docsSubsidio: any;
  boton: any;
  id: any;

  borrar: string[] = [];
  valor: any;
  todo: any;

  eliminarForm: any = {
    ESTATUS: 0,
    NOMBRE: undefined,
    ENLACE: undefined,

  };

  anotacionForm = new FormGroup({
    ID: new FormControl(''),
    ANOTACIONES: new FormControl('')
  })

  displayStyleEditarNombre = "none";
  constructor(private service: GeneralService, private alerts: SweetAlertService) { }

  ngOnInit(): void {
    localStorage.setItem('fraccion', '6');
    localStorage.setItem('inciso', '1');

    if (localStorage.getItem('token')) {
      this.boton = 1;
    }

    this.service.obtenerA8(localStorage.getItem('fraccion'), localStorage.getItem('inciso')).subscribe(res => {
      this.docs = res.body
      console.log(this.docs);

    })
  }

  checkCheckBoxvalue(event:any, index:any, id: any){
    console.log(event.target.checked)
    this.todo = null;
    this.valor = event.target.checked;
    if(this.valor === true){
      console.log('verdadero');
      console.log(index);
      this.borrar[index]=this.docs[index].ID;
      console.log(this.borrar);

    }else if(this.valor === false){
      console.log('falso');
      console.log(index);
      let indice = this.borrar.indexOf(id);
      this.borrar.splice(indice, 1);
      console.log(this.borrar);

    }
  }

  seleccionarTodo(){
    this.todo = true;
    let valores = this.docs.length;
    for( let i = 0; i < valores; i++){
      console.log(i);
      this.borrar[i]=this.docs[i].ID;
      console.log(this.borrar);

    }

  }

  quitarSeleccion(){
    let valores = this.borrar.length;
    for( let i = 0; i < valores; i++){
      console.log(i);
      this.borrar.pop();
      console.log(this.borrar);
      this.todo = false;
    }
  }

  eliminarSeleccionados(){

    this.alerts.alertaConfirmacionEliminar('Eliminar documento', '¿Desea eliminar este documento?')
    .then((res: any) => {

      if (res.isConfirmed) {
        for(let numero of this.borrar){
         console.log(numero);
         this.eliminarForm.ID = numero;
         this.eliminarForm.ESTATUS = 1;
         this.service.eliminarEnlace(this.eliminarForm).subscribe(
           (data: any) => {

           })
         this.alerts.alertaRealizado().then((res: any) => {
          location.reload()
         })

        }

      }

    })
  }

  eliminar(ID: any) {

    this.alerts.alertaConfirmacionEliminar('Eliminar documento', '¿Desea eliminar este documento?')
      .then((res: any) => {

        if (res.isConfirmed) {
          this.eliminarForm.ID = ID;
          this.eliminarForm.ESTATUS = 1;
          this.service.eliminarEnlace(this.eliminarForm).subscribe(
            (data: any) => {


              console.log(ID);

            })
          this.alerts.alertaRealizado().then((res: any) => {
            location.reload()
          })
        }

      })


  }


  openModalEditarNombre(ID: any) {
    this.id = ID;
    this.displayStyleEditarNombre = "block";
  }

  closePopUpNombre() {
    this.displayStyleEditarNombre = "none";
  }

  anotacion() {
    this.anotacionForm.value.ID = this.id;

    console.log(this.anotacionForm.value);
    this.service.editarDoc(this.anotacionForm.value).subscribe(res => {
      console.log(res.body);
      console.log('Editado');

      this.service.obtenerA8(localStorage.getItem('fraccion'), localStorage.getItem('inciso')).subscribe(res => {
        this.docs = res.body
        console.log(this.docs);

      })

    })


    this.closePopUpNombre();
  }

  fraccion1a() {
    localStorage.setItem('fraccion', '1')
    localStorage.setItem('inciso', '1')
  }
  fraccion1b() {
    localStorage.setItem('fraccion', '1')
    localStorage.setItem('inciso', '2')
  }
  fraccion1c() {
    localStorage.setItem('fraccion', '1')
    localStorage.setItem('inciso', '3')
  }
  fraccion1d() {
    localStorage.setItem('fraccion', '1')
    localStorage.setItem('inciso', '4')
  }
  fraccion1e() {
    localStorage.setItem('fraccion', '1')
    localStorage.setItem('inciso', '5')
  }
  fraccion1f() {
    localStorage.setItem('fraccion', '1')
    localStorage.setItem('inciso', '6')
  }
  fraccion1g() {
    localStorage.setItem('fraccion', '1')
    localStorage.setItem('inciso', '7')
  }
  fraccion1h() {
    localStorage.setItem('fraccion', '1')
    localStorage.setItem('inciso', '8')
  }
  fraccion1i() {
    localStorage.setItem('fraccion', '1')
    localStorage.setItem('inciso', '9')
  }
  fraccion1j() {
    localStorage.setItem('fraccion', '1')
    localStorage.setItem('inciso', '10')
  }
  fraccion1k() {
    localStorage.setItem('fraccion', '1')
    localStorage.setItem('inciso', '11')
  }
  fraccion1l() {
    localStorage.setItem('fraccion', '1')
    localStorage.setItem('inciso', '12')
  }
  fraccion1m() {
    localStorage.setItem('fraccion', '1')
    localStorage.setItem('inciso', '13')
  }
  fraccion1n() {
    localStorage.setItem('fraccion', '1')
    localStorage.setItem('inciso', '14')
  }
  fraccion1n1() {
    localStorage.setItem('fraccion', '1')
    localStorage.setItem('inciso', '15')
  }
  fraccion2a() {
    localStorage.setItem('fraccion', '2')
    localStorage.setItem('inciso', '1')
  }
  fraccion2b() {
    localStorage.setItem('fraccion', '2')
    localStorage.setItem('inciso', '2')
  }
  fraccion2c() {
    localStorage.setItem('fraccion', '2')
    localStorage.setItem('inciso', '3')
  }
  fraccion2d() {
    localStorage.setItem('fraccion', '2')
    localStorage.setItem('inciso', '4')
  }
  fraccion2e() {
    localStorage.setItem('fraccion', '2')
    localStorage.setItem('inciso', '5')
  }
  fraccion3a() {
    localStorage.setItem('fraccion', '3')
    localStorage.setItem('inciso', '1')
  }
  fraccion3b() {
    localStorage.setItem('fraccion', '3')
    localStorage.setItem('inciso', '2')
  }
  fraccion3c() {
    localStorage.setItem('fraccion', '3')
    localStorage.setItem('inciso', '3')
  }
  fraccion3d() {
    localStorage.setItem('fraccion', '3')
    localStorage.setItem('inciso', '4')
  }
  fraccion3e() {
    localStorage.setItem('fraccion', '3')
    localStorage.setItem('inciso', '5')
  }
  fraccion3f() {
    localStorage.setItem('fraccion', '3')
    localStorage.setItem('inciso', '6')
  }
  fraccion3g() {
    localStorage.setItem('fraccion', '3')
    localStorage.setItem('inciso', '7')
  }
  fraccion4a() {
    localStorage.setItem('fraccion', '4')
    localStorage.setItem('inciso', '1')
  }
  fraccion4b() {
    localStorage.setItem('fraccion', '4')
    localStorage.setItem('inciso', '2')
  }
  fraccion4c() {
    localStorage.setItem('fraccion', '4')
    localStorage.setItem('inciso', '3')
  }
  fraccion4d() {
    localStorage.setItem('fraccion', '4')
    localStorage.setItem('inciso', '4')
  }
  fraccion4e() {
    localStorage.setItem('fraccion', '4')
    localStorage.setItem('inciso', '5')
  }
  fraccion4f() {
    localStorage.setItem('fraccion', '4')
    localStorage.setItem('inciso', '6')
  }
  fraccion4g() {
    localStorage.setItem('fraccion', '4')
    localStorage.setItem('inciso', '7')
  }
  fraccion4h() {
    localStorage.setItem('fraccion', '4')
    localStorage.setItem('inciso', '8')
  }
  fraccion4i() {
    localStorage.setItem('fraccion', '4')
    localStorage.setItem('inciso', '9')
  }
  fraccion5a() {
    localStorage.setItem('fraccion', '5')
    localStorage.setItem('inciso', '1')
  }
  fraccion5b() {
    localStorage.setItem('fraccion', '5')
    localStorage.setItem('inciso', '2')
  }
  fraccion5c() {
    localStorage.setItem('fraccion', '5')
    localStorage.setItem('inciso', '3')
  }
  fraccion5d() {
    localStorage.setItem('fraccion', '5')
    localStorage.setItem('inciso', '4')
  }
  fraccion5e() {
    localStorage.setItem('fraccion', '5')
    localStorage.setItem('inciso', '5')
  }
  fraccion5f() {
    localStorage.setItem('fraccion', '5')
    localStorage.setItem('inciso', '6')
  }
  fraccion5g() {
    localStorage.setItem('fraccion', '5')
    localStorage.setItem('inciso', '7')
  }
  fraccion5h() {
    localStorage.setItem('fraccion', '5')
    localStorage.setItem('inciso', '8')
  }
  fraccion5i() {
    localStorage.setItem('fraccion', '5')
    localStorage.setItem('inciso', '9')
  }
  fraccion5j() {
    localStorage.setItem('fraccion', '5')
    localStorage.setItem('inciso', '10')
  }
  fraccion5k() {
    localStorage.setItem('fraccion', '5')
    localStorage.setItem('inciso', '11')
  }
  fraccion5l() {
    localStorage.setItem('fraccion', '5')
    localStorage.setItem('inciso', '12')
  }
  fraccion5m() {
    localStorage.setItem('fraccion', '5')
    localStorage.setItem('inciso', '13')
  }
  fraccion5n() {
    localStorage.setItem('fraccion', '5')
    localStorage.setItem('inciso', '14')
  }
  fraccion5n1() {
    localStorage.setItem('fraccion', '5')
    localStorage.setItem('inciso', '15')
  }
  fraccion5o() {
    localStorage.setItem('fraccion', '5')
    localStorage.setItem('inciso', '16')
  }
  fraccion5p() {
    localStorage.setItem('fraccion', '5')
    localStorage.setItem('inciso', '17')
  }
  fraccion5q() {
    localStorage.setItem('fraccion', '5')
    localStorage.setItem('inciso', '18')
  }
  fraccion5r() {
    localStorage.setItem('fraccion', '5')
    localStorage.setItem('inciso', '19')
  }
  fraccion5s() {
    localStorage.setItem('fraccion', '5')
    localStorage.setItem('inciso', '20')
  }
  fraccion5t() {
    localStorage.setItem('fraccion', '5')
    localStorage.setItem('inciso', '21')
  }
  fraccion5u() {
    localStorage.setItem('fraccion', '5')
    localStorage.setItem('inciso', '22')
  }
  fraccion5v() {
    localStorage.setItem('fraccion', '5')
    localStorage.setItem('inciso', '23')
  }
  fraccion5w() {
    localStorage.setItem('fraccion', '5')
    localStorage.setItem('inciso', '24')
  }
  fraccion5x() {
    localStorage.setItem('fraccion', '5')
    localStorage.setItem('inciso', '25')
  }
  fraccion5y() {
    localStorage.setItem('fraccion', '5')
    localStorage.setItem('inciso', '26')
  }
  fraccion5z() {
    localStorage.setItem('fraccion', '5')
    localStorage.setItem('inciso', '27')
  }
  fraccion6a() {
    localStorage.setItem('fraccion', '6')
    localStorage.setItem('inciso', '1')
  }
  fraccion6b() {
    localStorage.setItem('fraccion', '6')
    localStorage.setItem('inciso', '2')
  }
  fraccion6c() {
    localStorage.setItem('fraccion', '6')
    localStorage.setItem('inciso', '3')
  }
  fraccion6d() {
    localStorage.setItem('fraccion', '6')
    localStorage.setItem('inciso', '4')
  }
  fraccion6e() {
    localStorage.setItem('fraccion', '6')
    localStorage.setItem('inciso', '5')
  }
  fraccion6f() {
    localStorage.setItem('fraccion', '6')
    localStorage.setItem('inciso', '6')
  }
  fraccion6g() {
    localStorage.setItem('fraccion', '6')
    localStorage.setItem('inciso', '7')
  }
  fraccion6h() {
    localStorage.setItem('fraccion', '6')
    localStorage.setItem('inciso', '8')
  }
  fraccion6i() {
    localStorage.setItem('fraccion', '6')
    localStorage.setItem('inciso', '9')
  }
  fraccion6j() {
    localStorage.setItem('fraccion', '6')
    localStorage.setItem('inciso', '10')
  }
  fraccion6k() {
    localStorage.setItem('fraccion', '6')
    localStorage.setItem('inciso', '11')
  }
  fraccion6l() {
    localStorage.setItem('fraccion', '6')
    localStorage.setItem('inciso', '12')
  }
  fraccion6m() {
    localStorage.setItem('fraccion', '6')
    localStorage.setItem('inciso', '13')
  }
  fraccion6n() {
    localStorage.setItem('fraccion', '6')
    localStorage.setItem('inciso', '14')
  }
  a8fraccion7() {
    localStorage.setItem('fraccion', '7')
  }
  a8fraccion8() {
    localStorage.setItem('fraccion', '8')
  }
  a8fraccion9() {
    localStorage.setItem('fraccion', '9')
  }
  a8fraccion10() {
    localStorage.setItem('fraccion', '10')
  }
  a8fraccion11() {
    localStorage.setItem('fraccion', '11')
  }
  a8fraccion12() {
    localStorage.setItem('fraccion', '12')
  }
  a8fraccion13() {
    localStorage.setItem('fraccion', '13')
  }
  a8fraccion14() {
    localStorage.setItem('fraccion', '14')
  }

  fraccion1() {
    localStorage.setItem('fraccion', '1')
  }

  fraccion2() {
    localStorage.setItem('fraccion', '2')
  }
  fraccion3() {
    localStorage.setItem('fraccion', '3')
  }
  fraccion4() {
    localStorage.setItem('fraccion', '4')
  }
  fraccion5() {
    localStorage.setItem('fraccion', '5')
  }
  fraccion6() {
    localStorage.setItem('fraccion', '6')
  }
  fraccion7() {
    localStorage.setItem('fraccion', '7')
  }
  fraccion8() {
    localStorage.setItem('fraccion', '8')
  }
  fraccion9() {
    localStorage.setItem('fraccion', '9')
  }
  fraccion10() {
    localStorage.setItem('fraccion', '10')
  }
  fraccion11() {
    localStorage.setItem('fraccion', '11')
  }
  fraccion12() {
    localStorage.setItem('fraccion', '12')
  }
  fraccion13() {
    localStorage.setItem('fraccion', '13')
  }
  fraccion14() {
    localStorage.setItem('fraccion', '14')
  }
  fraccion15() {
    localStorage.setItem('fraccion', '15')
  }
  fraccion16() {
    localStorage.setItem('fraccion', '16')
  }
  fraccion17() {
    localStorage.setItem('fraccion', '17')
  }
  fraccion18() {
    localStorage.setItem('fraccion', '18')
  }
  fraccion19() {
    localStorage.setItem('fraccion', '19')
  }
  fraccion20() {
    localStorage.setItem('fraccion', '20')
  }
  fraccion21() {
    localStorage.setItem('fraccion', '21')
  }
  fraccion22() {
    localStorage.setItem('fraccion', '22')
  }
  fraccion23() {
    localStorage.setItem('fraccion', '23')
  }
  fraccion24() {
    localStorage.setItem('fraccion', '24')
  }
  fraccion25() {
    localStorage.setItem('fraccion', '25')
  }
  fraccion26() {
    localStorage.setItem('fraccion', '26')
  }

  fraccion27() {
    localStorage.setItem('fraccion', '27')
  }
  fraccion28() {
    localStorage.setItem('fraccion', '28')
  }

  area1() {
    localStorage.setItem('area', '1');
  }
  area2() {
    localStorage.setItem('area', '2');
  }
  area3() {
    localStorage.setItem('area', '3');
  }
  area4() {
    localStorage.setItem('area', '4');
  }
  area5() {
    localStorage.setItem('area', '5');
  }
  area6() {
    localStorage.setItem('area', '6');
  }
  area7() {
    localStorage.setItem('area', '7');
  }
  area8() {
    localStorage.setItem('area', '8');
  }
  area9() {
    localStorage.setItem('area', '9');
  }
  area10() {
    localStorage.setItem('area', '10');
  }
  area11() {
    localStorage.setItem('area', '11');
  }
  area12() {
    localStorage.setItem('area', '12');
  }
  area13() {
    localStorage.setItem('area', '13');
  }
  area14() {
    localStorage.setItem('area', '14');
  }
  area15() {
    localStorage.setItem('area', '15');
  }
  area16() {
    localStorage.setItem('area', '16');
  }
  area17() {
    localStorage.setItem('area', '17');
  }
  area18() {
    localStorage.setItem('area', '18');
  }
  area19() {
    localStorage.setItem('area', '19');
  }
  area20() {
    localStorage.setItem('area', '20');
  }
  area21() {
    localStorage.setItem('area', '21');
  }
  area22() {
    localStorage.setItem('area', '22');
  }
  area23() {
    localStorage.setItem('area', '23');
  }
  area24() {
    localStorage.setItem('area', '24');
  }
  area25() {
    localStorage.setItem('area', '25');
  }
  area26() {
    localStorage.setItem('area', '26');
  }
  area27() {
    localStorage.setItem('area', '27');
  }
  area28() {
    localStorage.setItem('area', '28');
  }
  area29() {
    localStorage.setItem('area', '29');
  }
  area30() {
    localStorage.setItem('area', '30');
  }
  area31() {
    localStorage.setItem('area', '31');
  }
  area32() {
    localStorage.setItem('area', '32');
  }
  area33() {
    localStorage.setItem('area', '33');
  }
  area34() {
    localStorage.setItem('area', '34');
  }
  area35() {
    localStorage.setItem('area', '35');
  }
  area36() {
    localStorage.setItem('area', '36');
  }
  area37() {
    localStorage.setItem('area', '37');
  }
  area38() {
    localStorage.setItem('area', '38');
  }
  area39() {
    localStorage.setItem('area', '39');
  }
  area40() {
    localStorage.setItem('area', '40');
  }
  area41() {
    localStorage.setItem('area', '41');
  }
  area42() {
    localStorage.setItem('area', '42');
  }
  area43() {
    localStorage.setItem('area', '43');
  }
  area44() {
    localStorage.setItem('area', '44');
  }
  area45() {
    localStorage.setItem('area', '45');
  }
  area46() {
    localStorage.setItem('area', '46');
  }
  area47() {
    localStorage.setItem('area', '47');
  }
  area48() {
    localStorage.setItem('area', '48');
  }
  area49() {
    localStorage.setItem('area', '49');
  }

  area50() {
    localStorage.setItem('area', '50');
  }
}

